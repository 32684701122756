import React, { useEffect, useState, useContext } from 'react';
import { Link,useHistory } from "react-router-dom";
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat,Common,Permission_keys } from '../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../Spinner';
import VirtualRingRequestDetail from '../VirtualRingBuilder/VirtualRingRequestDetail'
const VirtualRingBuilder = () => {
    const [VirtualRingRequestList, setVirtualRingRequestList] = useState([])
    const [VirtualRingRequestData, setVirtualRingRequestData] = useState([]);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [IsShowModal, setIsShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, settotalRows] = useState()
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const columns = [
        {
            name: 'Image',
            selector: row => <img className="my-2" width="95px" height="95px" src={row.image}></img>,
        },
        {
            name: 'SKU',
            selector: (row) => row.sku
        },
        {
            name: 'Product Name',
            selector: (row) => row.metal + ' ' + row.product_name + ' ' + row.style
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,

        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="cursor action-btn">
                    <span onClick={(e) => ShowRequestData(e, row.id)} className="text-dark" title="View"><i className="fa fa-eye" aria-hidden="true"></i> </span>
                </div>
            ,
        },
    ];

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Virtual_Ring_Builder_Request)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.VirtualRingBuilder
        GetVirtualRingRequestList()
    }, [CustomFilters])

    const GetVirtualRingRequestList = () => {
        var param = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetVirtualRingRequestList, param)
            .then(response => {
                console.log(response.data.result);
                setVirtualRingRequestList(response.data.result[1])
                if (response.data.result[0].length > 0)
                    settotalRows(response.data.result[0][0].total)
            });
    }

    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search For Service, Name, E-mail, Contact No." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['page']: 1, ['search']: e.target.value })} />
        );
    });
    const ShowRequestData = (e, id) => {
        e.preventDefault();
        let data = VirtualRingRequestList.filter(x => x.id == id)[0]
        setVirtualRingRequestData(data)
        setIsShowModal(true);
        setForceUpdate(!isForceUpdate)
    }
    const onCloseModal = () => {
        setIsShowModal(false);
        GetVirtualRingRequestList();
        setForceUpdate(!isForceUpdate);
    }
 return (!IsAuth || loading) ? <Spinner /> : (
        <>
            {
                IsShowModal == true &&
                <VirtualRingRequestDetail props={{ IsShowModal: IsShowModal, data: VirtualRingRequestData }} onCloseModal={onCloseModal} />
            }
            <div className="content-wrapper pb-0">
                <div className="page-header">
                    <h3 className="page-title"> {Title.VirtualRingBuilder} </h3>
                </div>
                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={VirtualRingRequestList}
                                        // progressPending={loading}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VirtualRingBuilder;