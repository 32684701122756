import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Permission_keys, Common, Title, toast, Messages, swal } from '../../config/react'
import readXlsxFile from 'read-excel-file'
import exampleSheet from '../../assests/example_sheets/Product_Sheet.xlsx'
import Spinner from '../Spinner';
const ImportProducts = () => {

    const user = useContext(UserContext);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false);
    const validator = useRef(new SimpleReactValidator())
    const [loading, setLoading] = useState(false);
    const [IsImagesExist, setIsImagesExist] = useState(false);
    const [Inputs, setInputs] = useState({
        excel_sheet: []
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.ImportExcel
    }, [])

    const uploadExcelFile = (e) => {
        setInputs({ ...Inputs, ['excel_sheet']: e.target.files[0] });
        setIsImagesExist(false);
        setForceUpdate(!isForceUpdate);
    }
    const imageErrorMessage = (skuWithoutBaseImage, skuWithoutVideo) => {
        return <div>
            {
                skuWithoutBaseImage.length > 0 &&
                <>
                    <b>Base image not uploaded for below SKU:</b>
                    <p>{skuWithoutBaseImage.join(",")}</p>
                </>
            }

            {/* {
                skuWithoutVideo.length > 0 &&
                <>
                    <b>Video not uploaded for below SKU:</b>
                    <p>{skuWithoutVideo.join(",")}</p>
                </>
            } */}

        </div>
    }

    const SubmitForm = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        setLoading(true);
        readXlsxFile(Inputs.excel_sheet).then((rows) => {
            var headers = rows[0];
            rows.splice(0, 1);
            var datas = rows;
            var excelDatas = [];
            for (var i = 0; i < datas.length; i++) {
                var array = {};
                for (var j = 0; j < datas[i].length; j++) {
                    array[headers[j].toLowerCase().replaceAll(" ", "_")] = datas[i][j];
                }
                excelDatas.push(array);
            }
            var products = [];
            var last_sku = "";

            for (var key in excelDatas) {
                if (excelDatas[key]['parent_sku'] == null && excelDatas[key]['product_type'] != null && excelDatas[key]['product_type'].toLowerCase() == "configurable") {
                    var diamonds = [];
                    for (var i = 1; i <= 20; i++) {
                        var diamondKey = "stone" + i;
                        if (excelDatas[key].hasOwnProperty(diamondKey) && excelDatas[key][diamondKey] != null && excelDatas[key][diamondKey] != "") {
                            var stoneDetails = excelDatas[key][diamondKey].split("|");
                            diamonds.push({
                                'Type': stoneDetails.hasOwnProperty(0) ? stoneDetails[0] : "",
                                'Shape': stoneDetails.hasOwnProperty(1) ? stoneDetails[1] : "",
                                'Weight': stoneDetails.hasOwnProperty(2) ? stoneDetails[2] : "",
                                'Peices': stoneDetails.hasOwnProperty(3) ? stoneDetails[3] : "",
                                'Color': stoneDetails.hasOwnProperty(4) ? stoneDetails[4] : "",
                                'Clarity': stoneDetails.hasOwnProperty(5) ? stoneDetails[5] : "",
                                'Setting': stoneDetails.hasOwnProperty(6) ? stoneDetails[6] : ""
                            });
                        }
                    }
                    last_sku = excelDatas[key]['sku'];
                    var canCarat = excelDatas[key]['can_be_set_with_carat'] != null && excelDatas[key]['can_be_set_with_carat'].indexOf("-") >= 0 ? excelDatas[key]['can_be_set_with_carat'].split("-") : [excelDatas[key]['can_be_set_with_carat']];
                    var OtherImages = excelDatas[key]['other_image'] != null && excelDatas[key]['other_image'].indexOf(",") >= 0 ? excelDatas[key]['other_image'].split(",") : [excelDatas[key]['other_image']];
                    var canSetStyle = excelDatas[key]['can_be_set_with_style'] != null && excelDatas[key]['can_be_set_with_style'].indexOf("|") >= 0 ? excelDatas[key]['can_be_set_with_style'].split("|") : [excelDatas[key]['can_be_set_with_style']];
                    var categories = excelDatas[key]['category'] != null && excelDatas[key]['category'].indexOf("|") >= 0 ? excelDatas[key]['category'].split("|") : [excelDatas[key]['category']];
                    var finalCategories = [];
                    categories.forEach(element => {
                        if (finalCategories.indexOf(element) < 0) {
                            finalCategories.push(element);
                        }
                    });
                    canSetStyle.forEach(element => {
                        if (finalCategories.indexOf(element) < 0) {
                            finalCategories.push(element);
                        }
                    });
                    products[excelDatas[key]['sku']] = {
                        'sku': excelDatas[key]['sku'],
                        'name': excelDatas[key]['name'],
                        'attribute_set_id': excelDatas[key]['attribute_set_code'],
                        'metal_quality': "[]",
                        'is_configured': 1,
                        'description': excelDatas[key]['description'],
                        'short_description': excelDatas[key]['short_description'],
                        'is_readytoship': excelDatas[key]['is_stock'],
                        'is_sold': 0,
                        'meta_description': excelDatas[key]['meta_description'],
                        'meta_keywords': excelDatas[key]['meta_keywords'],
                        'meta_title': excelDatas[key]['meta_title'],
                        'qty': excelDatas[key]['qty'],
                        'price': excelDatas[key]['price'],
                        'status': excelDatas[key]['status'],
                        'weight': excelDatas[key]['metal_weight'],
                        'can_be_set': excelDatas[key]['can_be_set_with_shape'] != null && excelDatas[key]['can_be_set_with_carat'] != "" ?
                            [{
                                shape: excelDatas[key]['can_be_set_with_shape'],
                                carat_from: canCarat[0] != undefined ? canCarat[0] : 0,
                                carat_to: canCarat[1] != undefined ? canCarat[1] : 0
                            }] : [],
                        'can_be_set_with_style': excelDatas[key]['can_be_set_with_style'] != null && excelDatas[key]['can_be_set_with_style'] != "" ? excelDatas[key]['can_be_set_with_style'].split('|') : [],
                        'Userid': user.id,
                        'attributes': {
                            // 'color': excelDatas[key]['color'],
                            // 'clarity': excelDatas[key]['clarity'],
                            // 'cut': excelDatas[key]['cut'],
                            'height': excelDatas[key]['height'],
                            'ring_style': excelDatas[key]['ring_style'],
                            'shape': excelDatas[key]['shape'],
                            'width': excelDatas[key]['width'],
                        },
                        'category_id': finalCategories.join("|"),
                        'video_url': excelDatas[key]['video_url'],
                        'images': {
                            'base_image': excelDatas[key]['base_image'],
                            'dimention_image': excelDatas[key]['dimention_image'],
                            'modal_image': excelDatas[key]['modal_image'],
                            'other_image': JSON.stringify(OtherImages),
                        },
                        'related_products': excelDatas[key]['related_skus'],
                        'configurationValues': [],
                        'diamond': diamonds.length > 0 ? JSON.stringify(diamonds) : null
                    };
                }
                else if (excelDatas[key]['parent_sku'] == null && excelDatas[key]['sku'] == null && excelDatas[key]['can_be_set_with_shape'] && products[last_sku] != undefined) {
                    var lastSet = products[last_sku]['can_be_set'];
                    var canCarat = excelDatas[key]['can_be_set_with_carat'] != null && excelDatas[key]['can_be_set_with_carat'].indexOf("-") >= 0 ? excelDatas[key]['can_be_set_with_carat'].split("-") : [excelDatas[key]['can_be_set_with_carat']];
                    products[last_sku]['can_be_set'].push({
                        shape: excelDatas[key]['can_be_set_with_shape'],
                        carat_from: canCarat[0] != undefined ? canCarat[0] : 0,
                        carat_to: canCarat[1] != undefined ? canCarat[1] : 0
                    });
                }
                else {
                    if (products.hasOwnProperty(excelDatas[key]['parent_sku']) && products[excelDatas[key]['parent_sku']] != undefined) {
                        var OtherImages = excelDatas[key]['other_image'] != null && excelDatas[key]['other_image'].indexOf(",") >= 0 ? excelDatas[key]['other_image'].split(",") : [excelDatas[key]['other_image']];
                        var metalQuality = JSON.parse(products[excelDatas[key]['parent_sku']]['metal_quality']);
                        var configurationProducts = products[excelDatas[key]['parent_sku']]['configurationValues'].length > 0 ? JSON.parse(products[excelDatas[key]['parent_sku']]['configurationValues']) : [];
                        if (!metalQuality.includes(excelDatas[key]['metal_quality'])
                            && excelDatas[key]['sku'] != ""
                            && configurationProducts.findIndex(x => x.sku == excelDatas[key]['sku']) < 0) {
                            metalQuality.push(excelDatas[key]['metal_quality']);
                            configurationProducts.push({
                                'qty': excelDatas[key]['qty'],
                                'price': excelDatas[key]['price'],
                                'sku': excelDatas[key]['sku'],
                                'metal_quality': excelDatas[key]['metal_quality'],
                                'video_url': excelDatas[key]['video_url'],
                                'images': {
                                    'base_image': excelDatas[key]['base_image'],
                                    'dimention_image': excelDatas[key]['dimention_image'],
                                    'modal_image': excelDatas[key]['modal_image'],
                                    'other_image': JSON.stringify(OtherImages),
                                }
                            });
                            products[excelDatas[key]['parent_sku']]['metal_quality'] = JSON.stringify(metalQuality);
                            products[excelDatas[key]['parent_sku']]['configurationValues'] = JSON.stringify(configurationProducts);
                        }
                    }
                }
            }
            var finalArray = [];
            for (var key in products) {
                products[key]['can_be_set'] = JSON.stringify(products[key]['can_be_set']);
                if (products[key].configurationValues != "") {
                    finalArray.push(products[key]);
                }
            }
            if (finalArray.length > 0) {
                let apiUrl = IsImagesExist ? API.ImportProducts : API.CheckProductImages;
                HttpService.postApi(API.ImportProducts, finalArray)
                    .then(response => {
                        if (response.data.message) {
                            toast.warning(response.data.message)
                            setForceUpdate(!isForceUpdate);
                            return
                        }
                        setLoading(false);
                        if (response.data.result.sku_without_images != undefined) {
                            let skuWithoutBaseImage = response.data.result.sku_without_images;
                            let skuWithoutVideo = response.data.result.sku_without_video;
                            if (skuWithoutBaseImage.length > 0 || skuWithoutVideo.length > 0) {
                                setIsImagesExist(false);

                                swal({
                                    title: Messages.oops,
                                    content: imageErrorMessage(skuWithoutBaseImage, skuWithoutVideo),
                                    icon: "error",
                                    buttons: {
                                        cancel: true,
                                        confirm: false,
                                    }
                                });
                            }
                        }
                        else {
                            toast.success(Messages.Record_Added)
                            setForceUpdate(!isForceUpdate);
                            history.push('/products/ring')
                        }

                    });
            }
        });
    }


    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {Title.ImportExcel} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Upload Excel</label>
                                            <input type="file" name="excel_sheet" onChange={uploadExcelFile} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{ display: 'block' }} className="form-control" />
                                            {validator.current.message('Sheet', Inputs.excel_sheet, 'required', { className: 'error' })}
                                            <a href={exampleSheet} className="float-right" style={{ 'font-size': '12px', 'color': "#000000d4", 'text-decoration': "underline" }} download>Example Sheet</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        {/* <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Check Images </button> */}
                                        <button type="button" onClick={SubmitForm} className={true ? "btn btn-primary mr-2" : "btn btn-primary mr-2 disabled"}> Submit </button>
                                        <Link to="/products" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportProducts;