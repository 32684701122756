import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';
const AddRole = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const validator = useRef(new SimpleReactValidator())
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [PermissionList, setPermissionList] = useState([])
    const [Inputs, setInputs] = useState({
        id: 0,
        name: "",
        permissions: [],
        status: 0
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.role_permission)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddRole
        if (urlParams.id != null) {
            document.title = Title.EditRole
            GetRolebyID()
        }
        GetPermissionList();
    }, [urlParams.id])

    const GetRolebyID = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetRoleByID, param)
            .then(response => {
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const GetPermissionList = () => {
        HttpService.getApi(API.GetPermission)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result[0];
                    setPermissionList(data)
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status") {
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                }
                else if (prop == 'permissions') {
                    const target = event.target;
                    var value = target.value;
                    if (target.checked) {
                        Inputs.permissions.push(parseInt( value));
                    } else {
                        Inputs.permissions = Inputs.permissions.filter(e => e !== parseInt(value))
                    }
                }
                else {
                    setInputs({ ...Inputs, [prop]: event.target.value });
                }
                console.log(Inputs);
                setForceUpdate(!isForceUpdate)
            };

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        // if (submitbtnRef.current)
        //     submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "permission": Inputs.permissions
        }
        HttpService.postApi(API.SetRole, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/roles')
            });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddRole : Title.AddRole} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name"
                                                onChange={handleInputChanges("name")}
                                                onKeyUp={handleInputChanges("name")}
                                                onBlur={() => validator.current.showMessageFor("Name")}
                                                value={Inputs.name} placeholder="Name" />
                                            {validator.current.message('Name', Inputs.name, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className='card-header'>
                            <h5 className="page-title"> {Title.Permissions} </h5>
                        </div>
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    {
                                        PermissionList.length > 0 ?
                                            PermissionList.map((item, index) => (
                                                <div className="col-md-3" key={index}>
                                                    <div className="form-group">
                                                        <input type="checkbox" className="mr-2" name="permissions"
                                                            onChange={handleInputChanges("permissions")}
                                                            value={item.id}
                                                            checked={Inputs.permissions.includes(item.id) ? true : false}
                                                        />
                                                        <label>{item.name}</label>
                                                    </div>
                                                </div>
                                            ))
                                            : ''
                                    }
                                </div>
                                <div className="row">
                                    {validator.current.message('Permission', Inputs.permissions, 'required', { className: 'error' })}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            <div className="row">
                <div className="col-md-6">
                    <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                    <Link to="/roles" className="btn btn-light">Cancel</Link>
                </div>
            </div>
        </div >
    );
};

export default AddRole;