import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService,Common, API,Permission_keys, SimpleReactValidator, Title, toast, Messages, DropdownTreeSelect, Button, Modal, MaterialTable, DateFormat } from '../../config/react'
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import RelatedProduct from './RelatedProducts';
import CreateConfiguration from './CreateConfiguration';
import ConfigurationImagePrice from './ConfigurationImagePrice';
import AddImage from '../template/AddImage';
import AddVideo from '../template/AddVideo';
import Spinner from '../Spinner';
import { S3_BUCKET_URL } from '../../config/HttpService';
import Moment from 'moment';

const AddProducts = () => {
    const urlParams = useParams();
    const history = useHistory();
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [IsAuth, setIsAuth] = useState(false)
    const [AttributeSet, setAttributeSetList] = useState([])
    const [Categories, setCategories] = useState([])
    const [AttributesBySet, setAttributesBySetList] = useState([]);
    const [ImagesArray, setImagesArray] = useState([]);
    const [ShowModal, setShowModal] = useState(false);
    const [ModalTitle, setModalTitle] = useState('');
    const [ModalContent, setModalContent] = useState('');
    const [ModalSize, setModalSize] = useState('lg');
    const [addVideoStatus, setAddVideoStatus] = useState(true);
    const [RelatedProducts, setRelatedProducts] = useState([]);
    const [ShortDescription, setShortDescription] = useState('');
    const [LongDescription, setLongDescription] = useState('');
    const [VideoDetail, setVideoDetail] = useState([]);
    const [SelectedAttributesValues, setSelectedAttributesValues] = useState([]);
    const [ConfigurableProducts, setConfigurableProducts] = useState([]);
    const [SelectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [ConfigurableProductsStatus, setConfigurableProductsStatus] = useState(false);
    const [MetalQualityList, setMetalQualityList] = useState([])
    const [ImageTempleteRander, SetImageTempleteRander] = useState(false);
    const [Inputs, setInputs] = useState({
        id: 0,
        attribute_set_id: "",
        name: "",
        sku: "",
        parent_sku: "",
        price: "",
        special_price: "",
        special_to_date: "",
        metal_quality_value: "",
        url_key: "",
        url_path: "",
        meta_title: "",
        meta_keywords: "",
        meta_description: "",
        weight: "",
        view_360: 0,
        is_readytoship: 0,
        is_sold: 0,
        qty: "",
        status: 0
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddProducts
        GetProductAttributeSetList()
        if (urlParams.id != null) {
            document.title = Title.EditProducts
            GetProductDetail();
        }
        else {
            setConfigurableProductsStatus(true);
            GetCategories()
            SetImageTempleteRander(true);
        }
    }, [urlParams.id])

    const GetProductDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetProductsDetail, param)
            .then(response => {
                var data = response.data.result[0][0];
                if (data.special_to_date != "") {
                    var date = Moment(data.special_to_date).format("YYYY-MM-DD");
                    data.special_to_date = date;
                }
                console.log(data);
                setInputs(data);
                var categoryIds = data.category_ids;
                setShortDescription(decodeURI(data.short_description));
                setLongDescription(decodeURI(data.description));
                if(categoryIds != "" && categoryIds != null){
                    setSelectedCategoryIds(categoryIds.split(", "));
                    GetCategories(categoryIds.split(", "));
                }
                else{
                    setSelectedCategoryIds(categoryIds);
                    GetCategories(categoryIds);
                }
                var selectedAttributeIds = JSON.parse(data.selected_attribute_id);
                var selectedAttributeValues = JSON.parse(data.selected_attribute_value);
                for (var key in selectedAttributeIds) {
                    if (selectedAttributeIds[key] != null) {
                        var splitId = selectedAttributeIds[key].split("~");
                        if (splitId[0] != "" && splitId[0] != "null") {
                            SelectedAttributesValues.push({ 'option_id': splitId[0], 'value': null, 'attributeId': splitId[1] });
                        }
                    }
                    if (selectedAttributeValues[key] != null) {
                        var splitValue = selectedAttributeValues[key].split("~");
                        if (splitValue[0] != "" && splitValue[0] != "null") {
                            SelectedAttributesValues.push({ 'option_id': null, 'value': splitId[0], 'attributeId': splitId[1] });
                        }
                    }
                }
                GetAttributesBySetList(data.attribute_set_id);
                var RelateProd = response.data.result[1];
                var relatedProds = [];
                for (var key in RelateProd) {
                    if (RelateProd[key].hasOwnProperty('id')) {
                        relatedProds.push(RelateProd[key]);
                    }
                }
                setRelatedProducts(relatedProds);
                var imagesArray = [];
                if (data.base_image != "") {
                    imagesArray.push({ 'image_type': 'base_image', 'image': data.base_image });
                }
                if (data.modal_image != "") {
                    imagesArray.push({ 'image_type': 'modal_image', 'image': data.modal_image });
                }
                if (data.dimention_image != "") {
                    imagesArray.push({ 'image_type': 'dimention_image', 'image': data.dimention_image });
                }
                if (data.other_image != "[]") {
                    var otherImages = data.other_image;
                    for (var key in otherImages) {
                        if (otherImages[key] != "") {
                            imagesArray.push({ 'image_type': 'other_image', 'image': otherImages[key] });
                        }
                    }
                }
                setImagesArray(imagesArray);
                SetImageTempleteRander(true);
                if (data.video_url != null || data.video_title != null
                    || data.video_description != null || data.video_preview_image != null) {
                    setAddVideoStatus(false);
                    setVideoDetail({
                        url: data.video_url,
                        title: data.video_title,
                        description: data.video_description,
                        preview_image: data.video_preview_image
                    });
                }
                var configurableProduct = response.data.result[2];
                var dataArray = [];
                for (var i = 0; i < configurableProduct.length; i++) {
                    var imagesArray = [];
                    if (configurableProduct[i].base_image != "") {
                        imagesArray.push({ 'image_type': 'base_image', 'image': configurableProduct[i].base_image });
                    }
                    if (configurableProduct[i].modal_image != "") {
                        imagesArray.push({ 'image_type': 'modal_image', 'image': configurableProduct[i].modal_image });
                    }
                    if (configurableProduct[i].dimention_image != "") {
                        imagesArray.push({ 'image_type': 'dimention_image', 'image': configurableProduct[i].dimention_image });
                    }
                    if (configurableProduct[i].other_image != "[]") {
                        var otherImages = configurableProduct[i].other_image;
                        for (var key in otherImages) {
                            if (otherImages[key] != "") {
                                imagesArray.push({ 'image_type': 'other_image', 'image': otherImages[key] });
                            }
                        }
                    }
                    dataArray.push({
                        'id': configurableProduct[i].metal_quality.trim(),
                        'metal_quality': configurableProduct[i].value.trim(),
                        'imageStatus': 'get_unique',
                        'priceStatus': 'get_unique',
                        'quantityStatus': 'get_unique',
                        'images': imagesArray,
                        'price': configurableProduct[i].price,
                        'quantity': configurableProduct[i].qty
                    });
                }
                if (dataArray.length == 0) {
                    setConfigurableProductsStatus(false);
                }
                else{
                    setConfigurableProductsStatus(true);
                }
                setConfigurableProducts(dataArray);
                setForceUpdate(!isForceUpdate)
            });
    };

    const handleShortDescChange = (e) => {
        setShortDescription(e);
        setForceUpdate(!isForceUpdate)
    }

    const handleEditorChange = (e) => {
        setLongDescription(e);
        setForceUpdate(!isForceUpdate)
    }

    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status" || field == "is_sold" || field == "is_readytoship" || field == "view_360") {
            setInputs({ ...Inputs, [field]: e.target.checked });
        }
        else if (field == "attribute_set_id") {
            setInputs({ ...Inputs, [field]: fieldval });
            GetAttributesBySetList(fieldval)
        }
        else {
            setInputs({ ...Inputs, [field]: fieldval });
        }
        setForceUpdate(!isForceUpdate);
    }

    const handleImageCallback = (images) => {
        setImagesArray(images);
        setInputs({ ...Inputs, ['images']: images });
        setForceUpdate(!isForceUpdate);
    }

    const GetAttributesBySetList = (id) => {
        if (id != "") {
            var param = {
                "set_id": id
            };
            HttpService.postApi(API.GetAttributesBySetList, param).then(response => {
                var data = response.data.result[0][0];
                var attributeArray = [];
                var attributes = data.attributes.split(",");
                var attributeIds = data.attribute_id.split(",");
                var attributeNames = data.attribute_name.split(",");
                var attributeTypes = data.input_type.split(",");
                var attributeOptions = data.attribute_option.split(",");
                for (var i = 0; i < attributes.length; i++) {
                    var optionArray = [];
                    for (var j = 0; j < attributeOptions.length; j++) {
                        var options = attributeOptions[j].split('~');
                        if (options[2] == attributeIds[i]) {
                            optionArray[options[1]] = options[0];
                        }
                    }
                    var types = attributeTypes[i].split("~");
                    if (types[0] != "checkbox") {
                        var defaultData = "";
                        for (var key in SelectedAttributesValues) {
                            if (SelectedAttributesValues[key]['attributeId'] == attributeIds[i]) {
                                if (SelectedAttributesValues[key]['option_id'] != null) {
                                    defaultData = SelectedAttributesValues[key]['option_id'];
                                }
                                if (SelectedAttributesValues[key]['value'] != null) {
                                    defaultData = SelectedAttributesValues[key]['value'];
                                }
                            }
                        }
                    }
                    else {
                        var defaultData = [];
                        for (var key in SelectedAttributesValues) {
                            if (SelectedAttributesValues[key]['attributeId'] == attributeIds[i]) {
                                if (SelectedAttributesValues[key]['option_id'] != null) {
                                    defaultData.push(parseInt(SelectedAttributesValues[key]['option_id']));
                                }
                            }
                        }
                    }
                    var arr = {
                        'attribute_code': attributes[i],
                        'attribute_name': attributeNames[i],
                        'id': attributeIds[i],
                        'defaultValue': defaultData,
                        'input_type': types[0],
                        'options': optionArray
                    };
                    attributeArray.push(arr);
                }
                console.log(attributeArray);
                setAttributesBySetList(attributeArray)
                setForceUpdate(!isForceUpdate)
            });
        }
    }

    const handleAttributeValue = (e) => {
        var attributeType = "";
        if (e.target.attributes.hasOwnProperty('type')) {
            attributeType = e.target.attributes['type'].nodeValue;
        }
        var attributeId = e.target.attributes['data-id'].nodeValue;
        var attributeValue = e.target.value;
        if (attributeType == "checkbox") {
            var status = "not";
            for (var i = 0; i < SelectedAttributesValues.length; i++) {
                if (SelectedAttributesValues[i]['attributeId'] == attributeId && SelectedAttributesValues[i]['option_id'] == attributeValue) {
                    status = "match";
                    SelectedAttributesValues.splice(i, 1);
                }
            }
            if (status == "not") {
                SelectedAttributesValues.push({ 'option_id': attributeValue, 'attributeId': attributeId });
            }
        }
        else if (attributeType == "text") {
            var status = "not";
            for (var i = 0; i < SelectedAttributesValues.length; i++) {
                if (SelectedAttributesValues[i]['attributeId'] == attributeId) {
                    status = "match";
                    SelectedAttributesValues[i]['value'] = attributeValue;
                }
            }
            if (status == "not") {
                SelectedAttributesValues.push({ 'value': attributeValue, 'attributeId': attributeId });
            }
        }
        else {
            var status = "not";
            for (var i = 0; i < SelectedAttributesValues.length; i++) {
                if (SelectedAttributesValues[i]['attributeId'] == attributeId) {
                    status = "match";
                    SelectedAttributesValues[i]['option_id'] = attributeValue;
                }
            }
            if (status == "not") {
                SelectedAttributesValues.push({ 'option_id': attributeValue, 'attributeId': attributeId });
            }
        }
        setForceUpdate(!isForceUpdate)
    };

    const GetProductAttributeSetList = () => {
        HttpService.getApi(API.GetProductAttributeSetList)
            .then(response => {
                setAttributeSetList(response.data.result[0])
                setForceUpdate(!isForceUpdate)
            });
    }

    const onChangeCategory = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedCategoryIds(array);
        console.log(array);
        Categories.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                Categories[index].checked = true;
            }
            else {
                Categories[index].checked = false;
            }
            item.children.map((subitem, subindex) => {
                if (array.indexOf(subitem.value) >= 0) {
                    Categories[index].children[subindex].checked = true;
                }
                else {
                    Categories[index].children[subindex].checked = false;
                }
                subitem.children.map((subitem1, subindex1) => {
                    if (array.indexOf(subitem1.value) >= 0) {
                        Categories[index].children[subindex].children[subindex1].checked = true;
                    }
                    else {
                        Categories[index].children[subindex].children[subindex1].checked = false;
                    }
                })
            })
        });
    };

    const GetCategories = (selectedCategory = null) => {
        HttpService.postApi(API.GetCategory, null)
            .then(response => {
                if (response.data.result) {
                    //filter parent category
                    var parentCategories = response.data.result[0].filter(x => x.parent_id == 1)
                    var categories = [];

                    parentCategories.map((item) => {
                        //filter sub categories by parent
                        var filteredSubcategories = response.data.result[0].filter(x => x.parent_id == item.id)
                        var subcategories = [];
                        filteredSubcategories.map((subitem) => {
                            //filter sub child categories by parent
                            var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id)
                            var subchildcategories = [];
                            filteredSubChildCategories.map((childitem) => {
                                subchildcategories.push({
                                    label: childitem.name,
                                    value: childitem.id,
                                    checked: false,
                                    children: []
                                })
                            });
                            subcategories.push({
                                label: subitem.name,
                                value: subitem.id,
                                checked: false,
                                children: subchildcategories
                            })
                        });
                        categories.push({
                            label: item.name,
                            value: item.id,
                            children: subcategories,
                            checked: false
                        })
                    });
                    if (selectedCategory != null) {
                        for (var i = 0; i < categories.length; i++) {
                            selectedCategory.map((cate) => {
                                if (cate == categories[i].value) {
                                    categories[i].checked = true;
                                }
                            });
                            var children = categories[i].children;
                            if (children.length > 0) {
                                for (var j = 0; j < children.length; j++) {
                                    selectedCategory.map((cate1) => {
                                        if (cate1 == children[j].value) {
                                            children[j].checked = true;
                                        }
                                    });
                                }
                            }
                        }
                    }
                    setCategories(categories)
                }
            });

    }

    const AddConfigurableProducts = (e) => {
        setModalTitle(Title.ProductConfigurations);
        setModalContent('configurable');
        setModalSize('lg');
        setShowModal(true);
    };

    const handleRelatedProductCallback = (productIds) => {
        setRelatedProducts(productIds);
        setInputs({ ...Inputs, ['relatedProducts']: productIds });
        setForceUpdate(!isForceUpdate)
    };

    const handleConfigurableCallback = (e) => {
        setConfigurableProducts(e);
        setForceUpdate(!isForceUpdate)
    };

    const handleConfigurableProductCallback = (e) => {
        setConfigurableProducts(e);
    }

    const AddRelatedProducts = (e) => {
        setModalTitle(Title.RelatedProduct);
        setModalContent('relatedProduct');
        setModalSize('lg');
        setShowModal(true);
    };

    const SubmitModal = (e) => {
        if (ModalContent == 'relatedProduct') {
            setShowModal(false);
        }
        else if (ModalContent == 'configurable') {
            setModalTitle(Title.ConfigurableStep1);
            setModalContent('configurable-step1');
        }
        else {
            setShowModal(false);
        }
    };

    const handleAddVideoBtn = (e) => {
        setModalTitle(Title.AddVideo);
        setModalContent('addVideo');
        setModalSize('md');
        setShowModal(true);
    };

    const handleEditVideoBtn = (e) => {
        setModalTitle(Title.EditVideo);
        setModalContent('addVideo');
        setModalSize('md');
        setShowModal(true);
    };

    const handleVideoLink = (e) => {
        setVideoDetail(e);
        if (e.hasOwnProperty('url')) {
            setAddVideoStatus(false);
        }
        else {
            setAddVideoStatus(true);
        }
        setForceUpdate(!isForceUpdate)
    };

    const handleAccordionClick = (e) => {
        /*var className = e.target.children[1].className;
        var classSplit = className.split(" ");
        if(classSplit.indexOf("mdi-chevron-down") > -1){
            var index = classSplit.indexOf("mdi-chevron-down");
            classSplit[index] = "mdi-chevron-up";
            e.target.children[1].className = classSplit.join(" ");
        }
        else{
            var index = classSplit.indexOf("mdi-chevron-up");
            classSplit[index] = "mdi-chevron-down";
            e.target.children[1].className = classSplit.join(" ");
        }*/
    };

    const SubmitForm = (e) => {
        var mainbaseImage = '';
        var mainmodalImage = '';
        var maindimentionImage = '';
        var mainotherImages = [];
        for (var i = 0; i < ImagesArray.length; i++) {
            if (ImagesArray[i]['image_type'] == "base_image") {
                mainbaseImage = {'image': ImagesArray[i].image, 'name': ImagesArray[i].name};
            }
            else if (ImagesArray[i]['image_type'] == "modal_image") {
                mainmodalImage = {'image': ImagesArray[i].image, 'name': ImagesArray[i].name};
            }
            else if (ImagesArray[i]['image_type'] == "dimention_image") {
                maindimentionImage = {'image': ImagesArray[i].image, 'name': ImagesArray[i].name};
            }
            else {
                mainotherImages.push({'image': ImagesArray[i].image, 'name': ImagesArray[i].name});
            }
        }
        var ImagesData = {
            'base_image': mainbaseImage,
            'modal_image': mainmodalImage,
            'dimention_image': maindimentionImage,
            'other_image': JSON.stringify(mainotherImages)
        };
        var metal_quality = [];
        for (var j = 0; j < ConfigurableProducts.length; j++) {
            var baseImage = '';
            var modalImage = '';
            var dimentionImage = '';
            var otherImages = [];
            metal_quality.push(ConfigurableProducts[j]['id']);
            if (ConfigurableProducts[j]['imageStatus'] == "get_unique") {
                var images = ConfigurableProducts[j]['images'];
                for (var i = 0; i < images.length; i++) {
                    if (images[i]['image_type'] == "base_image") {
                        if(images[i].image != ""){
                            baseImage = {'image': images[i].image, 'name': images[i].name};
                        }
                        else{
                            baseImage = mainbaseImage;
                        }
                    }
                    else if (images[i]['image_type'] == "modal_image") {
                        if(images[i].image != ""){
                            modalImage = {'image': images[i].image, 'name': images[i].name};
                        }
                        else{
                            modalImage = mainmodalImage;
                        }
                    }
                    else if (images[i]['image_type'] == "dimention_image") {
                        if(images[i].image != ""){
                            dimentionImage = {'image': images[i].image, 'name': images[i].name};
                        }
                        else{
                            dimentionImage = maindimentionImage;
                        }
                    }
                    else {
                        if(images[i].image != ""){
                            otherImages.push({'image': images[i].image, 'name': images[i].name});
                        }
                        else{
                            otherImages = mainotherImages;
                        }
                    }
                }
                ConfigurableProducts[j]['images'] = {
                    'base_image': baseImage,
                    'modal_image': modalImage,
                    'dimention_image': dimentionImage,
                    'other_image': JSON.stringify(otherImages)
                };
            }
            else{
                ConfigurableProducts[j]['images'] = {
                    'base_image': mainbaseImage,
                    'modal_image': mainmodalImage,
                    'dimention_image': maindimentionImage,
                    'other_image': JSON.stringify(mainotherImages)
                };
            }
        }
        var relatedPro = [];
        for (var i = 0; i < RelatedProducts.length; i++) {
            relatedPro.push(RelatedProducts[i]['id']);
        }
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var param = {
            "id": Inputs.id,
            "attribute_set_id": Inputs.attribute_set_id,
            "metal_quality": JSON.stringify(metal_quality),
            "metal_quality_value": Inputs.metal_quality_value,
            "is_configured": ConfigurableProductsStatus === true ? 1 : 0,
            "parent_product_id": ConfigurableProductsStatus === true ? 0 : Inputs.parent_product_id,
            "description": encodeURI(LongDescription),
            "is_readytoship": (Inputs.is_readytoship === true || Inputs.is_readytoship == '1') ? 1 : 0,
            "is_sold": (Inputs.is_sold === true || Inputs.is_sold == '1') ? 1 : 0,
            "meta_description": Inputs.meta_description,
            "meta_keywords": Inputs.meta_keywords,
            "meta_title": Inputs.meta_title,
            "name": Inputs.name,
            "price": Inputs.price,
            "parent_sku": Inputs.parent_sku,
            "qty": Inputs.qty,
            "short_description": encodeURI(ShortDescription),
            "sku": Inputs.sku,
            "special_price": Inputs.special_price,
            "special_to_date": Inputs.special_to_date,
            "status": (Inputs.status === true || Inputs.status == '1') ? 1 : 0,
            "url_key": Inputs.url_key,
            "url_path": Inputs.url_path,
            "view_360": (Inputs.view_360 === true || Inputs.view_360 == '1') ? 1 : 0,
            "weight": Inputs.weight,
            "Userid": user.id,
            "videoDetail": VideoDetail,
            "attributes": SelectedAttributesValues,
            "category_id": SelectedCategoryIds,
            "configurationValues": ConfigurableProducts,
            "images": ImagesData,
            "related_products": JSON.stringify(relatedPro)
        };
        console.log(param);
        if (urlParams.id == null) {
            HttpService.postApi(API.AddProducts, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    toast.success(Messages.Record_Added)
                    setForceUpdate(!isForceUpdate);
                    history.push('/products')
                });
        }
        else {
            HttpService.postApi(API.UpdateProducts, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    toast.success(Messages.Record_Updated)
                    setForceUpdate(!isForceUpdate);
                    history.push('/products')
                });
        }

    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddProducts : Title.EditProducts} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Attribute Set</label>
                                            <select className="form-control" name="attribute_set_id" value={Inputs.attribute_set_id} onChange={handleInputChanges}>
                                                <option value="">--Select--</option>
                                                {AttributeSet.map((attributeSet, index) => (
                                                    <option value={attributeSet.id}>{attributeSet.name}</option>
                                                ))}
                                            </select>
                                            {validator.current.message('Attribute Set', Inputs.attribute_set_id, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control" name="name" onChange={handleInputChanges} defaultValue={Inputs.name} placeholder="Name" />
                                            {validator.current.message('Name', Inputs.name, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input type="text" className="form-control" name="sku" onChange={handleInputChanges} defaultValue={Inputs.sku} placeholder="SKU" />
                                            {validator.current.message('Sku', Inputs.sku, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Categories</label>
                                            {
                                                Categories.length > 0 &&
                                                <DropdownTreeSelect data={Categories} mode="hierarchical" onChange={onChangeCategory} className="bootstrap-demo w-100" />
                                            }
                                            {validator.current.message('Category', SelectedCategoryIds, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Weight</label>
                                            <input type="text" className="form-control" name="weight" onChange={handleInputChanges} defaultValue={Inputs.weight} placeholder="Weight" />
                                            {validator.current.message('Weight', Inputs.weight, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Quantity</label>
                                            <input type="text" className="form-control" name="qty" onChange={handleInputChanges} defaultValue={Inputs.qty} placeholder="QTY" />
                                            {validator.current.message('QTY', Inputs.qty, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Price</label>
                                            <input type="text" className="form-control" name="price" onChange={handleInputChanges} defaultValue={Inputs.price} placeholder="Price" />
                                            {validator.current.message('Price', Inputs.price, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Special Price</label>
                                            <input type="text" className="form-control" name="special_price" onChange={handleInputChanges} defaultValue={Inputs.special_price} placeholder="Special Price" />
                                            {/* {validator.current.message('Special Price', Inputs.special_price, 'required', { className: 'error' })} */}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Special To Date</label>
                                            <input type="date" className="form-control" name="special_to_date" onChange={handleInputChanges} defaultValue={Inputs.special_to_date} placeholder="Special To Date" />
                                            {/* {validator.current.message('Special To Date', Inputs.special_to_date, 'required', { className: 'error' })} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername" className="col-sm-12">View 360</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="view_360" onChange={handleInputChanges} id="view_360" checked={Inputs.view_360 == '1' ? true : false} />
                                                    <label className="custom-control-label" htmlFor="view_360"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername1" className="col-sm-12">Ready to Ship</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="is_readytoship" onChange={handleInputChanges} id="is_readytoship" checked={Inputs.is_readytoship == '1' ? true : false} />
                                                    <label className="custom-control-label" htmlFor="is_readytoship"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Is Sold</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="is_sold" onChange={handleInputChanges} id="is_sold" checked={Inputs.is_sold == '1' ? true : false} />
                                                    <label className="custom-control-label" htmlFor="is_sold"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername3" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="status" checked={Inputs.status == '1' ? true : false} />
                                                    <label className="custom-control-label" htmlFor="status"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="accordion">
                                    <div className="card shadow-none border-top pt-2 border-bottom">
                                        <a data-toggle="collapse" onClick={handleAccordionClick} data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div className="card-header border-none">
                                                <label>Content</label>
                                                <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                                            </div>
                                        </a>

                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body p-3 pt-0 font-dark">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Short Description</label>
                                                            <ReactSummernote
                                                                value={ShortDescription}
                                                                options={{
                                                                    height: 350,
                                                                    dialogsInBody: true,
                                                                    toolbar: [
                                                                        ["style", ["style"]],
                                                                        ["font", ["bold", "underline", "clear", 'strikethrough', 'superscript', 'subscript']],
                                                                        ["fontname", ["fontname"]],
                                                                        ["para", ["ul", "ol", "paragraph"]],
                                                                        ["table", ["table"]],
                                                                        ["insert", ["link", "picture", "video"]],
                                                                        ["view", ["fullscreen", "codeview"]],
                                                                        ['height', ['height']]
                                                                    ]
                                                                }}
                                                                onChange={handleShortDescChange}
                                                            />
                                                            {/* {validator.current.message('Short Description', ShortDescription, 'required', { className: 'error' })} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Long Description</label>
                                                            <ReactSummernote
                                                                value={LongDescription}
                                                                options={{
                                                                    height: 350,
                                                                    dialogsInBody: true,
                                                                    toolbar: [
                                                                        ["style", ["style"]],
                                                                        ["font", ["bold", "underline", "clear", 'strikethrough', 'superscript', 'subscript']],
                                                                        ["fontname", ["fontname"]],
                                                                        ["para", ["ul", "ol", "paragraph"]],
                                                                        ["table", ["table"]],
                                                                        ["insert", ["link", "picture", "video"]],
                                                                        ["view", ["fullscreen", "codeview"]],
                                                                        ['height', ['height']]
                                                                    ]
                                                                }}
                                                                onChange={handleEditorChange}
                                                            />
                                                            {/* {validator.current.message('Long Description', LongDescription, 'required', { className: 'error' })} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        ConfigurableProductsStatus &&
                                        <div className="card shadow-none border-bottom mt-2">
                                            <a data-toggle="collapse" onClick={handleAccordionClick} data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                <div className="card-header border-none">
                                                    <label>Configurations</label>
                                                    <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                                                </div>
                                            </a>

                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div className="card-body p-3 pt-0 font-dark">
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            Configurable products allow customers to choose options (Ex: 14K White Gold). You need to create a simple product for each configuration (Ex: a product for each quality).
                                                        </div>
                                                        <div className="col-md-3 text-right">
                                                            <button type="button" onClick={AddConfigurableProducts} className="btn btn-primary">Create Configurations</button>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <MaterialTable
                                                            title=""
                                                            columns={[
                                                                { title: 'Metal Quality', field: 'metal_quality' },
                                                                {
                                                                    title: 'Image', field: 'imageStatus',
                                                                    render: rowData => <img src={(rowData.imageStatus == 'get_unique' && rowData.images.length > 0) ? rowData.images[0].image : (ImagesArray.length > 0 ? ImagesArray[0]['image'] : S3_BUCKET_URL + 'assests/images/blank-image.jpg')} width="100" />
                                                                },
                                                                {
                                                                    title: 'Price', field: 'priceStatus',
                                                                    render: rowData => (rowData.priceStatus == 'get_unique' ? rowData.price : Inputs['price'])
                                                                },
                                                                {
                                                                    title: 'Quantity', field: 'quantityStatus',
                                                                    render: rowData => (rowData.quantityStatus == 'get_unique' ? rowData.quantity : Inputs['qty'])
                                                                },
                                                            ]}

                                                            data={ConfigurableProducts}
                                                            options={{
                                                                thirdSortClick: false,
                                                                pageSize: 10,
                                                                emptyRowsWhenPaging: false,
                                                                search: false,
                                                                exportCsv: (columns, data) => {
                                                                    alert('You should develop a code to export ' + data.length + ' rows');
                                                                },

                                                            }}
                                                        />
                                                    </div>
                                                    {validator.current.message('Configuration', ConfigurableProducts, 'required', { className: 'error' })}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="card shadow-none border-bottom mt-2">
                                        <a data-toggle="collapse" onClick={handleAccordionClick} data-target="#collapseThird" aria-expanded="true" aria-controls="collapseThird">
                                            <div className="card-header border-none">
                                                <label>Attributes</label>
                                                <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                                            </div>
                                        </a>

                                        <div id="collapseThird" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body p-3 pt-0 font-dark">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {
                                                            AttributesBySet.length > 0 &&
                                                            AttributesBySet.map((attributeSet, index1) => (
                                                                <div className="row mt-2">
                                                                    <div className="col-md-4">
                                                                        <label htmlFor="">{attributeSet.attribute_name}</label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        {
                                                                            attributeSet.input_type == "dropdown" &&
                                                                            <select onChange={handleAttributeValue} defaultValue={attributeSet.defaultValue} data-id={attributeSet.id} className="form-control">
                                                                                <option value="">Please Select ...</option>
                                                                                {attributeSet.options.map((option, index) => (
                                                                                    <option value={index}>{option}</option>
                                                                                ))}
                                                                            </select>
                                                                        }
                                                                        {
                                                                            attributeSet.input_type == "text" &&
                                                                            <input type="text" onChange={handleAttributeValue} defaultValue={attributeSet.defaultValue} data-id={attributeSet.id} className="form-control" />
                                                                        }
                                                                        {
                                                                            attributeSet.input_type == "radio" &&
                                                                            <div className="row">
                                                                                {attributeSet.options.map((option, index) => (
                                                                                    <div className="col-md-3">
                                                                                        <input onChange={handleAttributeValue} defaultChecked={attributeSet.defaultValue == index} name={'radioAttr' + attributeSet.id} data-id={attributeSet.id} type="radio" value={index} id={index} />
                                                                                        &nbsp;<label htmlFor={index}>{option}</label>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            attributeSet.input_type == "checkbox" &&
                                                                            <div className="row">
                                                                                {attributeSet.options.map((option, index) => (
                                                                                    <div className="col-md-3">
                                                                                        <input onChange={handleAttributeValue} defaultChecked={attributeSet.defaultValue.includes(index)} name={'checkAttr' + attributeSet.id + '[]'} data-id={attributeSet.id} type="checkbox" value={index} id={index} />
                                                                                        &nbsp;<label htmlFor={index}>{option}</label>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow-none border-bottom mt-2">
                                        <a data-toggle="collapse" onClick={handleAccordionClick} data-target="#collapseFourth" aria-expanded="true" aria-controls="collapseFourth">
                                            <div className="card-header border-none">
                                                <label>Images And Videos</label>
                                                <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                                            </div>
                                        </a>

                                        <div id="collapseFourth" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body p-3 pt-0 font-dark">
                                                <div className='row'>
                                                    <div className='col-md-12 text-right'>
                                                        {
                                                            addVideoStatus &&
                                                            <button type="button" onClick={handleAddVideoBtn} className='btn btn-primary'>Add Video</button>
                                                        }
                                                        {
                                                            addVideoStatus === false &&
                                                            <button type="button" onClick={handleEditVideoBtn} className='btn btn-primary'>Edit Video</button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        ImageTempleteRander &&
                                                        <AddImage dataParentToChild="validatedParentImage" dataImages={ImagesArray} imageCallback={handleImageCallback} />
                                                    }
                                                    {validator.current.message('Image', ImagesArray, 'required', { className: 'error' })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow-none border-bottom mt-2">
                                        <a data-toggle="collapse" onClick={handleAccordionClick} data-target="#collapseFifth" aria-expanded="true" aria-controls="collapseFifth">
                                            <div className="card-header border-none">
                                                <label>Search Engine Optimization</label>
                                                <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                                            </div>
                                        </a>

                                        <div id="collapseFifth" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body p-3 pt-0 font-dark">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>URL Key</label>
                                                            <input type="text" className="form-control" name="url_key" onChange={handleInputChanges} defaultValue={Inputs.url_key} placeholder="URL Key" />
                                                            {/* {validator.current.message('URL Key', Inputs.url_key, 'required', { className: 'error' })} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>URL Path</label>
                                                            <input type="text" className="form-control" name="url_path" onChange={handleInputChanges} defaultValue={Inputs.url_path} placeholder="URL Path" />
                                                            {/* {validator.current.message('URL Path', Inputs.url_path, 'required', { className: 'error' })} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Meta Title</label>
                                                            <input type="text" className="form-control" name="meta_title" onChange={handleInputChanges} defaultValue={Inputs.meta_title} placeholder="Meta Title" />
                                                            {/* {validator.current.message('Meta Title', Inputs.meta_title, 'required', { className: 'error' })} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Meta Keywords</label>
                                                            <input type="text" className="form-control" name="meta_keywords" onChange={handleInputChanges} defaultValue={Inputs.meta_keywords} placeholder="Meta Keywords" />
                                                            {/* {validator.current.message('Meta Keywords', Inputs.meta_keywords, 'required', { className: 'error' })} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Meta Description</label>
                                                            <textarea name="meta_description" className="form-control" onChange={handleInputChanges} defaultValue={Inputs.meta_description} id="" rows="3" placeholder="Meta Description"></textarea>
                                                            {/* {validator.current.message('Meta Description', Inputs.meta_description, 'required', { className: 'error' })} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card shadow-none border-bottom mt-2">
                                        <a data-toggle="collapse" onClick={handleAccordionClick} data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                            <div className="card-header border-none">
                                                <label>Related Products</label>
                                                <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                                            </div>
                                        </a>

                                        <div id="collapseSix" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body p-3 pt-0 font-dark">
                                                <div className="row">
                                                    <div className="col-md-9 pt-2">
                                                        Related products are shown to customers in addition to the item the customer is looking at.
                                                    </div>
                                                    <div className="col-md-3 text-right">
                                                        <button type="button" onClick={AddRelatedProducts} className="btn btn-primary">Add Related Products</button>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <MaterialTable
                                                        title=""
                                                        columns={[
                                                            { title: 'Name', field: 'name' },
                                                            { title: 'Category', field: 'category_name' },
                                                            { title: 'Attribute Set', field: 'attribute_set_id' },
                                                            { title: 'Metal Weight', field: 'weight' },
                                                            { title: 'Price', field: 'price' },
                                                            {
                                                                title: 'Created Date', field: 'created_at', type: 'datetime',
                                                                render: rowData => <DateFormat value={rowData.created_at}></DateFormat>
                                                            }
                                                        ]}

                                                        data={RelatedProducts}
                                                        options={{
                                                            thirdSortClick: false,
                                                            pageSize: 10,
                                                            emptyRowsWhenPaging: false,
                                                            search: false,
                                                            exportCsv: (columns, data) => {
                                                                alert('You should develop a code to export ' + data.length + ' rows');
                                                            },

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/products" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size={ModalSize}
                show={ShowModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {ModalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {
                            ModalContent == "relatedProduct" &&
                            <RelatedProduct dataParentToChild={RelatedProducts} parentRelatedProductCallback={handleRelatedProductCallback} />
                        }
                        {
                            ModalContent == "configurable" &&
                            <CreateConfiguration dataParentToChild={ConfigurableProducts} parentCallback={handleConfigurableCallback} />
                        }
                        {
                            ModalContent == "configurable-step1" &&
                            <ConfigurationImagePrice dataParentToChild={ConfigurableProducts} parentCallback={handleConfigurableProductCallback} />
                        }
                        {
                            ModalContent == "addVideo" &&
                            <AddVideo dataParentToChild={VideoDetail} parentCallback={handleVideoLink} />
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={SubmitModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddProducts;