export const Required = "This is required field";
export const Record_Updated = "Record updated successfully";
export const Record_Added = "Record added successfully";
export const Record_Deleted = "Record deleted successfully";
export const Subscriber_Deactivated = "Subscriber deactivated successfully";
export const Response_Send = "Response Sent Successfully!";

export const Diamond_Imported = "Diamond Imported Successfully!";
export const Diamond_Sold = "Diamond Sold Successfully!";
export const Select_Atlest_One_Item = "Select atleast one item.";


//Popup Headers
export const Confirm_Delete = "Delete Record?"
export const Confirm_Enable = "Enable Record?"
export const Confirm_Disable = "Disable Record?"
export const Confirm_Deactivate = "Deactivate Subscriber?"
//Popup Messages
export const Message_Delete = "Data will not be retrieved once deleted."
export const Message_Deactivate = "Newsletter will not be sent to this selected email."

//Navigation Menu
export const Menu_Updated = "Navigation menu updated successfully"
export const Menu_Added = "Navigation menu added successfully"
export const Sitemap_Generated = "Sitemap generated successfully"
// Oops Title
export const oops = "Oops!"

// Error Select Group Message
export const select_group = "You have to select one group to remove by click on group name."
export const atleast_one_group = "You are not able to remove this group. Because you have at least one group in attribute set."
export const default_group_not_delete = "Default group can not delete."

//Clear Cache
export const Confirm_title = "Clear Cache?"
export const Confirm_Clear = "Clear Cache?"
export const sold_Diamond_title = "Mark as Sold?"
export const sold_Diamond_Clear = "Are you sure to do Mark as Sold?"
export const Cache_Clear = "Cache Clear successfully"
export const Reset_Cache = "Reset Cache successfully"
export const Generate_Cache = "Cache generated successfully"

export const Confirm_Cancel_Newsletter = "Cancel Newsletter?"
export const Message_Cancel_Newsletter = "Cancel Newsletter?"
export const Newsletter_Cancelled = "Newsletter cancelled successfully"

export const Product_Image_Verified = "Product images verified successfully"

//Catlog Rule Price
export const same_date = "Start Date and End date should not be same"
export const small_date = "End Date must be greater than Start Date"

export const Swal_Delete_Prop = {
    title: Confirm_Delete,
    text: Message_Delete,
    dangerMode: true,
    buttons: {
        cancel: true,
        danger: {
            text: "Ok",
        },
    }
}

export const Swal_Deactivate_Prop = {
    title: Confirm_Deactivate,
    text: Message_Deactivate,
    dangerMode: true,
    buttons: {
        cancel: true,
        danger: {
            text: "Ok",
        },
    }
}

export const Swal_cancel_newsletter = {
    title: Confirm_Cancel_Newsletter,
    text: Message_Cancel_Newsletter,
    dangerMode: true,
    buttons: {
        cancel: true,
        danger: {
            text: "Ok",
        },
    }
}

export const Cahche_clear_Prop = {
    title: Confirm_title,
    text: Confirm_Clear,
    dangerMode: true,
    buttons: {
        cancel: true,
        danger: {
            text: "Ok",
        },
    }
}
export const diamond_Sold_Prop = {
    title: sold_Diamond_title,
    text: sold_Diamond_Clear,
    dangerMode: true,
    buttons: {
        cancel: true,
        danger: {
            text: "Ok",
        },
    }
}
export const diamond_ReverSold_Prop = {
    title: 'Revert Sold?',
    text: 'Are you sure, want to revert as  unsold ?',
    dangerMode: true,
    buttons: {
        cancel: true,
        danger: {
            text: "Ok",
        },
    }
}


