import React, { useEffect, useState, useContext } from 'react';
import { Link,useHistory } from "react-router-dom";
import UserContext from '../../../config/UserContext'
import { HttpService, API, Title, MaterialTable, PriceFormat, DateFormat ,Common,Permission_keys} from '../../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../../Spinner';
const Metal = () => {
    const [MetalList, setMetalList] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const columns = [
        {
            name: 'Metal Name',
            selector: row => row.metal_type
        },
        {
            name: 'Price',
            selector: row => <PriceFormat value={row.metal_price}></PriceFormat>

        },
        {
            name: 'Labour charge',
            selector: row => <PriceFormat value={row.metal_labour_charge} ></PriceFormat>

        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>

        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/system/metal/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.Metal
        GetMetalList()
    }, [CustomFilters])

    const GetMetalList = () => {
        var passData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        // HttpService.postApi(API.GetMetal, passData)
        HttpService.postApi(API.GetMetalAdminList, passData)
        .then(response => {
            if (response.data.status == true) {
                var data = response.data.result[1];
                // var data = response.data.result[0];

                setMetalList(data)
                setForceUpdate(!isForceUpdate)
                settotalRows(response.data.result[0][0].total_row)
            }
        });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search For Metal Name, Price, Labour Charge." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
		);
	});

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Metal} </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                            <DataTable
                                    columns={columns}
                                    data={MetalList}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Metal;