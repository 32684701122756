import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { HttpService, API, swal, toast, Title, Messages,Common ,Permission_keys} from '../../config/react'
import Spinner from '../Spinner';

const CacheManagement = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false)
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Cache_Management)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.CacheManagement
        // if(!Common.validatePermission('cache_management')){
        //     history.push('/')

        // }
        GetCacheManagementList()
    }, [])

    const GetCacheManagementList = () => {
        HttpService.getApi(API.getcachekeys)
            .then(response => {
                setLoading(false);
                setData(response.data.result);
            });
    }

    const GenerateCache = (e, key) => {
        e.preventDefault()
        setLoading(true);
        var param = {
            "key": key
        }

        HttpService.postApi(API.generatecachekey, param)
            .then(response => {
                toast.success(Messages.Generate_Cache)
                setLoading(false);
                GetCacheManagementList()
            }).catch((error) => {
                toast.success('Something went wrong.')
                setLoading(false);
            });;
    }

    const ClearCache = (e, key) => {
        e.preventDefault()
        swal(Messages.Cahche_clear_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "key": key
                }

                HttpService.postApi(API.removecachebykeys, param)
                    .then(response => {
                        toast.success(Messages.Cache_Clear)
                        GetCacheManagementList()
                    });
            }

        });
    }
    const Rest = ({ item }) => {
        if (item == 'Products' || item == 'Diamonds' || item == 'Diamond Combinations')
            return [<span>|</span>, <Link to="#" onClick={e => ResetCache(item)}> Reset </Link>]
        else
            return ''
    }
    const ResetCache = (key) => {
        var param = {
            "key": key
        }
        HttpService.postApi(API.ResetCache, param)
            .then(response => {
                toast.success(Messages.Reset_Cache)
            });
    }

    const ClearAll = () => {
        swal(Messages.Cahche_clear_Prop).then(function (isConfirm) {
            if (isConfirm) {
                HttpService.getApi(API.removeAllCache)
                    .then(response => {
                        toast.success(Messages.Cache_Clear)
                        GetCacheManagementList()
                    });
            }

        });
    }
    return   !IsAuth||loading ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.CacheManagement} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={ClearAll}> Clear All </button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">

                            <div className="table-responsive">
                                <div className="table-responsive">
                                    <table className="table text-center">
                                        <thead>
                                            <tr>
                                                <th>Cache Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(data).map((item) => (
                                                    <tr>
                                                        <td>{data[item].replace(/_/g, ' ')}</td>
                                                        <td>
                                                            <Link to="#" onClick={e => GenerateCache(e, item)}>Generate </Link>
                                                            |<Link to="#" onClick={e => ClearCache(e, item)}> Clear </Link>
                                                            <Rest item={data[item]}></Rest>
                                                            {/* { data[item] == 'Products' && [<span>|</span>, <Link to="#" onClick={e => ResetCache(item)}> Reset </Link>]} */}



                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CacheManagement;