import React, { useEffect, useState, useContext } from 'react';
import { S3_BUCKET_URL } from '../../config/HttpService';

const AddImage = (props) => {
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [CallImageArray, setCallImageArray] = useState([]);
    const [validateImage, setValidateImage] = useState('');
    useEffect(() => {
        setValidateImage(props.dataParentToChild);
        setCallImageArray(props.dataImages);
        console.log(CallImageArray);
    }, [])

    const readURI = (e) => {
        var fileTypes = ['jpg', 'jpeg', 'png'];
        if (e.target.files) {
            const files = Array.from(e.target.files);
            Promise.all(files.map(file => {
                var extension = file.name.split('.').pop().toLowerCase();
                return (new Promise((resolve, reject) => {
                    if (fileTypes.indexOf(extension) >= 0) {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            var array = {'image': ev.target.result, 'name': file.name};
                            resolve(array);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }
                }));
            }))
                .then(images => {
                    if (CallImageArray.length > 0) {
                        for (var i = (CallImageArray.length - 1); i >= 0; i--) {
                            images.unshift(CallImageArray[i]);
                        }
                    }
                    var imageArray = [];
                    for (var i = 0; i < images.length; i++) {
                        if (i == 0) {
                            imageArray.push({ 'image_type': 'base_image', 'image': images[i].image, 'name': images[i].name });
                        }
                        else if (i == 1) {
                            imageArray.push({ 'image_type': 'modal_image', 'image': images[i].image, 'name': images[i].name });
                        }
                        else if (i == 2) {
                            imageArray.push({ 'image_type': 'dimention_image', 'image': images[i].image, 'name': images[i].name });
                        }
                        else {
                            imageArray.push({ 'image_type': 'other_image', 'image': images[i].image, 'name': images[i].name });
                        }
                    }
                    setCallImageArray(imageArray);
                    props.imageCallback(imageArray);
                    setForceUpdate(!isForceUpdate)
                }, error => {
                    console.error(error);
                });
        }
    };

    const handleImageType = (e, index) => {
        var value = e.target.value;
        var images = CallImageArray;
        images[index].image_type = value;
        setCallImageArray(images);
        props.imageCallback(images);
        setForceUpdate(!isForceUpdate)
    };

    const handleImageRemove = (index) => {
        var images = CallImageArray;
        images.splice(index,1);
        setCallImageArray(images);
        props.imageCallback(images);
        setForceUpdate(!isForceUpdate)
    }

    return (
        <div className="col-md-12">
            <div className="photo-container">
                {
                    CallImageArray.map((imageURI, index) => (
                        <div className="photo-uploaded">
                            <img width="120" height="120" src={imageURI.image} alt="Photo uploaded" />
                            <select onChange={e => handleImageType(e, index)} value={imageURI.image_type} className="form-control">
                                <option value="base_image">Base Image</option>
                                <option value="modal_image">Modal Image</option>
                                <option value="dimention_image">Dimention Image</option>
                                <option value="other_image">Other Image</option>
                            </select>
                            <button type="button" className='btn btn-danger btn-sm btn-block mt-2' onClick={e => handleImageRemove(index)}>Remove</button>
                        </div>
                    ))
                }
                <div className="custom-file productUploadBox">
                    <input type="file" className="custom-file-input" multiple onChange={readURI} accept="image/jpeg,image/jpg,image/png" id={validateImage} />
                    <label for={validateImage}><img src={S3_BUCKET_URL + 'assests/images/blank-image.jpg'} width="120" height="120" className="photo-uploaded border" /></label>
                </div>
            </div>
        </div>
    );
};

export default AddImage;