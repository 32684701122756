import React, { useState, useRef, useEffect, useContext, ChangeEvent } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';
const AddDiamondShape = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    let submitbtnRef = useRef();
    const [Inputs, setInputs] = useState({
        id: 0,
        name: "",
        status: 0
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddDiamondShape
        if (urlParams.id != null) {
            document.title = Title.EditDiamondShape
            GetDiamondShapeDetail()
        }
    }, [urlParams.id])

    const GetDiamondShapeDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetDiamondShape, param)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
            };

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "status": Inputs.status ?? 0
        }

        HttpService.postApi(API.SetDiamondShape, param)
            .then(response => {
                console.log(response);
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)

                setForceUpdate(!isForceUpdate);
                history.push('/system/diamondshape')
            });

    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddDiamondShape : Title.EditDiamondShape} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Diamond Shape</label>
                                            <input type="text" className="form-control" name="name" min="1" maxLength="100"
                                                onChange={handleInputChanges("name")}
                                                onKeyUp={handleInputChanges("name")}
                                                value={Inputs.name}
                                                onBlur={() => validator.current.showMessageFor("Diamond Shape")}
                                                placeholder="Diamond Shape" />
                                            {validator.current.message('Diamond Shape', Inputs.name, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" ref={submitbtnRef} onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/system/diamondshape" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDiamondShape;