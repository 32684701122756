import React, { useEffect, useState, useContext } from 'react';
import { HttpService,Common,Permission_keys, API, PriceFormat, MaterialTable, DateFormat } from '../../config/react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Spinner from '../Spinner';
const CreateConfiguration = (props) => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [MetalQualityList, setMetalQualityList] = useState([])
    const [isForceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        var SelectedMetals = props.dataParentToChild;
        GetProductAttributeDetail(SelectedMetals)
    }, [])

    const GetProductAttributeDetail = (SelectedMetals) => {
        var param = {
            "attribute_code": "metal_quality"
        }
        HttpService.postApi(API.GetProductAttributeDetail, param)
            .then(response => {
                console.log(response);
                if (response.data.result[0][0]) {
                    var data = response.data.result[0][0];
                    var options = JSON.parse(data["attribute_options"]);
                    var array = [];
                    for (var key in options) {
                        var checkStatus = false;
                        var selectedMetalDetails = [];
                        for (var i = 0; i < SelectedMetals.length; i++) {
                            if (SelectedMetals[i]['id'] == key) {
                                checkStatus = true;
                                selectedMetalDetails = SelectedMetals[i];
                            }
                        }
                        if (checkStatus === false) {
                            array.push({
                                'id': key,
                                'metal_quality': options[key],
                                'imageStatus': 'get_parent',
                                'priceStatus': 'get_parent',
                                'quantityStatus': 'get_parent',
                                'images': [],
                                'price': 0,
                                'quantity': 0,
                                'checkStatus': checkStatus
                            });
                        }
                        else {
                            array.push({
                                'id': key,
                                'metal_quality': options[key],
                                'imageStatus': selectedMetalDetails['imageStatus'],
                                'priceStatus': selectedMetalDetails['priceStatus'],
                                'quantityStatus': selectedMetalDetails['quantityStatus'],
                                'images': selectedMetalDetails['images'],
                                'price': selectedMetalDetails['price'],
                                'quantity': selectedMetalDetails['quantity'],
                                'checkStatus': checkStatus
                            });
                        }
                    }
                    setMetalQualityList(array);
                }
            });
    }

    const checkAllCheckbox = (e) => {
        var checkData = [];
        if (e.target.checked) {
            for (var j = 0; j < MetalQualityList.length; j++) {
                MetalQualityList[j]['checkStatus'] = true;
                if(MetalQualityList[j]['checkStatus'] === true){
                    checkData.push(MetalQualityList[j]);
                }
            }
        }
        else {
            for (var j = 0; j < MetalQualityList.length; j++) {
                MetalQualityList[j]['checkStatus'] = false;
                if(MetalQualityList[j]['checkStatus'] === true){
                    checkData.push(MetalQualityList[j]);
                }
            }
        }
        props.parentCallback(checkData);
        setForceUpdate(!isForceUpdate)
    }

    const handleCheckChange = (e) => {
        var checkData = [];
        for (var j = 0; j < MetalQualityList.length; j++) {
            if (MetalQualityList[j]['id'] == e.target.value) {
                if (e.target.checked) {
                    MetalQualityList[j]['checkStatus'] = true;
                }
                else {
                    MetalQualityList[j]['checkStatus'] = false;
                }
            }
            if (MetalQualityList[j]['checkStatus'] === true) {
                checkData.push(MetalQualityList[j]);
            }
        }
        props.parentCallback(checkData);
        setForceUpdate(!isForceUpdate)
    }

    return !IsAuth ? <Spinner /> : (
        <div className="table-responsive">
            <MaterialTable
                title=""
                columns={[
                    {
                        title: <input type="checkbox" onChange={checkAllCheckbox} />, field: 'id', sorting: false,
                        render: rowData => <input type="checkbox" onChange={handleCheckChange} checked={rowData.checkStatus} value={rowData.id} />
                    },
                    { title: 'Metal Quality', field: 'metal_quality' }
                ]}

                data={MetalQualityList}
                options={{
                    thirdSortClick: false,
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    exportCsv: (columns, data) => {
                        alert('You should develop a code to export ' + data.length + ' rows');
                    },

                }}
            />
        </div>
    );
};

export default CreateConfiguration;