import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../config/react'
import ReactSummernote from "react-summernote";
import Spinner from '../Spinner';
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";

const AddContentManagement = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext);
    const [Content, setContent] = useState();
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Inputs, setInputs] = useState({
        id: 0,
        title: "",
        content: "",
        htmlcontent: "",
        page_code: "",
        slug: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        status: 0
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Content_Management)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddContentManagement
        if (urlParams.id != null) {
            document.title = Title.EditContentManagement
            GetContentManagementDetail()
        }
    }, [urlParams.id])

    // useEffect(() => {
    //     if (Inputs.content.length > 0)
    //         setForceUpdate(!isForceUpdate);
    // }, [Inputs.content])


    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)

            };

    const handleEditorChange = (e) => {
        setContent(e)
        // setInputs({ ...Inputs, ["content"]: e });
        setForceUpdate(!isForceUpdate)
    }

    const GetContentManagementDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetContentManagementDetail, param)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setInputs(response.data.result[0])
                    setContent(response.data.result[0].content)
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "title": Inputs.title,
            "content": Content,
            "page_code": Inputs.page_code,
            "slug": Inputs.slug,
            "meta_title": Inputs.meta_title,
            "meta_description": Inputs.meta_description,
            "meta_keywords": Inputs.meta_keywords,
            "status": Inputs.status
        }
        //console.log(param)
        if (urlParams.id == null) {
            HttpService.postApi(API.AddContentManagement, param)
                .then(response => {
                    //console.log(response);
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        submitbtnRef.current.removeAttribute("disabled");
                        return
                    }
                    toast.success(Messages.Record_Added)
                    setForceUpdate(!isForceUpdate);
                    history.push('/contentManagement')
                });
        }
        else {
            HttpService.postApi(API.UpdateContentManagement, param)
                .then(response => {
                    console.log(response);
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        submitbtnRef.current.removeAttribute("disabled");
                        return
                    }
                    toast.success(Messages.Record_Updated)
                    setForceUpdate(!isForceUpdate);
                    history.push('/contentManagement')
                });
        }

    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddContentManagement : Title.EditContentManagement} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                            }} className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Page Title</label>
                                            <input type="text" className="form-control" name="title" min="2" maxLength="255"
                                                onChange={handleInputChanges("title")}
                                                onKeyUp={handleInputChanges("title")}
                                                onBlur={() => validator.current.showMessageFor("Page Title")}
                                                defaultValue={Inputs.title} placeholder="Page Title" />
                                            {validator.current.message('Page Title', Inputs.title, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status"
                                                        onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Page Code</label>
                                            <input type="text" className="form-control" name="page_code" min="1" maxLength="10"
                                                onChange={handleInputChanges("page_code")}
                                                onKeyUp={handleInputChanges("page_code")}
                                                onBlur={() => validator.current.showMessageFor("Page Code")}
                                                defaultValue={Inputs.page_code} placeholder="Page Code" />
                                            {validator.current.message('Page Code', Inputs.page_code, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Slug (URL)</label>
                                            <input type="text" className="form-control" name="slug" min="1" maxLength="50"
                                                onChange={handleInputChanges("slug")}
                                                onKeyUp={handleInputChanges("slug")}
                                                onBlur={() => validator.current.showMessageFor("Slug")}
                                                defaultValue={Inputs.slug} placeholder="Slug" />
                                            {validator.current.message('Slug', Inputs.slug, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Content</label>
                                            <ReactSummernote
                                                name="content"
                                                value={Content}
                                                options={{
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ["style", ["style"]],
                                                        ["font", ["bold", "underline", "clear", 'strikethrough', 'superscript', 'subscript']],
                                                        ["fontname", ["fontname"]],
                                                        ["para", ["ul", "ol", "paragraph"]],
                                                        ["table", ["table"]],
                                                        ["insert", ["link", "picture", "video"]],
                                                        ["view", ["fullscreen", "codeview"]],
                                                        ['height', ['height']]
                                                    ]
                                                }}
                                                onChange={e => { handleEditorChange(e) }}
                                            />
                                            {/* <input type="text" className="form-control " rows="5" value={Inputs.content} ></input> */}
                                            {/* {validator.current.message('Page Content', Content, 'required', { className: 'error' })} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Meta Title</label>
                                            <input type="text" className="form-control" name="meta_title" min="1" maxLength="255"
                                                onChange={handleInputChanges("meta_title")}
                                                onKeyUp={handleInputChanges("meta_title")}
                                                onBlur={() => validator.current.showMessageFor("Meta Title")}
                                                defaultValue={Inputs.meta_title} placeholder="Meta Title" />
                                            {validator.current.message('Meta Title', Inputs.meta_title, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Meta Keywords</label>
                                            <input className="form-control" rows="5" name="meta_keywords"
                                                onChange={handleInputChanges("meta_keywords")}
                                                onKeyUp={handleInputChanges("meta_keywords")}
                                                onBlur={() => validator.current.showMessageFor("Meta Keywords")}
                                                defaultValue={Inputs.meta_keywords} placeholder="Meta Keywords"></input>
                                            {validator.current.message('Meta Keywords', Inputs.meta_keywords, 'required', { className: 'error' })}
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Meta Description</label>
                                            <textarea className="form-control" rows="5" name="meta_description"
                                                onChange={handleInputChanges("meta_description")}
                                                onKeyUp={handleInputChanges("meta_description")}
                                                onBlur={() => validator.current.showMessageFor("Meta Description")}
                                                defaultValue={Inputs.meta_description} placeholder="Meta Description"></textarea>
                                            {validator.current.message('Meta Description', Inputs.meta_description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/contentManagement" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddContentManagement;