import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, FileUploadWithPreview, PasswordStrengthBar, getBase64, Modal, Button, MaterialTable, DateFormat, swal, Common, Permission_keys } from '../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../Spinner';
const AddCustomer = () => {
    var Billing = useState({ Name: '123' });
    var Shipping = useState({});
    var Address = [];
    const urlParams = useParams()
    const history = useHistory()
    const validator = useRef(new SimpleReactValidator())
    let submitbtnRef = useRef();
    const addressValidator = useRef(new SimpleReactValidator())
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [IsShowImageRemoveLink, setIsShowImageRemoveLink] = useState(false)
    const [ProfilePicture, setProfilePicture] = useState('')
    const [CustomerGroupList, setCustomerGroupList] = useState([])
    const [DefaultGroup, setDefaultGroup] = useState(0)
    const [CountryList, setCountryList] = useState([])
    const [StateList, setStateList] = useState([])
    const [CityList, setCityList] = useState([])
    const [States, setStates] = useState([])
    const [Cities, setCities] = useState([])
    const [AddressList, setAddressList] = useState([])
    const [ShowAddressModal, setShowAddressModal] = useState(false)
    const [AddressModalTitle, setAddressModalTitle] = useState(Title.AddCustomerAddress);
    const [OrderList, setOrderList] = useState([])
    const [PageNo, setPageNo] = useState(1)
    const [PageSize, setPageSize] = useState(10)
    const [totalRows, settotalRows] = useState()
    const [totalAddressRows, settotalAddressRows] = useState()
    var profile_picture = '';
    const [Inputs, setInputs] = useState({
        id: 0,
        firstname: "",
        lastname: "",
        password: "",
        confirm_password: "",
        contact_number: "",
        email: "",
        status: 0,
        dob: "",
        gender: "",
        profile_picture: "",
        is_subscribed: 0,
        is_confirmed: 1,
        group_id: 0,
        default_billing: 0,
        default_shipping: 0
    });
    const [AddressInputs, setAddressInputs] = useState({
        id: 0,
        customer_id: urlParams.id != null ? urlParams.id : 0,
        firstname: "",
        lastname: "",
        street: "",
        country_id: "",
        state_id: "",
        city_id: "",
        postcode: "",
        contact_number: "",
        status: 0,
        default_billing_address: 0,
        default_shipping_address: 0,
    });
    const columns = [
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Ordernumber',
            selector: row => row.order_number,
        },
        {
            name: 'Total',
            selector: row => row.final_price,
        },
        {
            name: 'Quantity',
            selector: row => row.qty,
        },
        {
            name: 'Order Status',
            selector: row => row.order_status,
        },
        {
            name: 'Order Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/order/view/order_id/" + row.order_number} className="text-dark" title="View"><i className="fa fa-eye" aria-hidden="true"></i> </Link>
                    {
                        (row.invoice_path != undefined && row.invoice_path != "") ?
                            <a href={row.invoice_path} target="_blank" className="text-dark" title="Invoice"><i className="fa fa-file-pdf-o" aria-hidden="true"></i> </a>
                            : ""
                    }
                </div>
            ,
        },
    ];
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Customers)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddCustomer
        if (urlParams.id != null) {
            document.title = Title.EditCustomer
            GetAddressList()
            GetCustomerDetail()
            GetCountryList()
            GetStateList()
            GetCityList()
            GetOrderList()
        }
        else {
            GetDefaultGroup()
        }

        GetCustomerGroupList()
        initImagePlugin()
    }, [urlParams.id])

    const handlePageChange = (e) => {
        setPageNo(e)
    }

    const handlePerRowsChange = (e) => {
        setPageSize(e)
    }

    const GetOrderList = () => {
        var orderData = {
            "page": PageNo,
            "page_size": PageSize,
            "customer_id": urlParams.id != undefined ? parseInt(urlParams.id) : 0
        }
        HttpService.postApi(API.GetOrderAdminLists, orderData)
            .then(response => {

                var data = response.data.result[1];
                setOrderList(data)
                settotalRows(response.data.result[0][0].total_row)

                setForceUpdate(!isForceUpdate)
            });
    }

    const GetCustomerGroupList = () => {
        HttpService.postApi(API.GetCustomerGroups, null)
            .then(response => {
                setCustomerGroupList(response.data.result[0])
            });
    }

    const GetDefaultGroup = () => {
        HttpService.getApi(API.GetDefaultGroup)
            .then(response => {
                //console.log(response);
                if (response.data.result.length > 0)
                    Inputs['group_id'] = response.data.result[0].id
                setForceUpdate(!isForceUpdate)
            });
    }

    //initialize file upload plugin
    const initImagePlugin = (image = null) => {
        if (image != '' && image != null) {
            profile_picture = new FileUploadWithPreview("profile_picture", {
                text: {
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            profile_picture = new FileUploadWithPreview("profile_picture", {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }

    const GetCustomerDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetCustomerDetail, param)
            .then(response => {
                //console.log(response);
                if (response.data.result) {
                    var data = response.data.result
                    data.map(item => (
                        Object.keys(item).map(function (key, value) {
                            if (key == "dob") {
                                var dob = new Date(item[key]);
                                Inputs[key] = dob.toISOString().split('T')[0];
                            }
                            else if (key != 'password') {
                                Inputs[key] = item[key];
                            }
                        })
                    ))
                    initImagePlugin(data[0].profile_picture)
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    useEffect(() => {
        setForceUpdate(!isForceUpdate)
    }, [Inputs])

    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status" || prop == "is_subscribed")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
                // console.log(Inputs)
            };


    const handleAddressChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;
        if (field == "status" || field == "default_billing_address" || field == "default_shipping_address") {
            AddressInputs[field] = e.target.checked;
        }
        else if (field == "country_id") {
            const stateList = StateList.filter(x => x.country_id == fieldval)
            setStates(stateList)
            AddressInputs[field] = fieldval;
        }
        else if (field == "state_id") {
            const cityList = CityList.filter(x => (x.state_id == fieldval && x.country_id == AddressInputs['country_id']))
            setCities(cityList)
            AddressInputs[field] = fieldval;
        }
        else {
            AddressInputs[field] = fieldval;
        }
        setForceUpdate(!isForceUpdate);
    }

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var param = {
            "id": Inputs.id,
            "firstname": Inputs.firstname,
            "lastname": Inputs.lastname,
            //"password": Inputs.password,
            "contact_number": Inputs.contact_number,
            "email": Inputs.email,
            "profile_picture": ProfilePicture,
            "dob": Inputs.dob,
            "gender": Inputs.gender,
            "is_subscribed": Inputs.is_subscribed == true ? 1 : 0,
            "is_confirmed": Inputs.is_confirmed,
            "group_id": Inputs.group_id,
            "status": Inputs.status
        }
        if (Inputs.password.length > 0)
            param['password'] = Inputs.password
        if (urlParams.id == null) {
            HttpService.postApi(API.AddCustomer, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    toast.success(Messages.Record_Added)
                    setForceUpdate(!isForceUpdate);
                    history.push('/customers')
                });
        }
        else {
            HttpService.postApi(API.UpdateCustomer, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    toast.success(Messages.Record_Updated)
                    setForceUpdate(!isForceUpdate);
                    history.push('/customers')
                });
        }
    }

    const EditAddress = (e, addressId = null) => {
        e.preventDefault()
        clearAddressForm();
        if (addressId == null) {
            setAddressModalTitle(Title.AddCustomerAddress)
            Object.keys(AddressInputs).map(function (key, value) {
                AddressInputs[key] = '';
            })
            setForceUpdate(!isForceUpdate);
        }
        else {
            setAddressModalTitle(Title.EditCustomerAddress)
            var address = AddressList.filter(x => (x.customer_id == urlParams.id && x.id == addressId))
            if (address.length > 0) {
                address.map(item => (
                    Object.keys(item).map(function (key, value) {
                        AddressInputs[key] = item[key];
                    })
                ))
            }
            setForceUpdate(!isForceUpdate)
            var states = StateList.filter(x => x.country_id == AddressInputs['country_id'])
            setStates(states)
            var cityList = CityList.filter(x => (x.state_id == AddressInputs['state_id'] && x.country_id == AddressInputs['country_id']))
            setCities(cityList)
            // if (addressId == Inputs['default_billing']) {
            //     AddressInputs['default_billing_address'] = 1
            // }
            // else {
            //     AddressInputs['default_billing_address'] = 0
            // }

            if (addressId == Inputs['default_shipping']) {
                AddressInputs['default_shipping_address'] = 1
            }
            else {
                AddressInputs['default_shipping_address'] = 0
            }
            setForceUpdate(!isForceUpdate);
        }
        setShowAddressModal(true)
    }

    const GetCountryList = (e) => {
        HttpService.getApi(API.GetCountryList)
            .then(response => {
                setCountryList(response.data.result)
            });
    }

    const GetStateList = (e) => {
        HttpService.getApi(API.GetStateList)
            .then(response => {
                setStateList(response.data.result)
            });
    }

    const GetCityList = (e) => {
        HttpService.getApi(API.GetCityList)
            .then(response => {
                setCityList(response.data.result)
            });
    }

    const GetAddressList = (e) => {
        var param = {
            "customer_id": urlParams.id
        }
        HttpService.postApi(API.GetAddresses, param)
            .then(response => {
                //console.log(response);
                var data = response.data.result
                settotalAddressRows(data.length)
                //console.log(data);
                setAddressList(data)
                data.forEach(element => {
                    if (element.default_shipping == true) {
                        Shipping.Name = element.firstname + ' ' + element.lastname;
                        Shipping.Address = element.street + ', ' + element.city + ', ' + element.postcode;
                        Shipping.Address1 = element.state + ', ' + element.country;
                        Address.push(Shipping);
                        //console.log(Shipping);
                    }
                    if (element.default_billing) {
                        Billing.Name = element.firstname + ' ' + element.lastname;
                        Billing.Address = element.street + ', ' + element.city + ', ' + element.postcode;
                        Billing.Address1 = element.state + ', ' + element.country;
                        Address.push(Billing);
                    }
                });
            });
    }

    const SubmitAddress = (e) => {
        if (!addressValidator.current.allValid()) {
            addressValidator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": AddressInputs.id,
            "customer_id": urlParams.id,
            "firstname": AddressInputs.firstname,
            "lastname": AddressInputs.lastname,
            "street": AddressInputs.street,
            "country": AddressInputs.country_id,
            "state_id": AddressInputs.state_id,
            "city_id": AddressInputs.city_id,
            "postcode": AddressInputs.postcode,
            "contact_number": AddressInputs.contact_number,
            "status": AddressInputs.status,
            "default_billing": AddressInputs.default_billing_address ? true : false,
            "default_shipping": AddressInputs.default_shipping_address ? true : false,
        }
        // console.log(param);return;
        if (AddressInputs['id'] == null || AddressInputs['id'] == 0) {
            HttpService.postApi(API.AddAddress, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        submitbtnRef.current.removeAttribute("disabled");
                        return
                    }
                    toast.success(Messages.Record_Added)
                    setShowAddressModal(false)
                    GetAddressList()
                    setForceUpdate(!isForceUpdate);
                });
        }
        else {
            HttpService.postApi(API.UpdateAddress, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        submitbtnRef.current.removeAttribute("disabled");
                        return
                    }
                    toast.success(Messages.Record_Updated)
                    setShowAddressModal(false)
                    GetAddressList()
                    clearAddressForm()
                    setForceUpdate(!isForceUpdate);
                });
        }
    }

    const clearAddressForm = (e) => {
        setAddressInputs(AddressInputs)
        setForceUpdate(!isForceUpdate);
    }

    const DeleteAddress = async (e, id) => {
        e.preventDefault()
        swal({
            title: Messages.Confirm_Delete,
            text: Messages.Message_Delete,
            buttons: {
                cancel: true,
                confirm: true,
            }
        }).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id
                }
                HttpService.postApi(API.DeleteAddress, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetAddressList()
                    });
            }

        });
    }

    //substract date from given date
    const substractDate = (input, days, months, years) => {
        return new Date(
            input.getFullYear() + years,
            input.getMonth() + months,
            Math.min(
                input.getDate() + days,
                new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
            )
        ).toISOString().slice(0, -14);
    }
    window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
        getBase64(e.detail.cachedFileArray[0], (result) => {
            setProfilePicture(result)
        });
        setIsShowImageRemoveLink(true)
    });
    window.addEventListener("fileUploadWithPreview:clearButtonClicked", function (e) {
        setProfilePicture('')
        setIsShowImageRemoveLink(false)
    });
    return (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddCustomer : Title.EditCustomer} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <ul className="nav nav-tabs d-block" id="customer-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#account-information" role="tab" aria-controls="account-information">Account Information</a>
                                        </li>
                                        {
                                            urlParams.id != null &&
                                            <>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#addresses" role="tab" aria-controls="addresses">Addresses</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#orders" role="tab" aria-controls="orders">Orders</a>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="col-md-9">

                                    <div className="tab-content">
                                        <div className="tab-pane active" id="account-information" role="tabpanel">
                                            <form className="forms-sample" autoComplete="off">
                                                <div className="page-header">
                                                    <h3 className="page-title">Account Information</h3>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>First Name</label>
                                                            <input type="text" className="form-control" name="firstname" min="1" maxLength="255"
                                                                onChange={handleInputChanges("firstname")}
                                                                onKeyUp={handleInputChanges("firstname")}
                                                                onBlur={() => validator.current.showMessageFor("First Name")}
                                                                value={Inputs.firstname} placeholder="First Name" />
                                                            {validator.current.message('First Name', Inputs.firstname, 'required', { className: 'error' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Last Name</label>
                                                            <input type="text" className="form-control" name="lastname" min="1" maxLength="255"
                                                                onChange={handleInputChanges("lastname")}
                                                                onKeyUp={handleInputChanges("lastname")}
                                                                onBlur={() => validator.current.showMessageFor("Last Name")}
                                                                value={Inputs.lastname} placeholder="Last Name" />
                                                            {validator.current.message('Last Name', Inputs.lastname, 'required', { className: 'error' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input type="email" className="form-control" name="email" min="1" maxLength="255"
                                                                onChange={handleInputChanges("email")}
                                                                onKeyUp={handleInputChanges("email")}
                                                                onBlur={() => validator.current.showMessageFor("Email")}
                                                                value={Inputs.email} placeholder="Email" />
                                                            {validator.current.message('Email', Inputs.email, 'required|email', { className: 'error' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Contact Number</label>
                                                            <input type="text" className="form-control" name="contact_number" min="10" maxLength="15"
                                                                onChange={handleInputChanges("contact_number")}
                                                                onKeyUp={handleInputChanges("contact_number")}
                                                                onBlur={() => validator.current.showMessageFor("Contact Number")}
                                                                value={Inputs.contact_number} placeholder="Contact Number" />
                                                            {validator.current.message('Contact Number', Inputs.contact_number, 'required|phone', { className: 'error' })}
                                                        </div>
                                                    </div>
                                                    {
                                                        urlParams.id == null ?
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Password</label>
                                                                    <input type="password" className="form-control" name="password" minLength="6" maxLength="20" onChange={handleInputChanges("password")} placeholder="Password" autoComplete="new-password" />
                                                                    {
                                                                        // urlParams.id != null ?
                                                                        // validator.current.message('Password', Inputs.password, 'max:20', { className: 'error' })
                                                                        // :
                                                                        validator.current.message('Password', Inputs.password, 'required', { className: 'error' })
                                                                    }
                                                                    <PasswordStrengthBar password={urlParams.id == null ? Inputs.password : ''} />
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }

                                                    {
                                                        urlParams.id == null ?
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Confirm Password</label>
                                                                    <input type="password" className="form-control" name="confirm_password" onChange={handleInputChanges("confirm_password")} placeholder="Confirm Password" />
                                                                    {/* {
                                                                        (urlParams.id != null && Inputs.password.length < 1) ?
                                                                            validator.current.message('Confirm Password', Inputs.confirm_password, 'in:${Inputs.password}', {
                                                                                className: 'error',
                                                                                messages: {
                                                                                    in:
                                                                                        "Passwords need to match!",
                                                                                }
                                                                            })
                                                                            :
                                                                            validator.current.message('Confirm Password', Inputs.confirm_password, 'required|in:${Inputs.password}', {
                                                                                className: 'error',
                                                                                messages: {
                                                                                    in:
                                                                                        "Passwords need to match!",
                                                                                }
                                                                            })
                                                                    } */}

                                                                </div>
                                                            </div> : ""
                                                    }
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Birth Date</label>
                                                            <input type="date" className="form-control" name="dob"
                                                                onChange={handleInputChanges("dob")}
                                                                onKeyUp={handleInputChanges("dob")}
                                                                onBlur={() => validator.current.showMessageFor("Birth Date")}
                                                                value={Inputs.dob} placeholder="Birth Date" max={substractDate(new Date(), 0, 0, -18)} />
                                                            {validator.current.message('Birth Date', Inputs.dob, 'required', { className: 'error' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Gender</label>
                                                            <select className="form-control" name="gender" onChange={handleInputChanges("gender")} value={Inputs.gender}>
                                                                <option value="">Select</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            {validator.current.message('Gender', Inputs.gender, 'required', { className: 'error' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Customer Group</label>
                                                            <select className="form-control" name="group_id" onChange={handleInputChanges("group_id")} value={Inputs.group_id} >
                                                                {
                                                                    CustomerGroupList.map(item => (
                                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {validator.current.message('Customer Group', Inputs.gender, 'required', { className: 'error' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group row">
                                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                                            <div className="col-sm-12">
                                                                <div className="custom-control custom-switch mt-1">
                                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-12">
                                                                <div className="form-check">
                                                                    <label className="form-check-label">
                                                                        <input type="checkbox" className="form-check-input" name="is_subscribed" checked={Inputs.is_subscribed} onChange={handleInputChanges('is_subscribed')} /> Sign Up for Newsletter <i className="input-helper"></i>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="custom-file-container" data-upload-id="profile_picture">
                                                            <label>Profile Picture
                                                                <a href={void (0)} className="custom-file-container__image-clear" title="Remove Image" style={IsShowImageRemoveLink ? {} : { display: 'none' }}>&times;</a>
                                                            </label>

                                                            <label className="custom-file-container__custom-file">
                                                                <input type="file" className="custom-file-container__custom-file__custom-file-input" accept="image/x-png,image/gif,image/jpeg" aria-label="Choose Image" />
                                                                <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                                                <span className="custom-file-container__custom-file__custom-file-control"></span>
                                                            </label>
                                                            <div className="custom-file-container__image-preview"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                                        <Link to="/customers" className="btn btn-light">Cancel</Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        {
                                            urlParams.id != null &&
                                            <>
                                                <div className="tab-pane" id="addresses" role="tabpanel">
                                                    <div className="content-wrapper pb-0">
                                                        <div className="page-header">
                                                            <h3 className="page-title"> {Title.Addresses} </h3>
                                                            <div className="btn-group">
                                                                <button type="button" onClick={EditAddress} className="btn btn-dark btn-fw rounded mr-2"> Add New Address </button>
                                                            </div>
                                                        </div>

                                                        <div className="row g-4 Test">
                                                            {
                                                                AddressList.map((element, i) => {
                                                                    if (element.default_shipping_address) {
                                                                        return [<div className="col-md-5">
                                                                            <div className="my-4 address-details">
                                                                                <h4>Shipping Address</h4>
                                                                                <p><strong> {element.firstname + ' ' + element.lastname}</strong></p>
                                                                                <p>{element.street + ', ' + element.city + ', ' + element.postcode}</p>
                                                                                <p>{element.state + ', ' + element.country}</p>
                                                                                <p><i className='fa fa-phone'></i>&nbsp;{element.contact_number}</p>
                                                                                {/* <p>test@mail.com</p> */}
                                                                            </div>
                                                                        </div>,
                                                                        ]

                                                                    }
                                                                })
                                                            }
                                                            {
                                                                AddressList.map((element, i) => {
                                                                    if (element.default_billing_address) {
                                                                        return [<div className="col-md-5">
                                                                            <div className="my-4 address-details">
                                                                                <h4>Billing Address</h4>
                                                                                <p><strong> {element.firstname + ' ' + element.lastname}</strong></p>
                                                                                <p>{element.street + ', ' + element.city + ', ' + element.postcode}</p>
                                                                                <p>{element.state + ', ' + element.country}</p>
                                                                                <p><i className='fa fa-phone'></i>&nbsp;{element.contact_number}</p>
                                                                                {/* <p>test@mail.com</p> */}
                                                                            </div>
                                                                        </div>,
                                                                        ]

                                                                    }
                                                                })
                                                            }
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-12 grid-margin stretch-card">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="table-responsive">
                                                                            <DataTable
                                                                                title=""
                                                                                columns={[
                                                                                    { name: 'First Name', selector: row => row.firstname },
                                                                                    { name: 'Last Name', selector: row => row.lastname },
                                                                                    { name: 'Street', selector: row => row.street },
                                                                                    { name: 'City', selector: row => row.city },
                                                                                    { name: 'Country', selector: row => row.country },
                                                                                    { name: 'State', selector: row => row.state },
                                                                                    { name: 'Post Code', selector: row => row.postcode },
                                                                                    {
                                                                                        title: 'Status', field: 'status',
                                                                                        selector: row => row.status == 1 ? <label>Active</label> : <label>Inactive</label>
                                                                                    },
                                                                                    {
                                                                                        name: 'Action',
                                                                                        selector: (row) =>
                                                                                            <div className="action-btn">
                                                                                                <Link to="" onClick={e => EditAddress(e, row.id)} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                                                                                                <Link onClick={e => DeleteAddress(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                                                                                            </div>,
                                                                                    }
                                                                                ]}
                                                                                data={AddressList}
                                                                                pagination
                                                                                paginationServer
                                                                                paginationTotalRows={totalAddressRows}
                                                                                onChangeRowsPerPage={handlePerRowsChange}
                                                                                onChangePage={handlePageChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="orders" role="tabpanel">
                                                    <div className='content-wrapper'>
                                                        <div className="page-header">
                                                            <h3 className="page-title">Orders</h3>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 grid-margin stretch-card">
                                                                <div className="card">
                                                                    <div className="card-body">

                                                                        <div className="table-responsive">
                                                                            <DataTable
                                                                                columns={columns}
                                                                                data={OrderList}
                                                                                pagination
                                                                                paginationServer
                                                                                paginationTotalRows={totalRows}
                                                                                onChangeRowsPerPage={handlePerRowsChange}
                                                                                onChangePage={handlePageChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                urlParams.id &&
                <Modal
                    size="md"
                    show={ShowAddressModal}
                    onHide={() => setShowAddressModal(false)}
                    aria-labelledby="edit-address-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="edit-address-modal">
                            {AddressModalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" name="firstname" onChange={handleAddressChanges} value={AddressInputs.firstname} placeholder="First Name" />
                                    {addressValidator.current.message('First Name', AddressInputs.firstname, 'required|max:255', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" name="lastname" onChange={handleAddressChanges} value={AddressInputs.lastname} placeholder="Last Name" />
                                    {addressValidator.current.message('Last Name', AddressInputs.lastname, 'required|max:255', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Street</label>
                                    <input type="text" className="form-control" name="street" onChange={handleAddressChanges} value={AddressInputs.street} placeholder="Street" />
                                    {addressValidator.current.message('Street', AddressInputs.street, 'required', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select className="form-control" name="country_id" onChange={handleAddressChanges} value={AddressInputs.country_id}>
                                        <option value="">Select</option>
                                        {
                                            CountryList.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                    {addressValidator.current.message('Country', AddressInputs.country_id, 'required', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">

                                    <label>State</label>
                                    <select className="form-control" name="state_id" onChange={handleAddressChanges} value={AddressInputs.state_id}>
                                        <option value="">Select</option>
                                        {
                                            States.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                    {addressValidator.current.message('State', AddressInputs.state_id, 'required', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>City</label>
                                    <select className="form-control" name="city_id" onChange={handleAddressChanges} value={AddressInputs.city_id}>
                                        <option value="">Select</option>
                                        {
                                            Cities.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                    {addressValidator.current.message('City', AddressInputs.city_id, 'required', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Post Code</label>
                                    <input type="text" className="form-control" name="postcode" onChange={handleAddressChanges} value={AddressInputs.postcode} placeholder="Post Code" />
                                    {addressValidator.current.message('Post Code', AddressInputs.postcode, 'required|max:10|numeric', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                    <div className="col-sm-12">
                                        <div className="custom-control custom-switch mt-1">
                                            <input type="checkbox" className="custom-control-input" name="status" onChange={handleAddressChanges} id="AddressStatus" checked={AddressInputs.status} />
                                            <label className="custom-control-label" htmlFor="AddressStatus"> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Contact Number</label>
                                    <input type="text" className="form-control" name="contact_number" onChange={handleAddressChanges} value={AddressInputs.contact_number} placeholder="Contact Number" />
                                    {addressValidator.current.message('Contact Number', AddressInputs.contact_number, 'required|max:10|numeric', { className: 'error' })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label htmlFor="exampleInputUsername2" className="col-sm-12">Default Billing Address</label>
                                    <div className="col-sm-12">
                                        <div className="custom-control custom-switch mt-1">
                                            <input type="checkbox" className="custom-control-input" name="default_billing_address" onChange={handleAddressChanges} id="DefaultBillingAddress" checked={AddressInputs.default_billing_address} />
                                            <label className="custom-control-label" htmlFor="DefaultBillingAddress"> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label htmlFor="exampleInputUsername2" className="col-sm-12">Default Shipping Address</label>
                                    <div className="col-sm-12">
                                        <div className="custom-control custom-switch mt-1">
                                            <input type="checkbox" className="custom-control-input" name="default_shipping_address" onChange={handleAddressChanges} id="DefaultShippingAddress" checked={AddressInputs.default_shipping_address} />
                                            <label className="custom-control-label" htmlFor="DefaultShippingAddress"> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" ref={submitbtnRef} onClick={SubmitAddress}>
                            Submit
                        </Button>
                        <Button variant="secondary" onClick={() => setShowAddressModal(false)}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            }
        </div>
    )
};
export default AddCustomer;