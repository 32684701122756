import React, { ChangeEvent, useState, useRef, useEffect, useReducer } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import { Input } from '@material-ui/core';
import Spinner from '../../Spinner';
const AddMetalQuality = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false);
    const validator = useRef(new SimpleReactValidator())
    const [MetalTypeList, setMetalTypeList] = useState([]);
    const [MetalAttributeOpts, setMetalAttributeOpts] = useState();

    let submitbtnRef = useRef();
    const [Inputs, setInputs] = useState({
         id: 0,
        metal_quality_option_id: "",
        metal_type_id: "",
        gold_rate: "",
        fineness: "0",
        weight: "1",
        rate: "0",
        labour_charges: "",
        status: 0
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddMetalQuality
        GetMetalTypeList()
        GetMetalAttributeList();
    }, [urlParams.id])

    const GetMetalTypeList = () => {
        HttpService.postApi(API.GetMetal, null)
            .then(response => {
                //console.log(response);
                setMetalTypeList(response.data.result[0])
                if (urlParams.id != null) {
                    document.title = Title.EditMetalQuality
                    GetMetalQualityDetail()

                }
            });
    }

    const GetMetalAttributeList = () => {
        var param = {
            'attribute_code': 'Metal_quality'
        }
        HttpService.postApi(API.GetProductAttributeDetail, param)
            .then(response => {
                setMetalAttributeOpts(JSON.parse(response.data.result[0][0].attribute_options))
                setForceUpdate(!isForceUpdate)
            });
    }

    const GetMetalQualityDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetMetalQuality, param)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const handleInputChanges = (prop) => (event: ChangeEvent<HTMLInputElement>) => {
        if (prop == "status") {
            setInputs({ ...Inputs, [prop]: event.target.checked });
        }
        else if (prop == "metal_type_id") {
            for (var i = 0; i < MetalTypeList.length; i++) {
                if (MetalTypeList[i]['id'] == event.target.value) {
                    setInputs({ ...Inputs, ['metal_type_id']: event.target.value, ['labour_charges']: MetalTypeList[i]['metal_labour_charge'], ['gold_rate']: MetalTypeList[i]['metal_price'] });
                }
            }
        }
        else if (prop == "fineness") {
            var rate = (Inputs['gold_rate'] * event.target.value / 100);
            setInputs({ ...Inputs, ['fineness']: event.target.value, ['rate']: rate });
        }
        else {
            setInputs({ ...Inputs, [prop]: event.target.value });
        }
        setForceUpdate(!isForceUpdate)
    };

    const SubmitForm = (e) => {
        e.preventDefault();
        debugger;
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "metal_quality_option_id": Inputs.metal_quality_option_id,
            "metal_type_id": Inputs.metal_type_id,
            "gold_rate": Inputs.gold_rate,
            "fineness": Inputs.fineness,
            "weight": Inputs.weight,
            "rate": Inputs.rate,
            "labour_charges": Inputs.labour_charges,
            "status": Inputs.status ?? 0
        }

        HttpService.postApi(API.SetMetalQuality, param)
            .then(response => {
                console.log(response);
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/system/metalquality')
            });
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddMetalQuality : Title.EditMetalQuality} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                            }} className="forms-sample">
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Metal Quality</label>
                                            <input type="text" className="form-control"
                                                name="metal_quality" min="1" maxLength="100"
                                                value={Inputs.metal_quality}
                                                onChange={handleInputChanges("metal_quality")}
                                                onKeyUp={handleInputChanges("metal_quality")}
                                                onBlur={() => validator.current.showMessageFor("Metal Quality")}
                                                placeholder="Metal Quality" />
                                            {validator.current.message('Metal Quality', Inputs.metal_quality, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Metal Quality</label>
                                            <select className="form-control" id="" name="metal_quality_option_id"
                                                onChange={handleInputChanges("metal_quality_option_id")} value={Inputs.metal_quality_option_id} >
                                                <option>Select Metal</option>
                                                {
                                                    MetalAttributeOpts != null ?
                                                        Object.keys(MetalAttributeOpts).map((item, index) => (
                                                            <option key={item} value={item}>{MetalAttributeOpts[item]}</option>
                                                        ))
                                                        : ""
                                                }
                                            </select>
                                            {validator.current.message('Metal Quality', Inputs.metal_quality_option_id, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Metal</label>
                                            <select className="form-control" id="" name="metal_type_id"
                                                onChange={handleInputChanges("metal_type_id")} value={Inputs.metal_type_id}>
                                                <option>Select Metal</option>
                                                {
                                                    MetalTypeList.map(item => (
                                                        <option key={item.id} value={item.id}>{item.metal_type}</option>
                                                    ))
                                                }
                                            </select>
                                            {validator.current.message('Metal', Inputs.metal_type_id, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Gold Price(£)</label>
                                            <input type="text" className="form-control" id="gold_rate" name="gold_rate"
                                                min="1" maxLength="7"
                                                readOnly="readonly"
                                                onChange={handleInputChanges("gold_rate")}
                                                onKeyUp={handleInputChanges("gold_rate")}
                                                onBlur={() => validator.current.showMessageFor("Gold Rate")}
                                                value={Inputs.gold_rate} placeholder="Gold Price" />

                                            {validator.current.message('Gold Rate', Inputs.gold_rate,
                                                'required|numeric', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fineness(%)</label>
                                            <input type="text" className="form-control" name="fineness" min="1" maxLength="7"
                                                onChange={handleInputChanges("fineness")}
                                                onKeyUp={handleInputChanges("fineness")}
                                                onBlur={() => validator.current.showMessageFor("Fineness")} value={Inputs.fineness} placeholder="Fineness" min="0" />
                                            {validator.current.message('Fineness', Inputs.fineness, 'required|numeric', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Weight (kt)</label>
                                            <input type="text" className="form-control" name="weight" min="1" maxLength="10"
                                                readonly="readonly"
                                                onChange={handleInputChanges("weight")}
                                                onKeyUp={handleInputChanges("weight")}
                                                onBlur={() => validator.current.showMessageFor("Weight")} value={Inputs.weight} placeholder="Weight" min="0" />
                                            {validator.current.message('Weight', Inputs.weight, 'required|numeric', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Rate(£)</label>
                                            <input type="text" readonly="readonly" className="form-control" name="rate" min="1" maxLength="7"
                                                readonly="readonly"
                                                onChange={handleInputChanges("rate")}
                                                onKeyUp={handleInputChanges("rate")}
                                                onBlur={() => validator.current.showMessageFor("Rate")} value={Inputs.rate} placeholder="Rate" min="0" />
                                            {validator.current.message('Rate', Inputs.rate, 'required|numeric', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Labour Charge(£)</label>
                                            <input type="text" className="form-control" name="labour_charges" min="1" maxLength="7"
                                                onChange={handleInputChanges("labour_charges")}
                                                onKeyUp={handleInputChanges("labour_charges")}
                                                onBlur={() => validator.current.showMessageFor("Labour Charge")} value={Inputs.labour_charges} placeholder="Labour Charge" min="0" />
                                            {validator.current.message('Labour Charge', Inputs.labour_charges, 'required|numeric', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status"
                                                        onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" ref={submitbtnRef} onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/system/metalquality" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMetalQuality;