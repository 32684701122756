import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';
function AddSettingOption(props) {
    const validator = useRef(new SimpleReactValidator())
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Inputs, setInputs] = useState({
        id: 0,
        hj_title: "",
        hj_key: "",
        hj_value: ""
    });
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddSettingOption
        if (urlParams.id != null) {
            document.title = Title.EditSettingOption
            GetSettingOption()
        }
    }, [urlParams.id])
    const GetSettingOption = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetSettingOptionDetail, param)
            .then(response => {
                if (response.data.result) {
                    setInputs(response.data.result[0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
            };
    const SubmitForm = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");
        var param = {
            "id": Inputs.id,
            "hj_title": Inputs.hj_title,
            "hj_key": Inputs.hj_key,
            "hj_value": Inputs.hj_value
        }
        if (urlParams.id == null) {
            HttpService.postApi(API.AddSettingOption, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        submitbtnRef.current.removeAttribute("disabled");
                        return
                    }
                    toast.success(Messages.Record_Added)
                    setForceUpdate(!isForceUpdate);
                    history.push('/system/settings')
                });
        }
        else {
            HttpService.postApi(API.UpdateSettingOption, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        submitbtnRef.current.removeAttribute("disabled");
                        return
                    }
                    toast.success(Messages.Record_Updated)
                    setForceUpdate(!isForceUpdate);
                    history.push('/system/settings')
                });
        }

    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddSettingOption : Title.EditSettingOption} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" name="hj_title"
                                                onChange={handleInputChanges("hj_title")}
                                                onKeyUp={handleInputChanges("hj_title")}
                                                onBlur={() => validator.current.showMessageFor("hj_title")}
                                                value={Inputs.hj_title} placeholder="Title" />
                                            {validator.current.message('Title', Inputs.hj_title, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Key</label>
                                            <input type="text" className="form-control" name="hj_key"
                                                onChange={handleInputChanges("hj_key")}
                                                onKeyUp={handleInputChanges("hj_key")}
                                                onBlur={() => validator.current.showMessageFor("hj_key")}
                                                value={Inputs.hj_key} placeholder="Key" />
                                            {validator.current.message('Key', Inputs.hj_key, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Value</label>
                                            <input type="text" className="form-control" name="hj_value"
                                                onChange={handleInputChanges("hj_value")}
                                                onKeyUp={handleInputChanges("hj_value")}
                                                onBlur={() => validator.current.showMessageFor("hj_value")}
                                                value={Inputs.hj_value} placeholder="Value" />
                                            {validator.current.message('Value', Inputs.hj_value, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/system/settings" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSettingOption;