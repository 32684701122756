import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, DropdownTreeSelect, FileUploadWithPreview, getBase64, Common, Permission_keys } from '../../config/react'
import Moment from 'moment';
import Spinner from '../Spinner';
const AddCoupons = () => {
    const urlParams = useParams()
    const submitBtnRef = useRef();
    const history = useHistory()
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext)
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [Categories, setCategories] = useState([])
    const [ExcludeCategories, setExcludeCategories] = useState([])
    const [SelectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [SelectedExcludeCategoryIds, setSelectedExcludeCategoryIds] = useState([]);
    const [Products, setProducts] = useState([])
    const [ExcludeProducts, setExcludeProducts] = useState([])
    const [SelectedProductsIds, setSelectedProductsIds] = useState([]);
    const [SelectedExcludeProductsIds, setSelectedExcludeProductsIds] = useState([]);
    const [Customers, setCustomers] = useState([])
    const [SelectedCustomerIds, setSelectedCustomerIds] = useState([]);

    const [Inputs, setInputs] = useState({
        id: 0,
        coupon_code: "",
        description: "",
        discount_type: "fixed_cart_discount",
        amount_type: "percentage",
        coupon_amount: 0,
        coupon_percentage: 0,
        coupon_expiry: "",
        minimum_spend: "",
        maximum_spend: "",
        usage_coupon: 0,
        usage_limit_item: 0,
        usage_limit_user: 0,
        status: 0
    });


    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Coupons)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddCoupons
        if (urlParams.id != null) {
            document.title = Title.EditCoupons
            GetCouponDetail()
        }
        else {
            GetCategories();
            GetProductLists();
            GetCustomerLists();
        }
    }, [urlParams.id])

    const GetCouponDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetCouponDetail, param).then(response => {
            if (response.data.result) {
                var data = response.data.result[0][0];
                console.log(data);
                var responseData = {
                    id: data.id,
                    coupon_code: data.coupon_code,
                    description: data.description,
                    discount_type: data.discount_type,
                    coupon_amount: data.coupon_amount,
                    coupon_percentage: data.coupon_percentage,
                    amount_type: data.coupon_amount != 0 ? 'amount' : 'percentage',
                    coupon_expiry: data.expire_date,
                    minimum_spend: data.minimum_spend,
                    maximum_spend: data.maximum_spend,
                    usage_coupon: data.used_per_coupon,
                    usage_limit_item: data.limit_cart_products,
                    usage_limit_user: data.used_per_user,
                    status: data.status
                }
                if (responseData.coupon_expiry != "") {
                    var date = Moment(responseData.coupon_expiry).format("YYYY-MM-DD");
                    responseData.coupon_expiry = date;
                }
                setInputs(responseData)
                setForceUpdate(!isForceUpdate)
                setSelectedCategoryIds(JSON.parse(data.category_ids));
                setSelectedExcludeCategoryIds(JSON.parse(data.exclude_categories));
                setSelectedProductsIds(JSON.parse(data.product_ids));
                setSelectedExcludeProductsIds(JSON.parse(data.exclude_products));
                setSelectedCustomerIds(JSON.parse(data.user_ids));
                GetCategories(JSON.parse(data.category_ids), JSON.parse(data.exclude_categories));
                GetProductLists(JSON.parse(data.product_ids), JSON.parse(data.exclude_products));
                GetCustomerLists(JSON.parse(data.user_ids));
                setForceUpdate(!isForceUpdate)
            }
        });
    }

    const GetCustomerLists = (selectedCustomers = null) => {
        var customerData = {
            "customergroup": [],
            "name": '',
            "email": ''
        }
        HttpService.postApi(API.GetCustomerList, customerData).then(response => {
            if (response.data.result) {
                var customers = [];
                var data = response.data.result;
                console.log(data);
                data.map((item) => {
                    if (item.firstname != null) {
                        customers.push({
                            label: item.firstname + " " + item.lastname + " (" + item.email + ")",
                            value: item.id,
                            children: null,
                            checked: false
                        })
                    }
                    else {
                        customers.push({
                            label: item.email,
                            value: item.id,
                            children: null,
                            checked: false
                        })
                    }
                });
                if (selectedCustomers != null) {
                    for (var i = 0; i < customers.length; i++) {
                        selectedCustomers.map((cate) => {
                            if (cate == customers[i].value) {
                                customers[i].checked = true;
                            }
                        });
                    }
                }
                setCustomers(customers);
            }
        });
    }

    const GetProductLists = (selectedProducts = null, selectedExcludeProducts = null) => {
        HttpService.postApi(API.GetProductsList, null).then(response => {
            if (response.data.result) {
                var products = [];
                var ExcludeProducts = [];
                var data = response.data.result;
                data.map((item) => {
                    products.push({
                        label: item.name + ' ' + item.metal_quality_value,
                        value: item.id,
                        children: null,
                        checked: false
                    })
                    ExcludeProducts.push({
                        label: item.name + ' ' + item.metal_quality_value,
                        value: item.id,
                        children: null,
                        checked: false
                    })
                });
                if (selectedProducts != null) {
                    for (var i = 0; i < products.length; i++) {
                        selectedProducts.map((cate) => {
                            if (cate == products[i].value) {
                                products[i].checked = true;
                            }
                        });
                    }
                }
                if (selectedExcludeProducts != null) {
                    for (var i = 0; i < ExcludeProducts.length; i++) {
                        selectedExcludeProducts.map((cate) => {
                            if (cate == ExcludeProducts[i].value) {
                                ExcludeProducts[i].checked = true;
                            }
                        });
                    }
                }
                setProducts(products);
                setExcludeProducts(ExcludeProducts);
            }
        });
    }

    const GetCategories = (selectedCategory = null, selectedExcludeCategory = null) => {
        HttpService.postApi(API.GetCategory, null).then(response => {
            if (response.data.result) {
                //filter parent category
                var parentCategories = response.data.result[0].filter(x => x.parent_id == 1 && x.is_diamond_category == 0)
                var categories = [];
                var excludeCategories = [];
                parentCategories.map((item) => {
                    //filter sub categories by parent
                    var filteredSubcategories = response.data.result[0].filter(x => x.parent_id == item.id && x.is_diamond_category == 0)
                    var subcategories = [];
                    filteredSubcategories.map((subitem) => {
                        //filter sub child categories by parent
                        var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id && x.is_diamond_category == 0)
                        var subchildcategories = [];
                        filteredSubChildCategories.map((childitem) => {
                            subchildcategories.push({
                                label: childitem.name,
                                value: childitem.id,
                                checked: false,
                                children: []
                            })
                        });
                        subcategories.push({
                            label: subitem.name,
                            value: subitem.id,
                            checked: false,
                            children: subchildcategories
                        })
                    });
                    var subcategories1 = [];
                    filteredSubcategories.map((subitem) => {
                        //filter sub child categories by parent
                        var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id && x.is_diamond_category == 0)
                        var subchildcategories1 = [];
                        filteredSubChildCategories.map((childitem) => {
                            subchildcategories1.push({
                                label: childitem.name,
                                value: childitem.id,
                                checked: false,
                                children: []
                            })
                        });
                        subcategories1.push({
                            label: subitem.name,
                            value: subitem.id,
                            checked: false,
                            children: subchildcategories1
                        })
                    });
                    categories.push({
                        label: item.name,
                        value: item.id,
                        children: subcategories,
                        checked: false
                    });
                    excludeCategories.push({
                        label: item.name,
                        value: item.id,
                        children: subcategories1,
                        checked: false
                    });
                });
                if (selectedCategory != null) {
                    for (var i = 0; i < categories.length; i++) {
                        selectedCategory.map((cate) => {
                            if (cate == categories[i].value) {
                                categories[i].checked = true;
                            }
                        });
                        var children = categories[i].children;
                        if (children.length > 0) {
                            for (var j = 0; j < children.length; j++) {
                                selectedCategory.map((cate1) => {
                                    if (cate1 == children[j].value) {
                                        children[j].checked = true;
                                    }
                                });
                                var children1 = children[j].children;
                                if (children1.length > 0) {
                                    for (var k = 0; k < children1.length; k++) {
                                        selectedCategory.map((cate2) => {
                                            if (cate2 == children1[k].value) {
                                                children1[k].checked = true;
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                setCategories(categories)
                if (selectedExcludeCategory != null) {
                    for (var i = 0; i < categories.length; i++) {
                        selectedExcludeCategory.map((cate) => {
                            if (cate == excludeCategories[i].value) {
                                excludeCategories[i].checked = true;
                            }
                        });
                        var children = excludeCategories[i].children;
                        if (children.length > 0) {
                            for (var j = 0; j < children.length; j++) {
                                selectedExcludeCategory.map((cate1) => {
                                    if (cate1 == children[j].value) {
                                        children[j].checked = true;
                                    }
                                });
                                var children1 = children[j].children;
                                if (children1.length > 0) {
                                    for (var k = 0; k < children1.length; k++) {
                                        selectedExcludeCategory.map((cate2) => {
                                            if (cate2 == children1[k].value) {
                                                children1[k].checked = true;
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                setExcludeCategories(excludeCategories)
            }
        });

    }

    const onChangeCategory = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedCategoryIds(array);
        setForceUpdate(!isForceUpdate);
        console.log(array);
        Categories.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                Categories[index].checked = true;
            }
            else {
                Categories[index].checked = false;
            }
            item.children.map((subitem, subindex) => {
                if (array.indexOf(subitem.value) >= 0) {
                    Categories[index].children[subindex].checked = true;
                }
                else {
                    Categories[index].children[subindex].checked = false;
                }
                subitem.children.map((subitem1, subindex1) => {
                    if (array.indexOf(subitem1.value) >= 0) {
                        Categories[index].children[subindex].children[subindex1].checked = true;
                    }
                    else {
                        Categories[index].children[subindex].children[subindex1].checked = false;
                    }
                })
            })
        });
    };

    const onChangeExcludeCategory = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedExcludeCategoryIds(array);
        setForceUpdate(!isForceUpdate);
        ExcludeCategories.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                ExcludeCategories[index].checked = true;
            }
            else {
                ExcludeCategories[index].checked = false;
            }
            item.children.map((subitem, subindex) => {
                if (array.indexOf(subitem.value) >= 0) {
                    ExcludeCategories[index].children[subindex].checked = true;
                }
                else {
                    ExcludeCategories[index].children[subindex].checked = false;
                }
                subitem.children.map((subitem1, subindex1) => {
                    if (array.indexOf(subitem1.value) >= 0) {
                        ExcludeCategories[index].children[subindex].children[subindex1].checked = true;
                    }
                    else {
                        ExcludeCategories[index].children[subindex].children[subindex1].checked = false;
                    }
                })
            })
        });
    };

    const onChangeProduct = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedProductsIds(array);
        setForceUpdate(!isForceUpdate);
        Products.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                Products[index].checked = true;
            }
            else {
                Products[index].checked = false;
            }
        });
    };

    const onChangeExcludeProduct = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedExcludeProductsIds(array);
        setForceUpdate(!isForceUpdate);
        ExcludeProducts.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                ExcludeProducts[index].checked = true;
            }
            else {
                ExcludeProducts[index].checked = false;
            }
        });
    };

    const onChangeCustomer = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedCustomerIds(array);
        setForceUpdate(!isForceUpdate);
        Customers.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                Customers[index].checked = true;
            }
            else {
                Customers[index].checked = false;
            }
        });
    };

    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status")
            Inputs[field] = e.target.checked;
        else
            Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var param = {
            "id": Inputs.id,
            "coupon_code": Inputs.coupon_code,
            "description": Inputs.description,
            "discount_type": Inputs.discount_type,
            "coupon_amount": parseInt(Inputs.coupon_amount),
            "coupon_percentage": parseInt(Inputs.coupon_percentage),
            "coupon_expiry": Inputs.coupon_expiry,
            "minimum_spend": parseInt(Inputs.minimum_spend),
            "maximum_spend": parseInt(Inputs.maximum_spend),
            "usage_coupon": parseInt(Inputs.usage_coupon),
            "usage_limit_item": parseInt(Inputs.usage_limit_item),
            "usage_limit_user": parseInt(Inputs.usage_limit_user),
            "category_ids": (SelectedProductsIds.length > 0 || SelectedExcludeProductsIds.length > 0) ? '[]' : JSON.stringify(SelectedCategoryIds),
            "exclude_category_ids": (SelectedProductsIds.length > 0 || SelectedExcludeProductsIds.length > 0) ? '[]' : JSON.stringify(SelectedExcludeCategoryIds),
            "product_ids": (SelectedCategoryIds.length > 0 || SelectedExcludeCategoryIds.length > 0) ? '[]' : JSON.stringify(SelectedProductsIds),
            'exclude_product_ids': (SelectedCategoryIds.length > 0 || SelectedExcludeCategoryIds.length > 0) ? '[]' : JSON.stringify(SelectedExcludeProductsIds),
            'customer_ids': JSON.stringify(SelectedCustomerIds),
            "status": Inputs.status ? 1 : 0,
            "Userid": user.id
        }
        if (Inputs.amount_type == "percentage") {
            param.coupon_amount = 0;
        }
        else {
            param.coupon_percentage = 0;
        }
        if (submitBtnRef.current)
            submitBtnRef.current.setAttribute("disabled", "disabled");
        if (urlParams.id == null) {
            HttpService.postApi(API.AddCoupon, param).then(response => {
                if (submitBtnRef.current)
                    submitBtnRef.current.removeAttribute("disabled");
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                toast.success(Messages.Record_Added)
                setForceUpdate(!isForceUpdate);
                history.push('/coupons')
            });
        }
        else {
            HttpService.postApi(API.UpdateCoupon, param).then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/coupons')
            });
        }
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddCoupons : Title.EditCoupons} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Coupon Code</label>
                                            <input type="text" className="form-control" name="coupon_code" onChange={handleInputChanges} value={Inputs.coupon_code} placeholder="Coupon Code" />
                                            {validator.current.message('Coupon Code', Inputs.coupon_code, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control" name="description" onChange={handleInputChanges} value={Inputs.description} placeholder="Description" rows="2"></textarea>
                                            {validator.current.message('Description', Inputs.description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Discount Type</label>
                                            <select className="form-control" name="discount_type" onChange={handleInputChanges} value={Inputs.discount_type}>
                                                <option value="fixed_cart_discount">Fixed Cart Discount</option>
                                                <option value="fixed_product_discount">Fixed Product Discount</option>
                                            </select>
                                            {validator.current.message('Discount Type', Inputs.discount_type, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Coupon Amount..</label>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <select className="form-control" name="amount_type" value={Inputs.amount_type} onChange={handleInputChanges}>
                                                        <option value="percentage">%</option>
                                                        <option value="amount">Amount</option>
                                                    </select>
                                                </div>
                                                {
                                                    Inputs.amount_type == "percentage" ?
                                                        <div className='col-md-9'>
                                                            <input type="number" className="form-control" name="coupon_percentage" onChange={handleInputChanges} value={Inputs.coupon_percentage} placeholder="Coupon Percentage" />
                                                            {validator.current.message('Coupon Percentage', Inputs.coupon_percentage, 'required|numeric|max:99,num|min:0,num', { className: 'error' })}
                                                        </div>
                                                        : ""
                                                }
                                                {
                                                    Inputs.amount_type == "amount" ?
                                                        <div className='col-md-9'>
                                                            <input type="number" className="form-control" name="coupon_amount" onChange={handleInputChanges} value={Inputs.coupon_amount} placeholder="Coupon Amount" min="0" />
                                                            {validator.current.message('Coupon Amount', Inputs.coupon_amount, 'required', { className: 'error' })}
                                                        </div>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Coupon Expiry Date</label>
                                            <input type="date" className="form-control" name="coupon_expiry" onChange={handleInputChanges} value={Inputs.coupon_expiry} placeholder="Coupon Expiry Date" />
                                            {validator.current.message('Coupon Expiry Date', Inputs.coupon_expiry, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Minimum Spend</label>
                                            <input type="number" min="0" step="1" className="form-control" name="minimum_spend" onChange={handleInputChanges} value={Inputs.minimum_spend} placeholder="Minimum Spend" />
                                            {validator.current.message('Minimum Spend', Inputs.minimum_spend, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Maximum Spend</label>
                                            <input type="number" min="0" step="1" className="form-control" name="maximum_spend" onChange={handleInputChanges} value={Inputs.maximum_spend} placeholder="Maximum Spend" />
                                            {validator.current.message('Maximum Spend', Inputs.maximum_spend, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    {
                                        Inputs.discount_type != "fixed_cart_discount" ?
                                            <div className="col-md-12">
                                                <div className='row'>
                                                    <div className={(SelectedCategoryIds.length > 0 || SelectedExcludeCategoryIds.length > 0) ? 'col-md-6 d-none' : 'col-md-6'}>
                                                        <div className="form-group">
                                                            <label>Products</label>
                                                            {
                                                                Products.length > 0 &&
                                                                <DropdownTreeSelect data={Products} id="productSelect" mode="hierarchical" onChange={onChangeProduct} className="bootstrap-demo w-100" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={(SelectedCategoryIds.length > 0 || SelectedExcludeCategoryIds.length > 0) ? 'col-md-6 d-none' : 'col-md-6'}>
                                                        <div className="form-group">
                                                            <label>Exclude Products</label>
                                                            {
                                                                ExcludeProducts.length > 0 &&
                                                                <DropdownTreeSelect data={ExcludeProducts} id="excludeProductSelect" mode="hierarchical" onChange={onChangeExcludeProduct} className="bootstrap-demo w-100" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={(SelectedProductsIds.length > 0 || SelectedExcludeProductsIds.length > 0) ? 'col-md-6 d-none' : 'col-md-6'}>
                                                        <div className="form-group">
                                                            <label>Category</label>
                                                            {
                                                                Categories.length > 0 &&
                                                                <DropdownTreeSelect data={Categories} id="categorySelect" mode="hierarchical" onChange={onChangeCategory} className="bootstrap-demo w-100" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={(SelectedProductsIds.length > 0 || SelectedExcludeProductsIds.length > 0) ? 'col-md-6 d-none' : 'col-md-6'}>
                                                        <div className="form-group">
                                                            <label>Exclude Category</label>
                                                            {
                                                                ExcludeCategories.length > 0 &&
                                                                <DropdownTreeSelect data={ExcludeCategories} id="excludeCategorySelect" mode="hierarchical" onChange={onChangeExcludeCategory} className="bootstrap-demo w-100" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Allowed Users</label>
                                                            {
                                                                Customers.length > 0 &&
                                                                <DropdownTreeSelect data={Customers} id="userSelect" mode="hierarchical" onChange={onChangeCustomer} className="bootstrap-demo w-100" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Limit Usages to X items</label>
                                                            <input type="number" min="0" step="1" className="form-control" name="usage_limit_item" value={Inputs.usage_limit_item} onChange={handleInputChanges} placeholder="Unlimited Usages" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Usage Limit per Coupon</label>
                                            <input type="number" min="0" step="1" className="form-control" name="usage_coupon" value={Inputs.usage_coupon} onChange={handleInputChanges} placeholder="Unlimited Usages" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Usage Limit per User</label>
                                            <input type="number" min="0" step="1" className="form-control" name="usage_limit_user" value={Inputs.usage_limit_user} onChange={handleInputChanges} placeholder="Unlimited Usages" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2" ref={submitBtnRef}> Submit </button>
                                        <Link to="/coupons" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCoupons;