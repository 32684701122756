import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { SimpleReactValidator } from '../../config/react'

const AddVideo = (props) => {
    const [isForceUpdate, setForceUpdate] = useState(false);
    const validator = useRef(new SimpleReactValidator())
    const [VideoPreviewImage, setVideoPreviewImage] = useState('');
    const [VideoDetail, setVideoDetail] = useState({
        url: "",
        title: "",
        description: "",
        preview_image: ""
    });

    useEffect(() => {
        setVideoDetail(props.dataParentToChild);
        if(props.dataParentToChild.hasOwnProperty('preview_image')){
            setVideoPreviewImage(props.dataParentToChild['preview_image']);
        }
    }, [])

    useEffect(() => {
        // Refresh JSON Variable
        props.parentCallback(VideoDetail);
    }, [VideoDetail])

    const handleVideoDetail = (e) => {
        var field = e.target.name, fieldval = e.target.value;
        setVideoDetail({ ...VideoDetail, [field]: fieldval });
        setForceUpdate(!isForceUpdate);
        //props.parentCallback(VideoDetail);
    };

    const handleVideoPreviewImage = (e) => {
        var fileTypes = ['jpg', 'jpeg', 'png'];
        if (e.target.files) {
            const files = Array.from(e.target.files);
            Promise.all(files.map(file => {
                var extension = file.name.split('.').pop().toLowerCase();
                return (new Promise((resolve,reject) => {
                    if (fileTypes.indexOf(extension) >= 0) {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }
                }));
            }))
            .then(images => {
                setVideoPreviewImage(images[0]);
                setVideoDetail({ ...VideoDetail, ['preview_image']: images[0] });
                setForceUpdate(!isForceUpdate)
                //props.parentCallback(VideoDetail);
            }, error => {
                console.error(error);
            });
        }
    };
    
    return (
        <div className="col-md-12">
            <div className='form-group'>
                <label htmlFor="">URL</label>
                <input type="text" className='form-control' defaultValue={VideoDetail.url} name="url" onChange={handleVideoDetail} />
            </div>
            <div className='form-group'>
                <label htmlFor="">Title</label>
                <input type="text" className='form-control' defaultValue={VideoDetail.title} name="title" onChange={handleVideoDetail} />
            </div>
            <div className='form-group'>
                <label htmlFor="">Description</label>
                <textarea name="description" className='form-control' defaultValue={VideoDetail.description} onChange={handleVideoDetail} rows="3"></textarea>
            </div>
            <div className='form-group'>
                <label htmlFor="">Preview Image</label>
                <div className="custom-file">
                    <input type="file" className="custom-file-input" onChange={handleVideoPreviewImage} accept="image/jpeg,image/jpg,image/png" id="video-preview-image" />
                    <label for="video-preview-image" className='btn btn-primary'>Upload Image</label>
                </div>
                {
                    VideoPreviewImage != "" && 
                    <img width="auto" height="300" className='mt-3' src={VideoPreviewImage}/>
                }
            </div>
        </div>
    );
};

export default AddVideo;