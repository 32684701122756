import $ from 'jquery';

var body = document.getElementById("root");

$(document).mouseup(function () {
  $('[data-toggle="minimize"]').on("click", function () {
    if ((body.classList.contains('sidebar-toggle-display')) || (body.classList.contains('sidebar-absolute'))) {
      body.classList.remove('sidebar-icon-only');
    } else {
      body.classList.add('sidebar-icon-only');
    }
  });
  $('.sidebar-icon-only [data-toggle="minimize"]').on("click", function () {
    body.classList.remove('sidebar-icon-only');
  });
  $('#accordionMenu li .nav-link[href]').on("click", function () {
    body.classList.add('sidebar-icon-only');
  });
  $('.main-panel').on("click", function () {
    body.classList.add('sidebar-icon-only');
  });

  $('[data-toggle="offcanvas"]').on("click", function () {
    var offcanvas = document.getElementById("sidebar");
    if ((offcanvas.classList.contains('active'))) {
      offcanvas.classList.remove('active');
    } else {
      offcanvas.classList.add('active');
    }
  });
});

$(document).ready(function () {
  $(".form-check label, .form-radio label").append('<i class="input-helper"></i>');

  // Filter Bar show and hide
  $('.filter-btn').on("click", function () {
    var filterId = document.getElementById("filter-bar");
    if (filterId == null) return
    if ((filterId.classList.contains('filter-show'))) {
      filterId.classList.remove('filter-show');
    } else {
      filterId.classList.add('filter-show');
    }
  });

  $('[data-toggle="tooltip"]').tooltip()

});
