import React from 'react'; 

const Footer = () => {
    return (
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © Harmony Jewels {new Date().getFullYear()}</span>
                {/* <span className="text-muted d-block text-center text-sm-left d-sm-inline-block"> Developed by <a
                    href="http://www.grapestechsolutions.com/" target="_blank">GrapesTech Solutions Pvt. Ltd.</a></span> */}
            </div>

        </footer>
    );
};

export default Footer;
