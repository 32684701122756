import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { HttpService, API, swal, Title, toast, Messages, DateFormat,Common,Permission_keys } from '../../../config/react'
// import { confirmWrapper, confirm } from '../../constants/Confirmation'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Spinner from '../../Spinner';
const DiamondShape = () => {

    const [ShapeList, setShapeList] = useState([])
    const [isForceUpdate, setForceUpdate] = useState(false);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const columns = [
        {
            name: 'Shape Name',
            selector: row => row.name,
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>
        },
        {
            name: 'Status',
            selector: row => row.status == 1 ? <label>Active</label> : <label>Inactive</label>
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/system/diamondshape/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    <Link onClick={e => DeleteShape(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.DiamondShape
        GetShapeList()
    }, [CustomFilters])

    const GetShapeList = () => {
        var passData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetStoneShapeAdminList, passData)
        .then(response => {
            if (response.data.status == true) {
                var data = response.data.result[1];
                // var data = response.data.result[0];

                setShapeList(data)
                setForceUpdate(!isForceUpdate)
                settotalRows(response.data.result[0][0].total_row)
            }
        });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search For Shape Name." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
		);
	});

    const DeleteShape = async (e, id) => {

        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id,
                    "IsDeleted": true
                }
                console.log(param);
                HttpService.postApi(API.DeleteDiamondShape, param)
                    .then(response => {
                        console.log(response);
                        toast.success(Messages.Record_Deleted)
                        GetShapeList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }


    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.DiamondShape} </h3>
                <div className="btn-group">
                    <Link to="/system/diamondshape/add" className="btn btn-dark btn-fw rounded mr-2"> Add Diamond Shape </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                            <DataTable
                                    columns={columns}
                                    data={ShapeList}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiamondShape;