import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, DropdownTreeSelect, FileUploadWithPreview, Common,Permission_keys } from '../../config/react'
import { DeleteDiamondQuality } from '../../constants/API';
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import Spinner from '../Spinner';
const AddBanner = () => {
    const urlParams = useParams()
    const history = useHistory()
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const videoLinkValidator = useRef(new SimpleReactValidator())
    const linkValidator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext)
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [IsShowImageRemoveLink, setIsShowImageRemoveLink] = useState(false)
    const [IsShowMobileImageRemoveLink, setIsShowMobileImageRemoveLink] = useState(false)
    const [Content, setContent] = useState();
    const [BannerImage, setBannerImage] = useState('')
    const [BannerMobileImage, setBannerMobileImage] = useState('')
    // const [Banners, setbanners] = useState([])
    const [Inputs, setInputs] = useState({
        id: 0,
        name: "",
        description: "",
        slug: "",
        image: "",
        link: "",
        video_link: "",
        parent_id: 1,
        abbreviation: "",
        meta_description: "",
        meta_keyword: "",
        status: 0,
        banner_type: 'image',
        content: ""
    });
    var banner_images = '';
    var banner_Mobile_images = '';
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Widgets)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddBanner
        if (urlParams.id != null) {
            document.title = Title.EditBanner
            GetBannerDetail()
        }
        //GetBanners()
        // initImagePlugin()
        // initMobile_ImagePlugin()
    }, [urlParams.id])

    const initImagePlugin = (image = null) => {
        if (image != '' && image != null) {
            console.log(image);
            banner_images = new FileUploadWithPreview("banner_images", {
                text: {
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            banner_images = new FileUploadWithPreview("banner_images", {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }

    const initMobile_ImagePlugin = (image = null) => {
        if (image != '' && image != null) {
            banner_Mobile_images = new FileUploadWithPreview("banner_Mobile_images", {
                text: {
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            banner_Mobile_images = new FileUploadWithPreview("banner_Mobile_images", {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }
    const GetBannerDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetBanner, param)
            .then(response => {
                if (response.data.result) {
                    var data = response.data.result[0];
                    var banner = data.filter(x => x.id == urlParams.id)[0];
                    setContent(banner.content);
                    setInputs(banner);

                    // if (banner.image.includes(".")) {
                    //     initImagePlugin(banner.image)
                    //     initMobile_ImagePlugin(banner.mobile_image)
                    // }
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status")
            Inputs[field] = e.target.checked;
        else
            Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }
    // window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
    //     if (e.detail.uploadId == "banner_Mobile_images") {
    //         getBase64(e.detail.cachedFileArray[0], (result) => {
    //             setBannerMobileImage(result)
    //         });
    //         setIsShowMobileImageRemoveLink(true)

    //     }
    //     else {
    //         getBase64(e.detail.cachedFileArray[0], (result) => {
    //             setBannerImage(result)
    //         });
    //         setIsShowImageRemoveLink(true)
    //     }

    // });

    // window.addEventListener("fileUploadWithPreview:clearButtonClicked", function (e) {
    //     if (e.detail.uploadId == "banner_Mobile_images") {
    //         setBannerMobileImage('')
    //         setIsShowMobileImageRemoveLink(false)
    //     }
    //     else {
    //         setBannerImage('')
    //         setIsShowImageRemoveLink(false)
    //     }
    // });

    const SubmitForm = (e) => {

        if (Inputs.banner_type == 'video') {
            if (Inputs.name.trim() == '') {
                if (!validator.current.allValid()) {
                    validator.current.showMessages();
                    setForceUpdate(!isForceUpdate)
                    return
                }
            }

            if (!videoLinkValidator.current.allValid()) {
                videoLinkValidator.current.showMessages();
                setForceUpdate(!isForceUpdate)
                return
            }
        }
        else {
            if (!validator.current.allValid()) {
                validator.current.showMessages();
                setForceUpdate(!isForceUpdate)
                return
            }
            if (!linkValidator.current.allValid()) {
                linkValidator.current.showMessages();
                setForceUpdate(!isForceUpdate)
                return
            }
        }
        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "content": Content,
            // "image": BannerImage,
            // "mobile_image": BannerMobileImage,
            // "link": Inputs.link,
            "video_link": Inputs.video_link,
            "status": Inputs.status ?? 0,
            "banner_type": Inputs.banner_type
        }
        HttpService.postApi(API.SetBanner, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/widgets/banner')
            });
    }
    const handleEditorChange = (e) => {
        setContent(e)
        // setInputs({ ...Inputs, ["content"]: e });
        setForceUpdate(!isForceUpdate)
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddBanner : Title.EditBanner} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name" onChange={handleInputChanges} defaultValue={Inputs.name} placeholder="Name" />
                                            {validator.current.message('Name', Inputs.name, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Banner Type</label>
                                            <select className='form-control' name="banner_type" value={Inputs.banner_type} onChange={handleInputChanges}>
                                                <option value='image'>Image</option>
                                                <option value='video'>Video</option>
                                            </select>
                                            {validator.current.message('Banner Type', Inputs.banner_type, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    {/* <div className={Inputs.banner_type == 'video' ? "col-md-6 d-none" : "col-md-6"}>
                                        <div className="custom-file-container" data-upload-id="banner_images">
                                            <label>Image (only .png, .jpg, .jpeg formats allowed) Size 1920 X 782

                                            </label>
                                            <label className="custom-file-container__custom-file">
                                                <input type="file" className="custom-file-container__custom-file__custom-file-input" accept=".png,.jpg,.jpeg,.svg" aria-label="Choose Image" />
                                                <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                                <span className="custom-file-container__custom-file__custom-file-control"></span>
                                            </label>
                                            <div className="btn-remove-image">
                                                <a href={void (0)} className="custom-file-container__image-clear" title="Remove Image" style={IsShowImageRemoveLink ? {} : { display: 'none' }}>&times;</a>
                                                <div className="custom-file-container__image-preview">

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={Inputs.banner_type == 'video' ? "col-md-6 d-none" : "col-md-6"}>
                                        <div className="custom-file-container" data-upload-id="banner_Mobile_images">
                                            <label>Mobile Image (only .png, .jpg, .jpeg formats allowed) Size 785 X 1065

                                            </label>

                                            <label className="custom-file-container__custom-file">
                                                <input type="file" className="custom-file-container__custom-file__custom-file-input" accept=".png,.jpg,.jpeg" aria-label="Choose Image" />
                                                <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                                <span className="custom-file-container__custom-file__custom-file-control"></span>
                                            </label>
                                            <div className="btn-remove-image">
                                                <a href={void (0)} className="custom-file-container__image-clear" title="Remove Image" style={IsShowMobileImageRemoveLink ? {} : { display: 'none' }}>&times;</a>
                                                <div className="custom-file-container__image-preview">

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={Inputs.banner_type == 'video' ? "col-md-6 d-none" : "col-md-6"}>
                                        <div className="form-group">
                                            <label>Link</label>
                                            <input type="text" className="form-control" name="link" onChange={handleInputChanges} defaultValue={Inputs.link} placeholder="Banner Link" />
                                            {linkValidator.current.message('Link', Inputs.link, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div> */}
                                    <div className={Inputs.banner_type == 'video' ? "col-md-6" : "col-md-6 d-none"}>
                                        <div className="form-group">
                                            <label>Video Link</label>
                                            <input type="text" className="form-control" name="video_link" onChange={handleInputChanges} defaultValue={Inputs.video_link} placeholder="Video Link" />
                                            {videoLinkValidator.current.message('Video Link', Inputs.video_link, 'required|max:255|url', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className={Inputs.banner_type == 'image' ? 'row' : 'row d-none'}>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Content</label>
                                            <ReactSummernote
                                                name="content"
                                                value={Content}
                                                options={{
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ["style", ["style"]],
                                                        ["font", ["bold", "underline", "clear", 'strikethrough', 'superscript', 'subscript']],
                                                        ["fontname", ["fontname"]],
                                                        ["para", ["ul", "ol", "paragraph"]],
                                                        ["table", ["table"]],
                                                        ["insert", ["link", "picture", "video"]],
                                                        ["view", ["fullscreen", "codeview"]],
                                                        ['height', ['height']]
                                                    ]
                                                }}
                                                onChange={e => { handleEditorChange(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/widgets/banner" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBanner;