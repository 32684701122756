export const Dashboard = "Dashboard"

//Diamond
export const DiamondShape = "Diamond Shape"
export const AddDiamondShape = "Add Diamond Shape"
export const EditDiamondShape = "Edit Diamond Shape"
export const DiamondQuality = "Diamond Quality"
export const AddDiamondQuality = "Add Diamond Quality"
export const EditDiamondQuality = "Edit Diamond Quality"
export const DiamondMarkup = "Diamond Markup"
export const AddDiamondMarkup = "Add Diamond Markup"
export const EditDiamondMarkup = "Edit Diamond Markup"

//Metal
export const Metal = "Metal Type"
export const AddMetal = "Add Metal Type"
export const EditMetal = "Edit Metal Type"

//Metal Quality
export const MetalQuality = "Metal Quality"
export const AddMetalQuality = "Add Metal Quality"
export const EditMetalQuality = "Edit Metal Quality"

//Products
export const Products = "Products"
export const ClicksonLink = "Clicks on Link"
export const AddProducts = "Add Product"
export const ImportExcel = "Import Excel"
export const EditProducts = "Edit Product"
export const GenerateCSV = "Generate CSV"
export const DeleteSelected = "Delete Selected Items"

//Coupons
export const Coupons = "Coupons"
export const AddCoupons = "Add Coupon"
export const EditCoupons = "Edit Coupon"

//Catalog Price Rule
export const CatalogPriceRule = "Catalog Price Rule"
export const AddCatalogPriceRule = "Add Catalog Price Rule"
export const EditCatalogPriceRule = "Edit Catalog Price Rule"

export const RelatedProduct = "Related Products"
export const ProductConfigurations = "Create Product Configurations"
export const ConfigurableStep1 = "Bulk Images, Price and Quantity"
export const AddVideo = "Add Video"
export const EditVideo = "Edit Video"

//Product Sizes
export const ProductSize = "Product Size"
export const AddProductSize = "Add Product Size"
export const EditProductSize = "Edit Product Size"

//Category
export const Category = "Category"
export const AddCategory = "Add Category"
export const EditCategory = "Edit Category"

//Content Management
export const ContentManagement = "Content Management"
export const AddContentManagement = "Add Content Management"
export const EditContentManagement = "Edit Content Management"
export const Pages = "Pages"

// Navigation Menu
export const NavigationMenu = "Navigation Menu"
export const EditNavigationMenu = "Edit Navigation Menu"
//Customer
export const AddCustomer = "Add Customer"
export const EditCustomer = "Edit Customer"
export const Customers = "Customers"
export const CustomerGroup = "Customer Group"
export const AddCustomerGroup = "Add Customer Group"
export const EditCustomerGroup = "Update Customer Group"
export const Addresses = "Addresses"
export const AddCustomerAddress = "Add New Address"
export const EditCustomerAddress = "Edit Address"

//Attribute
export const ProductAttribute = "Product Attribute"
export const AddProductAttribute = "Add Product Attribute"
export const EditProductAttribute = "Edit Product Attribute"

//Attribute Set
export const ProductAttributeSet = "Product Attribute Set"
export const AddProductAttributeSet = "Add Product Attribute Set"
export const EditProductAttributeSet = "Edit Product Attribute Set"

// Attribute Group
export const AddAttributeGroup = "Add Group"
export const EditAttributeGroup = "Edit Group"

// Banner Group
export const Banner = "Banner"
export const AddBanner = "Add Banner"
export const EditBanner = "Edit Banner"

// Diamond
export const Diamond = "Diamond"
export const PullDiamond = "Pull Diamond"
export const SoldDiamond = "Sold Diamond"

// Order
export const Order = "Order"
export const OrdersDetails = "OrdersDetails"

//Cache
export const CacheManagement = "Cache Management"

// Newsletter
export const Newsletter = "Newsletters"
export const AddNewsletter = "Add Newsletter"
export const Subscriber = "Subscribers"

// Contact Form
export const ContactForm = "Contact Form"
export const SendResponse = "Send Response"

//Marketing
export const Reviews = "Reviews"
export const EditReview = "Edit Review"

//Setting
export const InstagramToken = "Instagram Token"
export const GenerateInstagramToken = "Generate Instagram Token"
export const Settings = "Settings"
export const SystemService = "System Services"
export const ServiceLogs = "Service Logs"
export const AddSettingOption = "Add Setting Option"
export const EditSettingOption = "Edit Setting Option"

//Services
export const AddService = "Add Service"
export const EditService = "Edit Service"
export const Service = "Services"
export const AppointmentTitle = "Appointment"
export const EditAppointment = "Edit Appointment"
export const AppointmentReport = "Appointment Report"

//Blog Category
export const BlogCategory = "Blog Category"
export const AddBlogCategory = "Add Blog Category"
export const EditBlogCategory = "Edit Blog Category"

//Blogs
export const Blogs = "Blogs"
export const AddBlog = "Add Blog"
export const EditBlog = "Edit Blog"

//Report
export const LinkStatistics = "Link Statistics"
export const UsersPerDay = "Users per day"
export const SearchTerms = "Search Terms"

//User Management
export const Roles = "Roles"
export const AddRole = "Add Role"
export const EditRole = "Edit Role"
export const Users = "Users"
export const AddUser = "Add User"
export const EditUser = "Edit User"

export const Permissions = "Permissions"
export const AddPermission = "Add Permission"
export const EditPermission = "Edit Permission"
//Virtual Ring Builder
export const VirtualRingBuilder = "Virtual Ring Builder Request"

