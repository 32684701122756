import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, SimpleReactValidator, API, toast, Title, Messages, FileUploadWithPreview, getBase64, Common, Permission_keys } from '../../config/react'
import { ReactSVG } from 'react-svg'
import Spinner from '../Spinner';
const AddBlogCategory = () => {
    const urlParams = useParams()
    const history = useHistory()
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [IsShowImageRemoveLink, setIsShowImageRemoveLink] = useState(false)
    const [BlogImage, setBlogImage] = useState('')
    const validator = useRef(new SimpleReactValidator())
    const [svgUrl, setsvgUrl] = useState('');
    const [Inputs, setInputs] = useState({
        id: 0,
        name: "",
        description: "",
        image: "",
        slug: "",
        status: 0,
    });
    var blog_images = '';
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.blogs)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        if (urlParams.id != undefined)
            GetBlogCategoryById();

        setTimeout(() => {
            initImagePlugin();
        }, 1000);
    }, [urlParams.id])
    const GetBlogCategoryById = () => {
        var param = {
            "id": urlParams.id,
            "page": 0,
            "search": null,
            "page_size": 0
        }
        HttpService.postApi(API.GetBlogCategoryById, param)
            .then(response => {
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const initImagePlugin = (image = null) => {
        if (image != '' && image != null) {
            blog_images = new FileUploadWithPreview("blog_images", {
                text: {
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            blog_images = new FileUploadWithPreview("blog_images", {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }
    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
            };

    window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
        getBase64(e.detail.cachedFileArray[0], (result) => {
            setsvgUrl(result)
            setBlogImage(result)
        });
        setIsShowImageRemoveLink(true)
    });

    window.addEventListener("fileUploadWithPreview:clearButtonClicked", function (e) {
        setBlogImage('')
        setIsShowImageRemoveLink(false)
    });

    const SubmitForm = (e) => {
        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "description": Inputs.description,
            "image": BlogImage,
            "slug": Inputs.slug
        }
        HttpService.postApi(API.SetBlogCategory, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/blog/blogcategory')
            });
    };
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddBlogCategory : Title.EditBlogCategory} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name" onChange={handleInputChanges('name')} onBlur={() => validator.current.showMessageFor("Name")} value={Inputs.name} placeholder="Name" />
                                            {validator.current.message('Name', Inputs.name, 'required|alpha_num_dash_space|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Slug</label>
                                            <input type="text" className="form-control" name="slug" onChange={handleInputChanges('slug')} onBlur={() => validator.current.showMessageFor("Slug")} value={Inputs.slug} placeholder="Slug" />
                                            {validator.current.message('Slug', Inputs.slug, 'required|alpha_num_dash|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control" name="description" onChange={handleInputChanges('description')} onBlur={() => validator.current.showMessageFor("Description")} value={Inputs.description} placeholder="Description"></textarea>
                                            {validator.current.message('Description', Inputs.description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="custom-file-container mb-5" data-upload-id="blog_images">
                                            <label>Image</label>
                                            <label className="custom-file-container__custom-file h-auto">
                                                <input type="file" className="custom-file-container__custom-file__custom-file-input" accept=".svg" aria-label="Choose Image" />
                                                <ReactSVG className="menu-svg-icon" src={svgUrl} />
                                                <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                                <span className="custom-file-container__custom-file__custom-file-control"></span>
                                            </label>
                                            <div className="btn-remove-image d-none">
                                                <a href={void (0)} className="custom-file-container__image-clear" title="Remove Image" style={IsShowImageRemoveLink ? {} : { display: 'none' }}>&times;</a>
                                                <div className="custom-file-container__image-preview">

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/blog/blogcategory" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default AddBlogCategory;