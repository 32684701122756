import React, { useEffect, useState, useContext } from 'react';
import { HttpService, API,Common ,Permission_keys,MaterialTable, DateFormat } from '../../config/react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Spinner from '../Spinner';
const RelatedProducts = (props) => {

    const [ProductList, setProductList] = useState([])
    const [isForceUpdate, setForceUpdate] = useState(false);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        GetProductsList()
    }, [])

    const GetProductsList = () => {
        var selectedDatas = props.dataParentToChild;
        var selectedRows = [];
        for(var i = 0; i<selectedDatas.length; i++){
            selectedRows.push(selectedDatas[i]['id']);
        }
        HttpService.postApi(API.GetProductsList, null)
            .then(response => {
                var data = response.data.result[0];
                setProductList(data)
            });
    }

    const checkAllCheckbox = (e) => {
        var checkData = [];
        if(e.target.checked){
            for(var j=0; j<ProductList.length; j++){
                ProductList[j]['checkStatus'] = true;
            }
            if(ProductList[j]['checkStatus'] === true){
                checkData.push(ProductList[j]);
            }
        }
        else{
            for(var j=0; j<ProductList.length; j++){
                ProductList[j]['checkStatus'] = false;
            }
            if(ProductList[j]['checkStatus'] === true){
                checkData.push(ProductList[j]);
            }
        }
        props.parentRelatedProductCallback(checkData);
        setForceUpdate(!isForceUpdate)
    }

    const handleCheckChange = (e) => {
        var checkData = [];
        for(var j=0; j<ProductList.length; j++){
            if(ProductList[j]['id'] == e.target.value){
                if(e.target.checked){
                    ProductList[j]['checkStatus'] = true;
                }
                else{
                    ProductList[j]['checkStatus'] = false;
                }
            }
            if(ProductList[j]['checkStatus'] === true){
                checkData.push(ProductList[j]);
            }
        }
        props.parentRelatedProductCallback(checkData);
        setForceUpdate(!isForceUpdate)
    }

    return !IsAuth ? <Spinner /> : (
        <div className="table-responsive">
            <MaterialTable
                title=""
                columns={[
                    {
                        title: <input type="checkbox" onChange={checkAllCheckbox}/>, field: 'id', sorting: false,
                        render: rowData => <input type="checkbox" onChange={handleCheckChange} checked={rowData.checkStatus} value={rowData.id}/>
                    },
                    { title: 'Name', field: 'name' },
                    { title: 'Category', field: 'category_name' },
                    { title: 'Attribute Set', field: 'attribute_set_id' },
                    { title: 'Metal Quality', field: 'metal_quality' },
                    { title: 'Metal Weight', field: 'weight' },
                    {
                        title: 'Created Date', field: 'created_at', type: 'datetime',
                        render: rowData => <DateFormat value={rowData.created_at}></DateFormat>
                    }
                ]}

                data={ProductList}
                options={{
                    thirdSortClick: false,
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    exportCsv: (columns, data) => {
                        alert('You should develop a code to export ' + data.length + ' rows');
                    },

                }}
            />
        </div>
    );
};

export default RelatedProducts;