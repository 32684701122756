import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SoldDiamond } from '../../constants/PageTitle';
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat,Common,Permission_keys } from '../../config/react'
import Spinner from '../Spinner';
const VirtualRingRequestDetail = (props) => {
    console.log(props);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [data, setData] = useState();
    const [isForceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Virtual_Ring_Builder_Request)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        setData(props.props?.data)
        setForceUpdate(!isForceUpdate)
    }, [])

    useEffect(() => {
        setForceUpdate(!isForceUpdate)
    }, [data])

    const closeModal = () => {
        props.onCloseModal(true);
    }
    const OnClick_RevertSoldDiamond = (issold) => {
        swal(Messages.diamond_ReverSold_Prop).then(function (isConfirm) {
            if (isConfirm) {
                HttpService.postApi(API.SetSoldDiamondBySKU, { "sku": props.props.data.sku, "issold": issold })
                    .then(response => {
                        props.onCloseModal(true);
                    });
            }
        })
    }

    return !IsAuth ? <Spinner /> : (
        <Modal show={props.props.IsShowModal} onHide={closeModal} backdrop="static" keyboard={false}>
            <Modal.Header>
                <div>
                    <h6>Virtual Ring Request</h6>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
            </Modal.Header>
            <Modal.Body>
                <div className="content-wrapper p-0">
                    <div className="row">
                        <div className="col-lg-12 stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between">
                                        <div className="col-md-12">
                                            <div className="card-header p-0 mb-3"></div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="address-info">
                                                <table className="table table-borderless table-diamond-detail">
                                                    <tbody>
                                                        {
                                                            data != undefined &&
                                                            <>
                                                                <tr>
                                                                    <td colSpan={2} className='text-center'><img src={data.image} width={300} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Product Name:</td>
                                                                    <td>{data.product_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>SKU:</td>
                                                                    <td>{data.sku}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Metal:</td>
                                                                    <td>{data.metal}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Style:</td>
                                                                    <td>{data.style}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Shape:</td>
                                                                    <td>{data.shape}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Carat:</td>
                                                                    <td>{data.carat + ' ct.'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Name:</td>
                                                                    <td>{data.firstname + ' ' + data.lastname}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email:</td>
                                                                    <td>{data.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Message:</td>
                                                                    <td>{data.message}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default VirtualRingRequestDetail;