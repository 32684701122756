import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, swal, API, SimpleReactValidator, Title, toast, Messages, Button, Modal, Common, Permission_keys } from '../../config/react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Spinner from '../Spinner';

const AddProductAttributeSet = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const groupvalidator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [ShowGroupModal, setShowGroupModal] = useState(false);
    const [GroupModalTitle, setGroupModalTitle] = useState(Title.AddAttributeGroup);
    const [IsDuplicateGroup, setIsDuplicateGroup] = useState('');
    var defaultPlus = 'mdi mdi-plus-box menu-icon fs-14';
    var defaultminus = 'mdi mdi-minus-box menu-icon fs-14';
    const [AttrSetItems, setAttrSetItems] = useState({
        items: [],
        groups: []
    });
    const [Inputs, setInputs] = useState({
        id: 0,
        name: "",
        status: 0
    });
    const [GroupInputs, setGroupInputs] = useState({
        name: "",
        id: 0
    });
    const grid = 8;

    const id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Attribute)) {
            history.push("/");
        }
        setIsAuth(true);
        // setForceUpdate(!isForceUpdate)
        document.title = Title.AddProductAttributeSet
        document.body.style.overflow = "hidden"
        if (urlParams.id != null) {
            document.title = Title.EditProductAttributeSet
            GetProductAttributeSetDetail()
            AttrSetItems['groups'] = [];
        }
        else {
            GetProductAttributeList()
            AttrSetItems['groups'] = [
                {
                    'id': 0,
                    'content': 'Default Group',
                    'className': 'lableNone',
                    'selected': []
                }
            ];
        }
    }, [urlParams.id])

    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status" || field == "is_required" || field == "use_in_search" || field == "show_in_storefront")
            Inputs[field] = e.target.checked;
        else
            Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }

    const handleGroupInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;
        GroupInputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }

    const GetProductAttributeSetDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetProductAttributeSetDetail, param)
            .then(response => {
                if (response.data.result[0]) {
                    var data = response.data.result[0]
                    var param = {
                        "limit": 10,
                        "page": 1
                    }
                    let ArrayItem = [];
                    HttpService.postApi(API.GetProductAttributeList, param)
                        .then(response => {
                            response.data.result[0].map(function (k, v) {
                                ArrayItem.push({ 'id': k.attribute_code, 'content': k.attribute_name });
                            });
                            AttrSetItems['items'] = ArrayItem;
                            data.map(item => (
                                Object.keys(item).map(function (key, value) {
                                    if (key == "group_names" || key == "attributes" || key == "group_id") {
                                        if (key == "group_id" && item["group_id"] != "") {
                                            var groupIds = item["group_id"].split(", ");
                                            var unique = groupIds.filter((v, i, a) => a.indexOf(v) === i);
                                            var groupNames = item["group_names"].split(", ");
                                            var uniqueNames = groupNames.filter((v, i, a) => a.indexOf(v) === i);
                                            for (var key1 in unique) {
                                                AttrSetItems['groups'].push({
                                                    'id': unique[key1],
                                                    'content': uniqueNames[key1],
                                                    'className': 'lableNone',
                                                    'selected': []
                                                });
                                            }
                                            if (item["attributes"] != null) {
                                                var attributes = item["attributes"].split("~");
                                                for (var attr in attributes) {
                                                    if (attributes[attr] != "") {
                                                        var detail = attributes[attr].split(",");
                                                        var attributeCode = [];
                                                        for (var acode in AttrSetItems['items']) {
                                                            if (AttrSetItems['items'][acode]['id'] == detail[0]) {
                                                                attributeCode = AttrSetItems['items'][acode];
                                                                AttrSetItems['items'].splice(acode, 1);
                                                            }
                                                        }
                                                        for (var acode in AttrSetItems['groups']) {
                                                            if (AttrSetItems['groups'][acode]['id'] == detail[1]) {
                                                                AttrSetItems['groups'][acode]['selected'].push(attributeCode);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        Inputs[key] = item[key];
                                    }
                                })
                            ));
                            for (var key in AttrSetItems['groups']) {
                                AttrSetItems['groups'][key]['id'] = key;
                            }
                            setForceUpdate(!isForceUpdate);
                        });
                }

            });
    }

    const GetProductAttributeList = () => {
        var param = {
            "limit": 10,
            "page": 1
        }
        let ArrayItem = [];
        HttpService.postApi(API.GetProductAttributeList, param)
            .then(response => {
                response.data.result[0].map(function (k, v) {
                    ArrayItem.push({ 'id': k.attribute_code, 'content': k.attribute_name });
                });
                AttrSetItems['items'] = ArrayItem;
                setForceUpdate(!isForceUpdate);
            });
    }

    const SubmitGroup = (e) => {
        if (!groupvalidator.current.allValid()) {
            groupvalidator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        // console.log(GroupInputs);
        // return ;
        if (GroupInputs.id == '') {
            var count = AttrSetItems['groups'].length;
            var index = AttrSetItems['groups'].findIndex(item => item.content.trim().toLowerCase() == GroupInputs.name.trim().toLowerCase())

            if (index > -1) {
                setIsDuplicateGroup(true);
                setShowGroupModal(true);
                setForceUpdate(!isForceUpdate)
                return
            }
            else {
                setIsDuplicateGroup(false);
                AttrSetItems['groups'].push(
                    {
                        'id': count,
                        'content': GroupInputs.name,
                        'className': 'lableNone',
                        'selected': []
                    }
                );
                setShowGroupModal(false);
                setForceUpdate(!isForceUpdate)
            }
        }
        else {

            var index = AttrSetItems['groups'].findIndex(item => item.content.trim().toLowerCase() == GroupInputs.name.trim().toLowerCase() && item.id != GroupInputs.id)

            if (index > -1) {
                setIsDuplicateGroup(true);
                setForceUpdate(!isForceUpdate)
                return
            }
            else {
                AttrSetItems['groups'][GroupInputs.id]['content'] = GroupInputs.name;
                setIsDuplicateGroup(false);
                setShowGroupModal(false);
            }
        }
        GroupInputs['id'] = 0;
        GroupInputs['name'] = '';
        for (var key in AttrSetItems['groups']) {
            AttrSetItems['groups'][key]['className'] = "labelNone";
            setForceUpdate(!isForceUpdate);
        }
        setForceUpdate(!isForceUpdate);
    };

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var attributeGroups = AttrSetItems.groups;
        console.log(attributeGroups);
        var groupString = "";
        for (var key in attributeGroups) {
            if (key > 0) {
                groupString += "^";
            }
            groupString += attributeGroups[key]['content'];
            if (attributeGroups[key]['selected'].length > 0) {
                groupString += "~";
                var selectedAttributes = attributeGroups[key]['selected'];
                for (var key1 in selectedAttributes) {
                    if (key1 > 0) {
                        groupString += ",";
                    }
                    groupString += selectedAttributes[key1]['id'];
                }
            }
        }
        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "status": Inputs.status,
            "attributes": groupString,
            "Userid": user.id
        }
        console.log(param);
        if (urlParams.id == null) {
            HttpService.postApi(API.AddProductAttributeSet, param)
                .then(response => {
                    console.log(response.data);
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    toast.success(Messages.Record_Added)
                    setForceUpdate(!isForceUpdate);
                    history.push('/attribute/attributeset')
                });
        }
        else {
            HttpService.postApi(API.UpdateProductAttributeSet, param)
                .then(response => {
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    toast.success(Messages.Record_Updated)
                    setForceUpdate(!isForceUpdate);
                    history.push('/attribute/attributeset')
                });
        }

    }

    const openAddGroupModal = () => {
        GroupInputs['id'] = "";
        GroupInputs['name'] = "";
        setGroupModalTitle(Title.AddAttributeGroup);
        setForceUpdate(!isForceUpdate);
        setShowGroupModal(true);
    };

    const handleDoubleClick = (value) => {
        GroupInputs['id'] = "";
        GroupInputs['name'] = "";
        GroupInputs['id'] = value.target.attributes.name.value;
        GroupInputs['name'] = value.target.innerText;
        setGroupModalTitle(Title.EditAttributeGroup);
        setForceUpdate(!isForceUpdate);
        setShowGroupModal(true);
    };

    const handleGroupSelect = (value) => {
        GroupInputs['id'] = value.target.attributes.name.value;
        GroupInputs['name'] = value.target.innerText;
        var id = value.target.attributes.name.value;
        for (var key in AttrSetItems['groups']) {
            AttrSetItems['groups'][key]['className'] = "labelNone";
            setForceUpdate(!isForceUpdate);
        }
        var index = AttrSetItems['groups'].findIndex(item => item.id == id)
        AttrSetItems['groups'][index]['className'] = "labelGray";
        setForceUpdate(!isForceUpdate);
    };

    const handleAccordionClick = (e, index) => {
        var IsCheckActivelst = document.getElementsByClassName('active').length > 0;
        if (IsCheckActivelst) {
            var activelst = document.getElementsByClassName('active')
            for (let index = 0; index <= activelst.length; index++) {
                const element = activelst[index];
                element.classList.remove('active')
            }
        }
        var icons = document.getElementById(index);
        if (e.target.className.includes("mdi-minus-box")) {
            icons.className = defaultPlus;
        }
        else {
            icons.className = defaultminus;
            icons.classList.add('active')
        }
        var activelst = document.getElementsByClassName('menu-icon')
        for (let index = 0; index < activelst.length; index++) {
            var element = activelst[index].classList;
            if (!element.value.includes('active'))
                element.value = defaultPlus;
        }
    };

    const deleteSelectedGroup = () => {
        if (GroupInputs['id'] == 0 || GroupInputs['id'] == "") {
            swal({
                title: Messages.oops,
                text: Messages.default_group_not_delete,
                buttons: {
                    cancel: false,
                    confirm: true,
                }
            });
        }
        else {
            var id = GroupInputs['id'];
            if (id == 0 && AttrSetItems['groups'].length == 1) {
                swal({
                    title: Messages.oops,
                    text: Messages.atleast_one_group,
                    buttons: {
                        cancel: false,
                        confirm: true,
                    }
                });
            }
            else {
                var index = AttrSetItems['groups'].findIndex(item => item.id == id)
                let attributes = AttrSetItems['groups'][index]['selected'];
                for (var key in attributes) {
                    AttrSetItems['items'].push(attributes[key]);
                    setForceUpdate(!isForceUpdate);
                }
                AttrSetItems['groups'][index]['selected'] = [];
                AttrSetItems['groups'].splice(index, 1);
                setForceUpdate(!isForceUpdate);
            }
        }
    };

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
    * Moves an item from one list to another list.
    */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        padding: grid
    });

    const getBoxHeight = id => ({
        height: '420px',
        overflowY: "scroll"
    });

    const getAttrListStyle = isDraggingOver => ({
        padding: grid,
        height: '500px',
        overflowY: "scroll"
    });

    const getList = id => {
        var hasNumber = /\d/;
        if (hasNumber.test(id)) {
            var destindex = id.match(/(\d+)/);
            return AttrSetItems['groups'][destindex[0]]['selected'];
        }
        else {
            return AttrSetItems[id2List[id]]
        }
    };

    const onDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        var hasNumber = /\d/;
        if (hasNumber.test(source.droppableId) && hasNumber.test(destination.droppableId)) {
            let sourindex = source.droppableId.match(/(\d+)/);
            let destindex = destination.droppableId.match(/(\d+)/);
            if (source.droppableId === destination.droppableId) {
                const items = reorder(
                    getList(source.droppableId),
                    source.index,
                    destination.index
                );
                AttrSetItems['groups'][sourindex[0]]['selected'] = items;
            }
            else {
                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                var dropId = destination.droppableId;
                var dropId1 = source.droppableId;
                AttrSetItems['groups'][sourindex[0]]['selected'] = result[dropId1]
                AttrSetItems['groups'][destindex[0]]['selected'] = result[dropId]
            }
        }
        else if (hasNumber.test(destination.droppableId)) {
            let destindex = destination.droppableId.match(/(\d+)/);
            console.log(destination);
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );
            var dropId = destination.droppableId;
            AttrSetItems['items'] = result.droppable
            AttrSetItems['groups'][destindex[0]]['selected'] = result[dropId]
        }
        else if (hasNumber.test(source.droppableId)) {
            let sourindex = source.droppableId.match(/(\d+)/);
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );
            var dropId = source.droppableId;
            AttrSetItems['items'] = result.droppable
            AttrSetItems['groups'][sourindex[0]]['selected'] = result[dropId]
        }
        else {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );
            AttrSetItems['items'] = items;
        }
    };

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddProductAttributeSet : Title.EditProductAttributeSet} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name" onChange={handleInputChanges} defaultValue={Inputs.name} placeholder="Name" />
                                            {validator.current.message('Name', Inputs.name, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername3" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="status" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="status"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <div className="col-md-6">
                                            <label>Groups</label>
                                            <hr className="m-0"></hr>
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <button type="button" className="btn btn-primary mr-2" onClick={openAddGroupModal}>Add New</button>
                                                    <button type="button" className="btn btn-primary mr-2" onClick={deleteSelectedGroup}>Delete Selected Group</button>
                                                </div>
                                                <div className="col-md-12 mt-1">
                                                    Double click on a group to rename it.
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-md-12" style={getBoxHeight(1)}>
                                                    <div id="accordion">
                                                        {AttrSetItems.groups.map((group, index) => (
                                                            <div className="card shadow-none">
                                                                <div className="card-header p-0 border-none">
                                                                    <a className="fs-16 p-0 pr-1 collapsed" onClick={(e) => handleAccordionClick(index)} data-toggle="collapse" data-target={"#attr_group" + index} aria-expanded="true" aria-controls={"attr_group" + index}>
                                                                        <i id={index} className={defaultPlus}></i>
                                                                    </a>
                                                                    <label name={group.id} className={group.className} style={{ margin: 0, padding: "2px" }} onClick={handleGroupSelect} onDoubleClick={handleDoubleClick}>{group.content}</label>
                                                                </div>

                                                                <div id={"attr_group" + index} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                                    <div className="card-body p-0 pl-4 font-dark">
                                                                        <Droppable droppableId={'droppable' + index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                                    {group.selected.map((item, index) => (
                                                                                        <Draggable
                                                                                            key={item.id}
                                                                                            draggableId={item.id}
                                                                                            index={index}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided.draggableProps.style
                                                                                                    )}>
                                                                                                    {item.content}
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Unassigned Attributes</label>
                                            <hr className="m-0"></hr>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={getAttrListStyle(snapshot.isDraggingOver)}>
                                                        {AttrSetItems.items.map((item, index) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={item.id}
                                                                index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}>
                                                                        {item.content}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </DragDropContext>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/attribute/attributeset" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size="md"
                show={ShowGroupModal}
                onHide={() => setShowGroupModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="edit-address-modal">
                        {GroupModalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Group Name</label>
                                <input type="text" className="form-control" name="name" onChange={handleGroupInputChanges} defaultValue={GroupInputs.name} placeholder="Group Name" />
                                {groupvalidator.current.message('Group Name', GroupInputs.name, 'required', { className: 'error' })}
                                {
                                    IsDuplicateGroup &&
                                    <label className='error'>Group already exist</label>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowGroupModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={SubmitGroup}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddProductAttributeSet;