import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Select from 'react-select';
import Spinner from '../../Spinner';
const AddUser = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const validator = useRef(new SimpleReactValidator())
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Roles, setRoles] = useState([]);
    const [SelectedRoles, setSelectedRoles] = useState([7, 8]);
    const DefaultInputs = {
        id: 0,
        email: "",
        name: "",
        password: "",
        confirm_password: "",
        is_active: 0,
        role_ids: []
    }
    const [Inputs, setInputs] = useState(DefaultInputs)
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.role_permission)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddUser
        GetRoles();
        if (urlParams.id != null) {
            document.title = Title.EditUser
            GetUser()
        }
    }, [urlParams.id])

    const GetUser = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetUser, param)
            .then(response => {
                if (response.data.result) {
                    let role_ids = response.data.result[0][0].role_ids != null ? response.data.result[0][0].role_ids.split(',') : [];
                    setInputs(response.data.result[0][0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const GetRoles = () => {
        HttpService.getApi(API.GetRoles)
            .then(response => {
                if (response.data.result) {
                    let roles = [];
                    response.data.result[0].map((item, index) => {
                        roles.push({ label: item.name, value: item.id })
                    })
                    setRoles(roles);
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const handleInputChanges = (event, fieldName) => {
        if (fieldName == "status")
            setInputs({ ...Inputs, [fieldName]: event.target.checked });
        else if (fieldName == 'role_ids')
            // Object.assign(Inputs, { [fieldName]: event });
            setInputs({ ...Inputs, [fieldName]: Array.isArray(event) ? event.map(x => x.value) : [] });
        else
            setInputs({ ...Inputs, [fieldName]: event.target.value });
        setForceUpdate(!isForceUpdate);
    };


    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "email": Inputs.email,
            "password": Inputs.password,
            "is_active": Inputs.is_active != null ? 1 : 0,
            "role_ids": Inputs.role_ids
        }
        HttpService.postApi(API.SetUser, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message);
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/users')
            });
    }
return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddUser : Title.EditUser} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name" onChange={(e) => handleInputChanges(e, 'name')} value={Inputs.name} />
                                            {validator.current.message('Name', Inputs.name, 'required|alpha_num_space|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control" name="email" onChange={(e) => handleInputChanges(e, 'email')} value={Inputs.email} />
                                            {validator.current.message('Email', Inputs.email, 'required|email|max:50', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="password">Password </label>
                                            <input type='password' className="form-control" name="password" id="password" onChange={(e) => handleInputChanges(e, 'password')} autoComplete='off' value={Inputs.password} />
                                            {
                                                urlParams.id == null &&
                                                validator.current.message('Password', Inputs.password, 'required|max:255', { className: 'error' })
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="cpassword">Confirm Password </label>
                                            <input type='password' className="form-control" name="confirm_password" id="cpassword" autoComplete="new-password" onChange={(e) => handleInputChanges(e, 'confirm_password')} value={Inputs.confirm_password} />
                                            {
                                                urlParams.id == null ?
                                                    validator.current.message('Confirm Password', Inputs.confirm_password, `required|max:255|in:${Inputs.password}`, {
                                                        className: 'error',
                                                        messages: {
                                                            in:
                                                                "Not matched with password.",
                                                        }
                                                    })
                                                    :
                                                    validator.current.message('Confirm Password', Inputs.confirm_password, `max:255|in:${Inputs.password}`, {
                                                        className: 'error',
                                                        messages: {
                                                            in:
                                                                "Not matched with password.",
                                                        }
                                                    })
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="is_active" onChange={(e) => handleInputChanges(e, 'is_active')} id="EnableUser" checked={Inputs.is_active} />
                                                    <label className="custom-control-label" htmlFor="EnableUser"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1"><strong>Role</strong></label>
                                            {
                                                Roles.length > 0 &&
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Select Option"
                                                    value={Roles.filter(obj => Inputs.role_ids?.includes(obj.value))}
                                                    options={Roles}
                                                    onChange={(e) => handleInputChanges(e, 'role_ids')}
                                                    isMulti
                                                    isClearable
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/users" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUser;