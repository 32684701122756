export const Master="all-master"
export const role_permission="role_permission"
export const blogs="blogs"
export const Order_List="order-list"
export const Test_Permission="test-permission"
export const Products="products"
export const Sales="sales"
export const Earrings="earrings"
export const Diamonds="diamonds"
export const Categories="categories"
export const Customers="customers"
export const Book_An_Appointment="book_an_appointment"
export const Content_Management="content_management"
export const Cache_Management="cache_management"
export const Widgets="widgets"
export const Attribute="attribute"
export const Report="report"
export const Newsletter="newsletter"
export const Contact_Form="contact_form"
export const Coupons="coupons"
export const Marketing="marketing"
export const Virtual_Ring_Builder_Request="virtual_ring_builder_request"
export const System="system"
