import React, { useEffect, useState } from 'react';
import { HttpService, API } from '../../config/react';
import { Link } from "react-router-dom";
// import PieChartComponent from './PieChartComponent';
import ColumnChartComponent from './ColumnChartComponent';
const Dashboard2 = () => {
    const [MostSearchProduct, setMostSearchProduct] = useState([]);
    const [MostViewedProduct, setMostViewedProduct] = useState([]);
    const [MostViewedCategory, setMostViewedCategory] = useState([]);
    const [MostSoldProduct, setMostSoldProduct] = useState([]);
    useEffect(() => {
        GetAnalysisData()
    }, [])
    const GetAnalysisData = () => {
        HttpService.getApi(API.GetAnalysisData)
            .then(response => {
                var labels = [];
                var tooltip_labels = [];
                var values = [];
                response.data.result[0].map(item => {
                    labels.push(item.keyword);
                    values.push(item.no_of_users);
                })
                var data = { labels: labels, values: values };
                setMostSearchProduct(data);

                labels = [];
                tooltip_labels = [];
                values = [];
                response.data.result[1].map(item => {
                    labels.push(item.sku);
                    tooltip_labels.push(item.label);
                    values.push(item.clicks);
                })
                data = { labels: labels, values: values, tooltip_labels: tooltip_labels };
                setMostViewedProduct(data);

                labels = [];
                values = [];
                response.data.result[2].map(item => {
                    labels.push(item.label);
                    values.push(item.clicks);
                })
                data = { labels: labels, values: values };
                setMostViewedCategory(data);

                labels = [];
                tooltip_labels = [];
                values = [];
                response.data.result[3].map(item => {
                    labels.push(item.sku);
                    tooltip_labels.push(item.label);
                    values.push(item.sold_count);
                })
                data = { labels: labels, values: values, tooltip_labels: tooltip_labels };
                setMostSoldProduct(data);
            });
    }


    return (

        <div className="content-wrapper pb-0">
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="page-title mb-3"> Products Chart </h3>
                <select className="form-control select-custom  mb-3">
                    <option value='all'>All</option>
                    <option value='year'>Year</option>
                    <option value='month'>Month</option>
                    <option value='week'>Week</option>
                    <option value='day'>Day</option>
                </select>
            </div>
            <div className="row">
                <div className="col-md-6 col-lg-4">
                    <div className="card grid-margin">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Max Search Products</h5>
                        </div>
                        <div className="card-body">
                            <div className="chart-wrapper chart-medium">
                                {
                                    MostSearchProduct != undefined &&
                                    <ColumnChartComponent data={MostSearchProduct} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="card grid-margin">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Most Viewed Products</h5>
                        </div>
                        <div className="card-body">
                            <div className="chart-wrapper chart-medium">
                                {
                                    MostViewedProduct != undefined &&
                                    <ColumnChartComponent data={MostViewedProduct} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="card grid-margin">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Most Viewed Category</h5>
                        </div>
                        <div className="card-body">
                            <div className="chart-wrapper chart-medium">
                                {
                                    MostViewedCategory != undefined &&
                                    <ColumnChartComponent data={MostViewedCategory} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="card grid-margin">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Most Sell Products</h5>
                        </div>
                        <div className="card-body">
                            <div className="chart-wrapper chart-medium">
                                {
                                    MostSoldProduct != undefined &&
                                    <ColumnChartComponent data={MostSoldProduct} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Dashboard2;