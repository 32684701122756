import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, swal, Title, toast, Messages, DateFormat,Common,Permission_keys } from '../../config/react'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Spinner from '../Spinner';
const ProductAttribute = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [ListData, setListData] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    const columns = [
        {
            name: 'Attribute Code',
            selector: row => row.attribute_code,
        },
        {
            name: 'Default Label',
            selector: row => row.attribute_name,
        },
        {
            name: 'Required',
            selector: row => row.is_required == 1 ? 'Yes' : 'No'
        },
        {
            name: 'Searchable',
            selector: row => row.use_in_search == 1 ? 'Yes' : 'No'
        },
        {
            name: 'Visible',
            selector: row => row.show_in_storefront == 1 ? 'Yes' : 'No'
        },
        {
            name: 'Default Value',
            selector: row => row.default_value,
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>
        },

        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/attribute/productattribute/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    <Link onClick={e => DeleteProductAttribute(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Attribute)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.ProductAttribute
        GetProductAttributeList()
    },[CustomFilters])

    const GetProductAttributeList = () => {
        var listparam = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }

        HttpService.postApi(API.GetProductAttributeAdminList, listparam)
            .then(response => {
                // console.log(response);
                // setSizeList(response.data.result[0])

                if (response.data.status == true) {
                    var data = response.data.result[1];
                    console.log(data);
                    setListData(data)
                    setForceUpdate(!isForceUpdate)
                    settotalRows(response.data.result[0][0].total_row)
                }
            });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }
    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search For Attribute Code, Default Value." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['page']: 1, ['search']: e.target.value })} />
		);
	});

    const DeleteProductAttribute = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id,
                    "IsDeleted": true,
                    "Userid": user.id
                }
                console.log(param);
                HttpService.postApi(API.DeleteProductAttribute, param)
                    .then(response => {
                        console.log(response);
                        toast.success(Messages.Record_Deleted)
                        GetProductAttributeList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.ProductAttribute} </h3>
                <div className="btn-group">
                    <Link to="/attribute/productattribute/add" className="btn btn-dark btn-fw rounded mr-2"> {Title.AddProductAttribute} </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">

                            <DataTable
                                    columns={columns}
                                    data={ListData}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductAttribute;