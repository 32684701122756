import React, { useEffect, useState, useContext } from 'react';
import { HttpService,Permission_keys,Common, API } from '../../config/react'
import AddImage from '../template/AddImage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Spinner from '../Spinner';

const ConfigurationImagePrice = (props) => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [MetalQualityList, setMetalQualityList] = useState([])
    const [ValueStatus, setValueStatus] = useState(false)
    const [ImageStatus, setImageStatus] = useState('')
    const [PriceStatus, setPriceStatus] = useState('')
    const [QuantityStatus, setQuantityStatus] = useState('')
    const [ImageValue, setImageValue] = useState([])
    const [PriceValue, setPriceValue] = useState('')
    const [QuantityValue, setQuantityValue] = useState('')
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [ConfigureImageArray, setConfigureImageArray] = useState([]);
    const [MetalQualitySelect, setMetalQualitySelect] = useState('')
    const [ConfigurableProductList, setConfigurableProductList] = useState({
        'id': 0,
        'metal_quality': '',
        'imageStatus': 'get_parent',
        'priceStatus': 'get_parent',
        'quantityStatus': 'get_parent',
        'images': [],
        'price': 0,
        'quantity': 0
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        setMetalQualityList(props.dataParentToChild);
        var data = props.dataParentToChild;
        var dataArray = [];
        for(var i=0; i<data.length; i++){
            dataArray.push({
                'id': data[i].id,
                'metal_quality': data[i].metal_quality,
                'imageStatus': data[i].imageStatus != null ? data[i].imageStatus : 'get_parent',
                'priceStatus': data[i].priceStatus != null ? data[i].priceStatus : 'get_parent',
                'quantityStatus': data[i].quantityStatus != null ? data[i].quantityStatus : 'get_parent',
                'images': data[i].images != null ? data[i].images : [],
                'price': data[i].price != null ? data[i].price : 0,
                'quantity': data[i].quantity != null ? data[i].quantity : 0
            });
        }
        setConfigurableProductList(dataArray);
        setForceUpdate(!isForceUpdate)
        props.parentCallback(dataArray);
        setFirstAutoSelect(dataArray[0]);
    }, [])

    const setFirstAutoSelect = (selectedDatas) => {
        setMetalQualitySelect(selectedDatas['id']);
        setImageStatus(selectedDatas['imageStatus']);
        setPriceStatus(selectedDatas['priceStatus']);
        setQuantityStatus(selectedDatas['quantityStatus']);
        setImageValue(selectedDatas['images']);
        setPriceValue(selectedDatas['price']);
        setQuantityValue(selectedDatas['quantity']);
        setValueStatus(true);
        setForceUpdate(!isForceUpdate)
    };

    const onMetalQualityChange = (e) => {
        setMetalQualitySelect(e.target.value);
        if(e.target.value != ""){
            var selectedDatas = [];
            for(var i=0; i < ConfigurableProductList.length; i++){
                if(ConfigurableProductList[i]['id'] == e.target.value){
                    console.log(ConfigurableProductList[i]);
                    selectedDatas = ConfigurableProductList[i];
                }
            }
            setImageStatus(selectedDatas['imageStatus']);
            setPriceStatus(selectedDatas['priceStatus']);
            setQuantityStatus(selectedDatas['quantityStatus']);
            setImageValue(selectedDatas['images']);
            setPriceValue(selectedDatas['price']);
            setQuantityValue(selectedDatas['quantity']);
            setValueStatus(true);
            setForceUpdate(!isForceUpdate)
        }
        else{
            setValueStatus(false)
            setForceUpdate(!isForceUpdate)
        }

        console.log(ImageStatus)
    };

    const handleImageConfiguration = (e) => {
        for(var i=0; i < ConfigurableProductList.length; i++){
            if(ConfigurableProductList[i]['id'] == MetalQualitySelect){
                ConfigurableProductList[i]['imageStatus'] = e.target.value;
                setImageStatus(e.target.value);
                setImageValue(ConfigurableProductList[i]['images']);
            }
        }
        setForceUpdate(!isForceUpdate)
        props.parentCallback(ConfigurableProductList);
    };

    const handlePriceConfiguration = (e) => {
        for(var i=0; i < ConfigurableProductList.length; i++){
            if(ConfigurableProductList[i]['id'] == MetalQualitySelect){
                ConfigurableProductList[i]['priceStatus'] = e.target.value;
                setPriceStatus(e.target.value);
                setPriceValue(ConfigurableProductList[i]['price']);
            }
        }
        setForceUpdate(!isForceUpdate)
        props.parentCallback(ConfigurableProductList);
    };

    const handleQuantityConfiguration = (e) => {
        for(var i=0; i < ConfigurableProductList.length; i++){
            if(ConfigurableProductList[i]['id'] == MetalQualitySelect){
                ConfigurableProductList[i]['quantityStatus'] = e.target.value;
                setQuantityStatus(e.target.value);
                setQuantityValue(ConfigurableProductList[i]['quantity']);
            }
        }
        setForceUpdate(!isForceUpdate)
        props.parentCallback(ConfigurableProductList);
    };

    const handleImageCallback1 = (images) => {
        setImageValue(images);
        for(var i=0; i < ConfigurableProductList.length; i++){
            if(ConfigurableProductList[i]['id'] == MetalQualitySelect){
                ConfigurableProductList[i]['images'] = images;
            }
        }
        props.parentCallback(ConfigurableProductList);
        setForceUpdate(!isForceUpdate)
    };

    const handlePriceChange = (e) => {
        setPriceValue(e.target.value);
        for(var i=0; i < ConfigurableProductList.length; i++){
            if(ConfigurableProductList[i]['id'] == MetalQualitySelect){
                ConfigurableProductList[i]['price'] = e.target.value;
            }
        }
        props.parentCallback(ConfigurableProductList);
        setForceUpdate(!isForceUpdate)
    };

    const handleQuantityChange = (e) => {
        setQuantityValue(e.target.value);
        for(var i=0; i < ConfigurableProductList.length; i++){
            if(ConfigurableProductList[i]['id'] == MetalQualitySelect){
                ConfigurableProductList[i]['quantity'] = e.target.value;
            }
        }
        props.parentCallback(ConfigurableProductList);
        setForceUpdate(!isForceUpdate)
    };

    const handleChildAccordion = (e) => {
        /*var className = e.target.children[1].className;
        var classSplit = className.split(" ");
        if(classSplit.indexOf("mdi-chevron-down") > -1){
            var index = classSplit.indexOf("mdi-chevron-down");
            classSplit[index] = "mdi-chevron-up";
            e.target.children[1].className = classSplit.join(" ");
        }
        else{
            var index = classSplit.indexOf("mdi-chevron-up");
            classSplit[index] = "mdi-chevron-down";
            e.target.children[1].className = classSplit.join(" ");
        }*/
    };

    return !IsAuth ? <Spinner /> : (
        <div className="col-md-12">
            <div className="form-group row">
                <label className="col-md-2 pt-3" htmlFor="">Select Metal Quality:</label>
                <select className="form-control col-md-3" onChange={onMetalQualityChange}>
                    <option value="">Please Select ...</option>
                    {
                        MetalQualityList.map((quality, index) => (
                            <option value={quality.id}>{quality.metal_quality}</option>
                        ))
                    }
                </select>
            </div>
            { ValueStatus &&
            <div id="accordion1">
                <div className="card shadow-none border-bottom mt-2">
                    <a data-toggle="collapse" onClick={handleChildAccordion} data-target="#collapseImages" aria-expanded="true" aria-controls="collapseImages">
                        <div className="card-header border-none">
                            <label>Images</label>
                            <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                        </div>
                    </a>

                    <div id="collapseImages" className="collapse show" aria-labelledby="collapseImages" data-parent="#accordion1">
                        <div className="card-body pl-4 pt-0 font-dark">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input type="radio" id="setParent" name="image_configure"  onChange={handleImageConfiguration} checked={ImageStatus == "get_parent"} value="get_parent"/>
                                    &nbsp;<label htmlFor="setParent" className="fs-16">Get from Parent Product</label><br/>

                                    <input type="radio" id="setUnique" name="image_configure" onChange={handleImageConfiguration} checked={ImageStatus == "get_unique"} value="get_unique"/>
                                    &nbsp;<label htmlFor="setUnique" className="fs-16">Apply unique images</label>
                                </div>
                                {
                                    ImageStatus == "get_unique" &&
                                    <div className="form-group">
                                        <AddImage dataParentToChild="validatedChildImage" dataImages={ImageValue} imageCallback={handleImageCallback1}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow-none border-bottom mt-2">
                    <a data-toggle="collapse" onClick={handleChildAccordion} data-target="#collapsePrice" aria-expanded="true" aria-controls="collapsePrice">
                        <div className="card-header border-none">
                            <label>Price</label>
                            <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                        </div>
                    </a>

                    <div id="collapsePrice" className="collapse" aria-labelledby="collapsePrice" data-parent="#accordion1">
                        <div className="card-body pl-4 pt-0 font-dark">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input type="radio" id="setParent1" name="price_configure" onChange={handlePriceConfiguration} checked={PriceStatus == "get_parent"} value="get_parent"/>
                                    &nbsp;<label htmlFor="setParent1" className="fs-16">Get from Parent Product</label><br/>
                                    <input type="radio" id="setUnique1" name="price_configure" onChange={handlePriceConfiguration} checked={PriceStatus == "get_unique"} value="get_unique"/>
                                    &nbsp;<label htmlFor="setUnique1" className="fs-16">Apply unique price</label>
                                </div>
                                {
                                    PriceStatus == "get_unique" &&
                                    <div className="form-group">
                                        <label htmlFor="">Price</label>
                                        <input type="number" min="0" defaultValue={PriceValue} onChange={handlePriceChange} className="form-control" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow-none border-bottom mt-2">
                    <a data-toggle="collapse" onClick={handleChildAccordion} data-target="#collapseQuantity" aria-expanded="true" aria-controls="collapseQuantity">
                        <div className="card-header border-none">
                            <label>Quantity</label>
                            <i className="mdi mdi-chevron-down fs-16 float-right"></i>
                        </div>
                    </a>

                    <div id="collapseQuantity" className="collapse" aria-labelledby="collapseQuantity" data-parent="#accordion1">
                        <div className="card-body pl-4 pt-0 font-dark">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input type="radio" id="setParent2" name="qty_configure" onChange={handleQuantityConfiguration} checked={QuantityStatus == "get_parent"} value="get_parent"/>
                                    &nbsp;<label htmlFor="setParent2" className="fs-16">Get from Parent Product</label><br/>
                                    <input type="radio" id="setUnique2" name="qty_configure" onChange={handleQuantityConfiguration} checked={QuantityStatus == "get_unique"} value="get_unique"/>
                                    &nbsp;<label htmlFor="setUnique2" className="fs-16">Apply unique quantity</label>
                                </div>
                                {
                                    QuantityStatus == "get_unique" &&
                                    <div className="form-group">
                                        <label htmlFor="">QTY</label>
                                        <input type="number" min="0" defaultValue={QuantityValue} onChange={handleQuantityChange} className="form-control" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default ConfigurationImagePrice;