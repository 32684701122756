
import React, { useEffect, useState, useContext } from 'react';
import { HttpService, API, Title, Common, Permission_keys, DatePicker } from '../../config/react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import Spinner from '../Spinner';
const LinkStatistics = () => {

    const [Products, setProducts] = useState([])
    const [Categories, setCategories] = useState([])
    const [Pages, setPages] = useState([])
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Interval, setInterval] = useState('all');
    const [FilterDate, setFilterDate] = useState('');
    const location = useLocation();
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Report)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        let date = '';
        if (location.state) {
            date = location.state.date;
            setFilterDate(new Date(date));
            setInterval('other');
        }
        GetLinkStatistics('', date)
    }, [])


    const GetLinkStatistics = (interval = 'all', date = '') => {
        var param = {
            'interval': interval,
            'date': date != '' ? new Date(date).toISOString() : ''
        }
        HttpService.postApi(API.GetLinkStatisticsPeriod, param)
            .then(response => {
                console.log(response.data.result);
                if (response.data.status && response.data.result[0].length > 0) {
                    setProducts(response.data.result[0].filter(item => item.type == 'products'));
                    setCategories(response.data.result[0].filter(item => item.type == 'category'));
                    setPages(response.data.result[0].filter(item => item.type == 'pages'));
                }
                else{
                    setProducts([]);
                    setCategories([]);
                    setPages([]);
                }
            });
    }

    const GoBackToMainReport = (e) => {
        e.preventDefault()
        history.push("/report/link-statistics")
    }
    const ChangeInterval = (e) => {
        GetLinkStatistics(e.target.value);
        setFilterDate('');
        setInterval(e.target.value);
    }
    const changeDate = (date) => {
        setFilterDate(date)
        GetLinkStatistics('', date);
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.LinkStatistics} </h3>
                <div className="d-flex ml-auto mr-3">
                <select className="form-control select-custom mr-2 ml-auto" value={Interval} onChange={(e) => ChangeInterval(e)}>
                    <option value='all'>All</option>
                    <option value='year'>Year</option>
                    <option value='month'>Month</option>
                    <option value='week'>Week</option>
                    <option value='day'>Day</option>
                    <option value='other'>Other</option>
                </select>
                {
                    Interval == 'other' &&
                    <DatePicker selected={FilterDate} onChange={(date) => changeDate(date)} maxDate={new Date()} />
                }
                </div>

                <button className="btn btn-dark rounded" type='button' onClick={(e) => GoBackToMainReport(e)}>Back to Main Report</button>
            </div>

            <div className="row">
                <div className="col-md-6 col-lg-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className='w-100'>
                                <div class="card-header p-0 mb-3">
                                    <h3 class="title font-20">{Title.Products}</h3>
                                </div>

                                <table class="table table-striped table-borderless">
                                    <tbody>
                                        <tr>
                                            <th className="w-75">Headline</th>
                                            <th>Clicks</th>
                                        </tr>
                                        {
                                            Products.length > 0 ?
                                                Products.map((item, index) => (
                                                    <tr>
                                                        <td className="w-75"><Link to={{ pathname: process.env.REACT_APP_FRONTEND_URL + item.URL }} target="_blank">{item.label}</Link></td>
                                                        <td>{item.clicks}</td>
                                                    </tr>
                                                ))
                                                : <tr><td className="text-center" colSpan="2">No data available</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className='w-100'>
                                <div class="card-header p-0 mb-3">
                                    <h3 class="title font-20">{Title.Category}</h3>
                                </div>
                                <table class="table table-striped table-borderless">
                                    <tbody>
                                        <tr>
                                            <th className="w-75">Headline</th>
                                            <th>Clicks</th>
                                        </tr>
                                        {
                                            Categories.length > 0 ?
                                                Categories.map((item, index) => (
                                                    <tr>
                                                        <td className="w-75"><Link to={{ pathname: process.env.REACT_APP_FRONTEND_URL + item.URL }} target="_blank">{item.label}</Link></td>
                                                        <td>{item.clicks}</td>
                                                    </tr>
                                                ))
                                                : <tr><td className="text-center" colSpan="2">No data available</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className='w-100'>
                                <div class="card-header p-0 mb-3">
                                    <h3 class="title font-20">{Title.Pages}</h3>
                                </div>
                                <table class="table table-striped table-borderless">
                                    <tbody>
                                        <tr>
                                            <th className="w-75">Headline</th>
                                            <th>Clicks</th>
                                        </tr>
                                        {
                                            Pages.length > 0 ?
                                                Pages.map((item, index) => (
                                                    <tr>
                                                        <td className="w-75"><Link to={{ pathname: process.env.REACT_APP_FRONTEND_URL + item.URL }} target="_blank">{item.label}</Link></td>
                                                        <td>{item.clicks}</td>
                                                    </tr>
                                                ))
                                                : <tr><td className="text-center" colSpan="2">No data available</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkStatistics;