import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';

const AddMetal = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Inputs, setInputs] = useState({
        id: 0,
        metal_type: "",
        metal_price: 0,
        metal_labour_charge: 0
    });



    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.EditMetal
        if (urlParams.id != null) {
            GetMetalDetail()
        }
    }, [urlParams.id])

    const GetMetalDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetMetal, param)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    // var data = response.data.result
                    // data.map(item => (
                    //     Object.keys(item).map(function (key, value) {
                    //         Inputs[key] = item[key];
                    //     })
                    // ))
                    setForceUpdate(!isForceUpdate);
                }
            });
    }

    const handleInputChanges = (e) => {
        console.log(e.target.value)
        var field = e.target.name, fieldval = e.target.value;
        Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }

    const SubmitForm = (e) => {

        if (!validator.current.allValid()) {
            var keys = Object.keys(validator.current.fields);
            var values = Object.values(validator.current.fields);
            values.forEach((index, element) => {
                console.log(element + '' + index)
                if (element) { validator.current.showMessageFor(keys[element]) }
                console.log(validator.current.fields[element]);
            });
            // validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        var param = {
            "id": Inputs.id,
            "metal_type": Inputs.metal_type,
            "metal_price": Inputs.metal_price,
            "metal_labour_charge": Inputs.metal_labour_charge
        }

        HttpService.postApi(API.UpdateMetalDetail, param)
            .then(response => {
                console.log(response);
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/system/metal')
            });

    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {Title.EditMetal} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Metal Type</label>
                                            <input type="text" className="form-control" name="metal_type" min="1" maxLength="100" onChange={handleInputChanges} value={Inputs.metal_type} placeholder="Metal Type" />
                                            {validator.current.message('Metal Type', Inputs.metal_type, 'required', { classNaame: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Metal Price(£)</label>
                                            <input type="number" className="form-control" name="metal_price" min="1" maxLength="7" onChange={handleInputChanges} value={Inputs.metal_price} placeholder="Metal Type" />
                                            {validator.current.message('Metal Price', Inputs.metal_price, 'required|numeric', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Metal Labour Charge(£)</label>
                                            <input type="number" className="form-control" name="metal_labour_charge" onChange={handleInputChanges} value={Inputs.metal_labour_charge} placeholder="Metal Labour Charge" />
                                            {validator.current.message('Metal Labour Charge', Inputs.metal_labour_charge, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/system/metal" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMetal;