import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';
const AddDiamondQuality = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const validator = useRef(new SimpleReactValidator())
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Inputs, setInputs] = useState({
        id: 0,
        stone_quality: "",
        color: "",
        clarity: "",
        status: 0
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddDiamondQuality
        if (urlParams.id != null) {
            document.title = Title.EditDiamondQuality
            GetDiamondQualityDetail()
        }
    }, [urlParams.id])

    const GetDiamondQualityDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetDiamondQuality, param)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    //var data = response.data.result
                    // data.map(item => (
                    //     Object.keys(item).map(function (key, value) {
                    //         //console.log(item[key])
                    //         Inputs[key] = item[key];
                    //     })
                    // ))
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
            };

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "stone_quality": Inputs.stone_quality,
            "color": Inputs.color,
            "clarity": Inputs.clarity,
            "status": Inputs.status ?? 0
        }

        HttpService.postApi(API.SetDiamondQuality, param)
            .then(response => {
                console.log(response);
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/system/diamondquality')
            });

    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddDiamondQuality : Title.EditDiamondQuality} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Diamond Quality</label>
                                            <input type="text" className="form-control" name="stone_quality" min="1" maxLength="25"
                                                onChange={handleInputChanges("stone_quality")}
                                                onKeyUp={handleInputChanges("stone_quality")}
                                                onBlur={() => validator.current.showMessageFor("Diamond Quality")}
                                                defaultValue={Inputs.stone_quality} placeholder="Diamond Quality" />
                                            {validator.current.message('Diamond Quality', Inputs.stone_quality, 'required|alpha_num_dash', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Clarity</label>
                                            <input type="text" className="form-control" name="clarity" min="1" maxLength="10"
                                                onChange={handleInputChanges("clarity")}
                                                onKeyUp={handleInputChanges("clarity")}
                                                onBlur={() => validator.current.showMessageFor("Clarity")}
                                                defaultValue={Inputs.clarity} placeholder="Clarity" />
                                            {validator.current.message('Clarity', Inputs.clarity, 'required|alpha', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Color</label>
                                            <input type="text" className="form-control" name="color" min="1" maxLength="10"
                                                onChange={handleInputChanges("color")}
                                                onKeyUp={handleInputChanges("color")}
                                                onBlur={() => validator.current.showMessageFor("Color")}
                                                defaultValue={Inputs.color} placeholder="Color" />
                                            {validator.current.message('Color', Inputs.color, 'required|alpha', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/system/diamondquality" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDiamondQuality;