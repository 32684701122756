import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';
const AddPermission = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const validator = useRef(new SimpleReactValidator())
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Inputs, setInputs] = useState({
        id: 0,
        name: "",
        description: "",
        key: ""
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.role_permission)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddPermission
        if (urlParams.id != null) {
            document.title = Title.EditPermission
            GetPermissionbyID()
        }
    }, [urlParams.id])

    const GetPermissionbyID = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetPermissionByID, param)
            .then(response => {
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
            };

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "description": Inputs.description,
            "key": Inputs.key
        }

        HttpService.postApi(API.SetPermission, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/permissions')
            });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddPermission : Title.AddPermission} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name"
                                                onChange={handleInputChanges("name")}
                                                onKeyUp={handleInputChanges("name")}
                                                onBlur={() => validator.current.showMessageFor("Name")}
                                                value={Inputs.name} placeholder="Name" />
                                            {validator.current.message('Name', Inputs.name, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input type="text" className="form-control" name="description"
                                                onChange={handleInputChanges("description")}
                                                onKeyUp={handleInputChanges("description")}
                                                onBlur={() => validator.current.showMessageFor("Description")}
                                                value={Inputs.description} placeholder="description" />
                                            {validator.current.message('Description', Inputs.name, 'required|alpha_num_space', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Key</label>
                                            <input type="text" className="form-control" name="key"
                                                onChange={handleInputChanges("key")}
                                                onKeyUp={handleInputChanges("key")}
                                                onBlur={() => validator.current.showMessageFor("Key")}
                                                value={Inputs.key} placeholder="key" />
                                            {validator.current.message('Key', Inputs.key, 'required|alpha_num_dash', { className: 'error' })}
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/permissions" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPermission;