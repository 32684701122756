import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);

const ColumnChartComponent = (props) => {

    const [ChartData, setChartData] = useState([]);
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return context[0].dataset.title_label ? context[0].dataset.title_label[context[0].dataIndex] : context[0].dataset.label[context[0].dataIndex]
                    },
                    label: function (context) {
                        return (context.parsed.y)
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value;
                    }
                }
            }
        }
    };
    const labels = props.data.labels;
    const data = {
        labels,
        datasets: [
            {
                label: '',
                title_label: props.data.tooltip_labels,
                data: props.data.values,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                    'rgba(255, 205, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(201, 203, 207, 0.5)'
                ],
            },
        ],
    };
    useEffect(() => {
        // if (props.data != undefined && props.data.length > 0) {
        //     props.data.map(item => {
        //         chart_data.push({ y: parseInt(item.no_of_users), label: item.keyword });
        //     })
        //     setChartData(chart_data);
        // }
    }, []);
    return (
        <Bar options={options} data={data} />
    );
};

export default ColumnChartComponent;