import React from 'react';

function Error(props) {
    return (
        <div className='text-center'>
            <h1>Something went wrong!</h1>
        </div>
    );
}

export default Error;