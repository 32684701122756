import React, { useEffect, useState, useRef } from 'react';
import { Line, getElementsAtEvent } from 'react-chartjs-2';
import { HttpService, API } from '../../config/react';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
Chart.register(CategoryScale);


const LineChartComponent = (props) => {
    // console.log(props);
    const chartRef = useRef();
    const [ChartData, setChartData] = useState([]);
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        fill: true,
        plugins: {
            legend: {
                display: false
            },

            // title: {
            //     display: true,
            //     text: '',
            // },
        },
        // scales: {
        //     x: {
        //         display: false // Hide Y axis labels
        //     }
        // }
    };
    const labels = props.data.labels;
    const data = {
        labels,
        datasets: [
            {
                label: '',
                data: props.data.values,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                    'rgba(255, 205, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(201, 203, 207, 0.5)'
                ],
            },
        ],

    };
    useEffect(() => {
        // console.log(props);
    }, []);
    const onClick = (event) => {
        let element = getElementsAtEvent(chartRef.current, event);
        props.onChartClick(element[0].index);
    }

    return (
        <Line ref={chartRef} options={options} data={data} onClick={onClick} />
    );
};


export default LineChartComponent;