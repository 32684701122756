
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect, BrowserRouter } from "react-router-dom";

import './assests/fonts/mdi/css/materialdesignicons.min.css'
import './assests/fonts/font-awesome/css/font-awesome.min.css'
import './assests/scss/style.scss'

import jwt from "jsonwebtoken";
import RouterConfig from './config/RouterConfig';
import Header from '../src/component/header/Header'
import Footer from '../src/component/footer/Footer'
import Navigation from './component/navigation/Navigation'
import Form from '../src/component/form/form'
import UserContext from './config/UserContext'
import ScrollToTop from './constants/scrollToTop'

import Login from "../src/component/login/Login"

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} ></Route>
        <Route exact path="/form" component={Form} ></Route>
        <Route exact path="login" component={Login} ></Route>
        <Route exact path="/" component={AfterLogin} ></Route>
        <Route exact path="" component={AfterLogin} ></Route>
      </Switch>
    </BrowserRouter>
  );
}


export const AfterLogin = () => {

  const isAuthed = JSON.parse(localStorage.getItem("appState"));
  const [userData, setisAuthed] = useState([]);
  const [LoggedInUser, setLoggedInUser] = useState([]);
  
  useEffect(() => {
    // console.log(isAuthed);
    // console.log(jwt.decode(isAuthed.session_token));
    if (isAuthed)
      setisAuthed(jwt.decode(isAuthed.session_token));
  }, []);

  return isAuthed == null ? (
    <Redirect to="/login" />
  ) :
    (
      <Router>
        <ScrollToTop />
        <UserContext.Provider value={userData}>
          <div className="container-scroller">
            <Navigation />
            <div className="container-fluid page-body-wrapper">
              <Header />
              <div className="main-panel">
                <RouterConfig></RouterConfig>
                <Footer />
              </div>
            </div>
          </div>
        </UserContext.Provider>
      </Router>
    )

}

export default App;
