import { moment } from '../config/react'
export const GetPaymentMethod = (str) => {
    if (str == "bank_transfer")
        return "Bank Transfer";
    else if (str == "credit_card")
        return "Credit Card"
    else if (str == "paypal")
        return "Paypal"
    else if (str == "wallet")
        return "Wallet"
    else
        return str
}
export const GetJSON = (str) => {
    if (str == undefined || str == "")
        return null;
    else if (typeof str == "string")
        return JSON.parse(str)
    else
        return str
}
export const getCurrencies = () => {
    return { USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: '$', AUD: '$', CHF: '₣', BHD: 'د.ب', INR: '₹' };
}
export const validatePermission = (_key) => {
    //return UserPermissions.map(x=>x.name).includes(Permissions.filter(x=>x.key==key)[0].name)
    const Permissions = JSON.parse(localStorage.getItem('PermissionList'));
    const UserPermissions = JSON.parse(localStorage.appState).permission == null ? [] : JSON.parse(localStorage.appState).permission;
    if ((UserPermissions == null || UserPermissions.length == 0) || (Permissions == null || Permissions.length == 0)) {
        localStorage.removeItem('appState');
        window.location.href = "/login";
    }
    if (UserPermissions.map(x => x.name).includes(Permissions.filter(x => x.key == 'all-master').length > 0 ? Permissions.filter(x => x.key == 'all-master')[0].name : null)) {
        return true;
    }
    return UserPermissions.map(x => x.name).includes(Permissions.filter(x => x.key == _key).length > 0 ? Permissions.filter(x => x.key == _key)[0].name : null)
};
export const GetDateTimeFormat = (str, format) => {
    // return moment(str,'DD.MM.YYYY hh:mm').zone(0).format('DD/MM/YYYY HH:MM');
    return moment(str).format(format);
};