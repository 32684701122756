import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';
const AddProductSize = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Category, setCategory] = useState([]);
    const [Country, setCountry] = useState([]);
    const [Inputs, setInputs] = useState({
        id: 0,
        size: "",
        status: 0,
        category_id: "",
        country_id: "",
        CategoryName: ""
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddProductSize
        GetParentCatgoryList()
        GetCountryList()
        if (urlParams.id != null) {
            document.title = Title.EditProductSize
            GetProductSizeDetail()

        }
    }, [urlParams.id])

    const GetParentCatgoryList = (e) => {
        HttpService.getApi(API.GetParentCategoryList)
            .then(response => {
                setCategory(response.data.result[0])
            });
    }

    const GetCountryList = (e) => {
        HttpService.getApi(API.GetCountryList)
            .then(response => {
                setCountry(response.data.result)
            });
    }

    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
            };

    const GetProductSizeDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetProductSize, param)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    //var data = response.data.result
                    // data.map(item => (
                    //     Object.keys(item).map(function (key, value) {
                    //         //console.log(item[key])
                    //         Inputs[key] = item[key];
                    //     })
                    // ))
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "size": Inputs.size,
            "status": Inputs.status,
            "category_id": Inputs.category_id,
            "country_id": Inputs.country_id
        }
        console.log(param)
        HttpService.postApi(API.SetProductSize, param)
            .then(response => {
                console.log(response);
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/system/productsize')
            });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddProductSize : Title.EditProductSize} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Product Size</label>
                                            <input type="text" className="form-control" name="size" min="1" maxLength="6"
                                                onChange={handleInputChanges("size")}
                                                onKeyUp={handleInputChanges("size")}
                                                onBlur={() => validator.current.showMessageFor("size")}
                                                defaultValue={Inputs.size} placeholder="Product Size" />
                                            {validator.current.message('size', Inputs.size, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Category Name</label>
                                            <select className="form-control" name="category_id" value={Inputs.category_id} onChange={handleInputChanges("category_id")}>
                                                <option value="">--Select--</option>
                                                {
                                                    Category.map(item => (
                                                        <option key={item.id} value={item.id} >{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {validator.current.message('Category Name', Inputs.category_id, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <select className="form-control" name="country_id" value={Inputs.country_id} onChange={handleInputChanges("country_id")}>
                                                <option value="">--Select--</option>
                                                {
                                                    Country.map(item => (
                                                        <option key={item.id} value={item.id} >{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {validator.current.message('Country', Inputs.country_id, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/system/productsize" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProductSize;