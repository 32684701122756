import React from 'react';
import { S3_BUCKET_URL } from '../config/HttpService';

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

const Spinner = () => {
    // let [loading, setLoading] = useState(true);
    // let [color, setColor] = useState('red');

    return (
        <>
            <div className="spinner-loader">
                {/* <ClipLoader color={color} loading={loading} css={css} size={50} /> */ }
                <img src={S3_BUCKET_URL + 'assests/images/loader.svg'} alt="loader" />
            </div>
        </>
    );
};

export default Spinner;