
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, DropdownTreeSelect, FileUploadWithPreview, getBase64 } from '../../config/react'


const AddService = () => {
    const [ServiceImage, setServiceImage] = useState('')
    const urlParams = useParams();
    const [isForceUpdate, setForceUpdate] = useState(false)
    const history = useHistory();
    const validator = useRef(new SimpleReactValidator())
    const [IsShowImageRemoveLink, setIsShowImageRemoveLink] = useState(false)
    useEffect(() => {
        document.title = Title.AddService
        if (urlParams.id != null) {
            document.title = Title.EditService
            GetServiceDetail();
        }
        //GetBanners()
        initImagePlugin()
        
    }, [urlParams.id])
    const [Inputs, setInputs] = useState({
        id: 0,
        title: "",
        description: "",
        image: "",
        service_type: 0,
        status: 0
    });
    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status")
            Inputs[field] = e.target.checked;
        else
            Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }
    window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
        getBase64(e.detail.cachedFileArray[0], (result) => {
            setServiceImage(result)
            setIsShowImageRemoveLink(true)
        });
    });
    var _images = '';
    const initImagePlugin = (image = null) => {
        if (image != '' && image != null) {
            console.log(image);
            _images = new FileUploadWithPreview("_images", {
                text: { 
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            _images = new FileUploadWithPreview("_images", {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }
    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var param = {
            "id": Inputs.id,
            "title": Inputs.title,
            "image": ServiceImage,
            "description": Inputs.description,
            "service_type":Inputs.service_type,
            "status": Inputs.status ?? 0
        }
        console.log(param);
        HttpService.postApi(API.AddService, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/services')
            });
    }
    const GetServiceDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetService, param)
            .then(response => {
                if (response.data.result) {
                    var data = response.data.result[0];
                    var service = data.filter(x => x.id == urlParams.id)[0];
                    //console.log(service);
                    setInputs(service)
                   
                    if (service.image.includes(".")) {
                        initImagePlugin(service.image)
                    }
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    return (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddService : Title.EditService} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" name="title" onChange={handleInputChanges} value={Inputs.title} placeholder="Title" />
                                            {validator.current.message('title', Inputs.title, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Service Type</label>
                                            <select className="form-control" name="service_type" onChange={handleInputChanges} value={Inputs.service_type}>
                                                <option value="0">Select</option>
                                                <option value="1">In Store</option>
                                                <option value="2">Virtual</option>
                                            </select>
                                            {validator.current.message('Service Type', Inputs.service_type, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control" name="description" onChange={handleInputChanges} value={Inputs.description} placeholder="Description" rows="2"></textarea>
                                            {validator.current.message('Description', Inputs.description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="custom-file-container" data-upload-id="_images">
                                            <label>Image (only .png, .jpg, .jpeg formats allowed) Size 1920 X 782
                                            </label>
                                            <label className="custom-file-container__custom-file">
                                                <input type="file" className="custom-file-container__custom-file__custom-file-input" accept=".png,.jpg,.jpeg,.svg" aria-label="Choose Image" />
                                                <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                                <span className="custom-file-container__custom-file__custom-file-control"></span>
                                            </label>
                                            <div className="btn-remove-image">
                                                <a href={void (0)} className="custom-file-container__image-clear" title="Remove Image" style={IsShowImageRemoveLink ? {} : { display: 'none' }}>&times;</a>
                                                <div className="custom-file-container__image-preview">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/services" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddService;