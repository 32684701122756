import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, Permission_keys, Common, swal, Title, toast, MaterialTable, Messages, DateFormat } from '../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../Spinner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Products = () => {
    const urlParams = useParams();
    const [ProductList, setProductList] = useState([])
    const [IsAuth, setIsAuth] = useState(false)
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [ShowFilters, setShowFilters] = useState(false);
    const [totalRows, settotalRows] = useState(0)
    const [selectedProducts, setselectedProducts] = useState([])
    const [IsSelectAll, setIsSelectAll] = useState(false);
    const [SelectedProductCount, setSelectedProductCount] = useState(0);
    const path = useLocation();
    const history = useHistory();

    const columns = [
        {
            name: 'SKU',
            selector: row => row.sku,
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Metal Weight',
            selector: row => row.weight,
        },

        {
            name: 'Price',
            selector: row => row.price,
        },
        {
            name: 'Qty',
            selector: row => row.qty,
        },
        {
            name: 'Is Sold',
            selector: row => row.is_sold,
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    {/* <Link to={"/products/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link> */}
                    <Link onClick={e => DeleteProducts(e, row.parent_product_id != null ? row.parent_product_id : row.id, row.sku)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        name: "",
        sku: "",
        price_To: 0,
        price_From: 0,
        page: 1,
        page_size: 20
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const price_To = useRef(null)
    const price_From = useRef(null)
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.Products
        GetProductsList()
    }, [CustomFilters, path])



    const GetProductsList = () => {
        var productData = {
            "sku": CustomFilters['sku'],
            "name": CustomFilters['name'],
            "price_From": (CustomFilters['price_From'] != null && Number(CustomFilters['price_From']) > 0) ? Number(CustomFilters['price_From']) : 1,
            "price_To": (CustomFilters['price_To'] != null) ? Number(CustomFilters['price_To']) : '-',
            "page": CustomFilters.page,
            "page_size": CustomFilters.page_size,
            "category": urlParams.category
        }
        // HttpService.postApi(API.GetProductsList, productData)
        HttpService.postApi(API.GetProductsAdminList, productData)
            .then(response => {
                if (response.data.status == true) {
                    // var data = response.data.result;
                    var data = response.data.result[1];
                    // console.log(data);
                    setProductList([])
                    setProductList(data)
                    setForceUpdate(!isForceUpdate)
                    settotalRows(response.data.result[0][0].total_row)
                    setSelectedProductCount(0);
                }
            })
    }

    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const DeleteProducts = async (e, id, sku) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                PostDeleteProduct(id, sku)
            }
        });
    }

    const DeleteSelectedProducts = async (e) => {
        e.preventDefault()
        if ((selectedProducts == undefined || selectedProducts.length == 0) && !IsSelectAll)
            toast.warning(Messages.Select_Atlest_One_Item)
        else {
            swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
                if (isConfirm) {
                    let ids = [], skus = [];
                    selectedProducts.forEach(arr => ids.push(arr.parent_product_id != null ? arr.parent_product_id : arr.id));
                    selectedProducts.forEach(arr => skus.push(arr.sku));
                    PostDeleteProduct(ids.join(","), skus.join(","))
                }
            });
        }
    }

    const PostDeleteProduct = (id, skus) => {

        var param = {
            "id": id,
            "IsDeleted": true,
            "Userid": user.id,
            "skus": skus,
            "is_delete_all": IsSelectAll === true ? 1 : 0,
            "delete_product_category": "ring"
        }

        HttpService.postApi(API.DeleteProducts, param)
            .then(response => {
                toast.success(Messages.Record_Deleted)
                GetProductsList()
                setForceUpdate(!isForceUpdate)
            });
    }

    const handleInputChanges = (prop) => (e) => {
        CustomFilters[prop] = e.target.value;
        setForceUpdate(!isForceUpdate);
    };

    const ClearFilter = (e) => {
        // e.preventDefault()
        setCustomFilters(DefaultFilters);
        setForceUpdate(!isForceUpdate);
        price_To.current.value = ''
        price_From.current.value = ''
        GetProductsList();
    }

    const openFilterBox = () => {
        if (ShowFilters) {
            setShowFilters(false);
        }
        else {
            setShowFilters(true);
        }
    }

    const handleChange = (state) => {
        setselectedProducts(state.selectedRows);
        setSelectedProductCount(state.selectedRows.length)
    };
    const SelectAll = (e) => {
        e.preventDefault();
        if (!IsSelectAll) {
            setSelectedProductCount(totalRows);
        }
        else {
            setSelectedProductCount(0);
        }

        setIsSelectAll(!IsSelectAll);
    }
    const SelectAllCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
        <div className="form-check">
            <input
                htmlFor="select-all"
                type="checkbox"
                className="form-check-input"
                ref={ref}
                onClick={onClick}
                {...rest}
            />
        </div>
    ));
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <h3 className="page-title mb-3"> {Title.Products} </h3>
            <div className="page-header mb-2">
                <div className="btn-group">
                    <Link onClick={DeleteSelectedProducts} className="btn btn-dark btn-fw rounded mr-2"> {Title.DeleteSelected} </Link>
                    {
                        SelectedProductCount > 0 &&
                        <>
                            <span className='m-2'>{SelectedProductCount} products are selected</span>
                            <button className='btn btn-primary btn-fw rounded mr-2' onClick={SelectAll}>{!IsSelectAll ? 'Select all ' + totalRows : 'Clear Selection'}</button>
                        </>
                    }
                </div>
                <div className="btn-group ml-auto">
                    <Link to="#" onClick={openFilterBox} className="btn btn-primary btn-fw rounded filter-btn mr-2" > Filters </Link>
                </div>

                <div className="btn-group">
                    <Link to="/products/importexcel" className="btn btn-dark btn-fw rounded mr-2"> {Title.ImportExcel} </Link>
                    <Link to="/generatecsv.php" target="_blank" className="btn btn-dark btn-fw rounded mr-2"> {Title.GenerateCSV} </Link>
                </div>
            </div>

            <div className={ShowFilters ? "filter-bar d-block" : "filter-bar"} id="filter-bar">
                <div className="grid-margin w-100 stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                {/* <div className="active-filter-box mb-4">
                                    <div className={CustomFilters ? "row justify-content-between" : "row justify-content-between"} >
                                        <div className="col-md-10">
                                            <div className="filter-result-list">
                                                <span className="filter-result-repeat-title">Active Filter : </span>
                                                <span className="filter-result-repeat"><a>SKU : {CustomFilters.sku} <button className="btn-close"></button></a></span>
                                                <span className="filter-result-repeat"><a>Name : {CustomFilters.name}  <button className="btn-close"></button></a></span>
                                                <span className="filter-result-repeat"><a>Price : ${CustomFilters.price_From}-${CustomFilters.price_To} <button className="btn-close"></button></a></span>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-right">
                                            <Link to="#" className="rounded mr-2 font-13"> Clear All </Link>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div> */}
                                <div className="row">
                                    {/* <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>ID</strong></label>
                                            <div className="form-group mb-2 row align-items-center">
                                                <label className="col-md-4">From</label>
                                                <div className="col-md-8">
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group mb-2 row align-items-center">
                                                <label className="col-md-4">To</label>
                                                <div className="col-md-8">
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Price</strong></label>
                                            <div className="form-group mb-2 row align-items-center">
                                                <label className="col-md-4">From</label>
                                                <div className="col-md-8">
                                                    <input type="number" className="form-control"  placeholder="From" onChange={handleInputChanges('price_From')} />
                                                </div>
                                            </div>
                                            <div className="form-group mb-2 row align-items-center">
                                                <label className="col-md-4">To</label>
                                                <div className="col-md-8">
                                                    <input type="number" className="form-control" placeholder="To" onChange={handleInputChanges('price_To')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>SKU</strong></label>
                                            <input type="text" className="form-control" onChange={handleInputChanges('sku')} value={CustomFilters.sku} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Name</strong></label>
                                            <input type="text" className="form-control" onChange={handleInputChanges('name')} value={CustomFilters.name} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Price</strong></label>
                                            <div className="form-group mb-2 row align-items-center">
                                                <div className="col-md-6 pr-md-2">
                                                    <input type="number" ref={price_From} className="form-control" placeholder="From" onChange={handleInputChanges('price_From')} />
                                                </div>
                                                <div className="col-md-6 pl-md-2">
                                                    <input type="number" className="form-control" placeholder="To" onChange={handleInputChanges('price_To')} ref={price_To} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <Link to="#" onClick={e=>ClearFilter()} className="rounded mr-2 font-13"> Clear All </Link>
                                        </div>
                                    </div> */}


                                    {/* <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Type</strong></label>
                                            <select id="inputState" className="form-control">
                                                <option selected>Choose...</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Attribute Set</strong></label>
                                            <select id="inputState" className="form-control">
                                                <option selected>Choose...</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Status</strong></label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div> */}
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-12 pr-2 text-right">
                                        <div className="btn-group ml-auto">
                                            <Link to="#" className="btn btn-primary btn-fw rounded mr-2" onClick={e => ClearFilter(e)}> Clear All </Link>
                                            {/* <span className="filter-result-repeat"><button onClick={e=>ClearFilter()} className="btn btn-primary btn-fw rounded mr-2">Clear All</button></span> */}
                                        </div>
                                        {/* <div className="btn-group ml-auto">
                                            <span className="filter-result-repeat"><a><button className="btn btn-primary btn-fw rounded mr-2">Cancel</button></a></span>
                                        </div> */}
                                        <div className="btn-group">
                                            <Link to="#" className="btn btn-dark btn-fw rounded mr-2" onClick={e => GetProductsList(e)}> Apply Filter </Link>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">

                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={ProductList}
                                    selectableRows
                                    pagination
                                    paginationServer
                                    paginationPerPage={20}
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    onSelectedRowsChange={handleChange}
                                    selectableRowsComponent={SelectAllCheckbox}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;