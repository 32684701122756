//User
export const ValidateUser = "user/validateuser"
export const ValidateToken = "user/validateToken"

//Metal
export const GetMetal = "metal/GetMetal"
export const GetMetalAdminList = "metal/GetMetalAdminList"
export const UpdateMetalDetail = "metal/UpdateMetal"

//Metal Quality
export const GetMetalQuality = "metal/GetMetalQuality"
export const GetMetalQualityAdminList = "metal/GetMetalQualityAdminList"
// export const GetMetalQualityDetail = "metal/GetMetalQualityDetail"
export const SetMetalQuality = "metal/SetMetalQuality"
// export const UpdateMetalQuality = "metal/UpdateMetalQuality"
export const DeleteMetalQuality = "metal/DeleteMetalQuality"


//Stone shape
export const GetDiamondShape = "stoneShape/GetStoneShape"
export const GetStoneShapeAdminList = "stoneShape/GetStoneShapeAdminList"
export const SetDiamondShape = "stoneShape/SetStoneShape"
export const DeleteDiamondShape = "stoneShape/DeleteStoneShape"

// Diamond Markup
export const GetDiamondMarkup = "diamondMarkup/GetDiamondMarkup"
export const GetDiamondMarkupAdmin = "diamondMarkup/GetDiamondMarkupAdmin"
export const SetDiamondMarkup = "diamondMarkup/SetDiamondMarkup"
export const DeleteDiamondMarkup = "diamondMarkup/DeleteDiamondMarkup"
export const GetShapeCategory = "diamondMarkup/GetShapeCategory"

//Stone quality
export const GetDiamondQuality = "stoneQuality/GetStoneQuality"
export const GetStoneQualityAdminList = "stoneQuality/GetStoneQualityAdminList"

//export const GetDiamondQualityDetail = "stoneQuality/GetStoneQualityDetail"
export const SetDiamondQuality = "stoneQuality/SetStoneQuality"
//export const UpdateDiamondQuality = "stoneQuality/UpdateStoneQuality"
export const DeleteDiamondQuality = "stoneQuality/DeleteStoneQuality"

//Product Size
export const GetProductSize = "product_size/GetProductSize"
export const GetProductSizeAdminList = "product_size/GetProductSizeAdminList"

//export const GetProductSizeDetail = "product_size/GetProductSizeDetail"
export const SetProductSize = "product_size/SetProductSize"
//export const UpdateProductSize = "product_size/UpdateProductSize"
export const DeleteProductSize = "product_size/DeleteProductSize"

//Category
export const GetParentCategoryList = "category/getParentCategory"
export const GetCategory = "category/getCategory"
export const GetCategoryAdminList = "category/GetCategoryAdminList"
export const GetSubCategories = "category/getSubCategories"
export const SetCategory = "category/SetCategory"
export const UpdateCategory = "category/UpdateCategory"
export const GetCategoryDetail = "category/GetCategoryDetail"
export const DeleteCategory = "category/DeleteCategory"
export const ChangeCategoryAttributeOrder = "category/ChangeCategoryAttributeOrder"

//Content Management
export const GetContentManagementList = "content-management/GetPage"
export const GetContentManagementAdminList = "content-management/GetCmsList"
export const GetContentManagementDetail = "content-management/GetPageDetail"
export const AddContentManagement = "content-management/AddPage"
export const UpdateContentManagement = "content-management/UpdatePage"
export const DeleteContentManagement = "content-management/DeletePage"

//Navigation Menu
export const GetNavigationMenuList = "navigation-menu/GetMenu"
export const GetAdminNavigationMenuList = "navigation-menu/GetAdminNavigationMenuList"
export const AddNavigationMenuItem = "navigation-menu/AddMenu"
export const DeleteNavigationMenuItem = "navigation-menu/DeleteMenu"
export const GetNavigationMenuDetail = "navigation-menu/GetMenuDetail"
export const UpdateNavigationMenu = "navigation-menu/UpdateMenu"
export const UpdateNavigationMenuOrder = "navigation-menu/UpdateMenuOrder"
export const GenerateSitemap = "navigation-menu/GenerateSitemap"
//Customer
export const AddCustomer = "customers/AddCustomer"
export const UpdateCustomer = "customers/UpdateCustomer"
export const GetCustomerList = "customers/GetCustomers"
export const GetCustomerAdminList = "customers/GetCustomersAdminList"
export const DeleteCustomer = "customers/DeleteCustomer"
export const GetCustomerDetail = "customers/GetCustomerDetail"
export const ChangeCustomerStatus = "customers/ChangeCustomerStatus"

//Customer Group
export const SetCustomerGroup = "customer-group/SetGroup"
export const UpdateCustomerGroup = "customer-group/UpdateGroup"
export const DeleteCustomerGroup = "customer-group/DeleteGroup"
export const GetCustomerGroups = "customer-group/GetGroups"
export const GetCustomerGroupsAdminList = "customer-group/GetGroupsAdminList"
export const GetCustomerGroupDetail = "customer-group/GetGroupDetail"
export const GetDefaultGroup = "customer-group/GetDefaultGroup"
export const AddAddress = "customer-address/AddAddress"
export const UpdateAddress = "customer-address/UpdateAddress"
export const GetAddresses = "customer-address/GetAddresses"
export const DeleteAddress = "customer-address/DeleteAddress"

//Country
export const GetCountryList = "country/getCountryList"
export const GetCountryWithCurrencyList = "country/GetCountryWithCurrency"

//State
export const GetStateList = "state/getStateList"

//City
export const GetCityList = "city/getCityList"
//Product Attribute
export const GetProductAttributeList = "attribute/getProductAttributeList"
export const GetProductAttributeAdminList = "attribute/getProductAttributeAdminList"
export const GetProductAttributeDetail = "attribute/GetProductAttributeDetail"
export const AddProductAttribute = "attribute/AddProductAttribute"
export const UpdateProductAttribute = "attribute/UpdateProductAttribute"
export const DeleteProductAttribute = "attribute/DeleteProductAttribute"
export const ChangeAttributeOptionOrder = "attribute/SetAttributeOptionOrder"

//Product Attribute
export const GetProductAttributeSetList = "product_attribute_set/get_attribute_set"
export const GetProductAttributeSetAdminList = "product_attribute_set/get_attribute_set_admin"
export const GetProductAttributeSetDetail = "product_attribute_set/attribute_set_detail"
export const AddProductAttributeSet = "product_attribute_set/add_attribute_set"
export const UpdateProductAttributeSet = "product_attribute_set/update_attribute_set"
export const DeleteProductAttributeSet = "product_attribute_set/remove_attribute_set"

// Products
export const GetProductsList = "product/GetProducts"
export const GetProductsAdminList = "product/GetProductsAdminList"
export const GetProductsDetail = "product/GetProductDetail"
export const AddProducts = "product/AddProduct"
export const UpdateProducts = "product/UpdateProducts"
export const DeleteProducts = "product/DeleteProducts"
export const ImportProducts = "product/ImportProducts"
export const CheckProductImages = "product/CheckProductImages"

export const GetAttributesBySetList = "product/GetAttributesBySetList"
export const GetBannerList = "banner/GetBannerAdminList"
export const SetBanner = "banner/setbanner"
export const DeleteBanner = "banner/deletebanner"
export const GetBanner = "banner/getBanner"


// Products
export const GetOrderLists = "order/getOrders"
export const GetOrderAdminLists = "order/getAdminOrders"
export const GetOrderDetailsList = "order/getOrdersDetails"
export const UpdateOrderStatus = "order/updateOrderStatus"
export const GetOrderStatusList = "order/getOrderStatusList"


//diamond
export const GetDiamondsList = "product/GetDiamonds"
export const GetDiamondDefaultFilters = "diamond/GetDiamondFilters"

//cache
export const getcachekeys = "cache/getcachekeys"
export const removecachebykeys = "cache/remove_cacheby_keys"
export const ResetCache = "cache/resetcache"
export const removeAllCache = "cache/removeAllCache"
export const generatecachekey = "cache/generatecachekey"

//Coupon
export const GetCouponList = "coupons/GetCouponList"
export const GetCouponAdminList = "coupons/GetCouponAdminList"
export const GetCouponDetail = "coupons/GetCouponDetail"
export const AddCoupon = "coupons/AddCoupon"
export const UpdateCoupon = "coupons/UpdateCoupon"
export const DeleteCoupon = "coupons/DeleteCoupon"

//Catalog Price Rule
export const AddCatalogPriceRule = "catalog-price-rule/AddCatalogPriceRule"
export const UpdateCatalogPriceRule = "catalog-price-rule/UpdateCatalogPriceRule"
export const DeleteCatalogPriceRule = "catalog-price-rule/DeleteCatalogPriceRule"
export const GetCatalogPriceRuleList = "catalog-price-rule/GetCatalogPriceRuleList"
export const GetCatalogPriceRuleDetail = "catalog-price-rule/GetCatalogPriceRuleDetail"

// Newsletter
export const GetNewsletterList = "newsletter/GetNewsletterList"
export const GetSubscribersList = "newsletter/GetSubscriberList"
export const DeactivateSubscriber = "newsletter/DeactivateSubscriber"
export const PostNewsletter = "newsletter/PostNewsletter"
export const CancelNewsletter = "newsletter/CancelNewsletter"

// Contact Forms
export const GetContactFormList = "contact-form/GetContactFormList"
export const GetContactFormAdminList = "contact-form/GetContactFormAdminList"
export const SendContactResponse = "contact-form/SendContactResponse"

//Marketing
export const GetReviewsList = "product/GetReviews"
export const GetReview = "product/GetReview"
export const UpdateReview = "product/UpdateReview"

//Setting
export const GetShortLivedToken = "https://api.instagram.com/oauth/access_token"
export const GetLongLivedToken = "https://graph.instagram.com/access_token"
export const UpdateInstagramToken = "setting/StoreInstagramToken"
export const GetInstagramToken = "setting/GetInstagramToken"
export const GetInstagramOptions = "setting/GetInstagramOptions"
export const InstagramAuth = "https://api.instagram.com/oauth/authorize"
export const GetSettingOptions = "setting/GetSettingOptions"
export const GetSettingAdminOptions = "setting/GetSettingAdminOptions"
export const UpdateSettingOption = "setting/UpdateSettingOption"
export const GetSettingOptionDetail = "setting/GetSettingOptionDetail"
export const AddSettingOption = "setting/AddSettingOption"
export const DeleteSettingOption = "setting/DeleteSettingOption"

//Dashboard
export const DashboardCounts = "adminPenal/getAdmindashboard"
export const BestSellerProduct = "adminPenal/BestSellerProduct"
export const lastorderProducts = "adminPenal/lastorderProducts"

//Services
export const GetService = "services/get_service"
export const GetServiceAdminList = "services/get_service_admin_list"
export const AddService = "services/set_service"
export const DeleteService = "services/delete_service"

export const GetAppointment = "services/get_appointment"
export const setAppointment = "services/set_appointment"
export const DeleteAppointment = "services/set_appointment"
export const Getservices = "services/get_service"
export const GetAppointmentReport = "services/get_appointment_report"

export const GetVendorDiamondCount = "diamond/VendorDiamondCount"
export const GetNivodaDiamondFeeds = 'diamond/GetNivodaDiamondFeeds'
export const SetSoldDiamond = "diamond/SetSoldDiamond"
export const SetSoldDiamondBySKU = "diamond/SetSoldDiamondBySKU"
export const DeleteSoldDiamonds = "diamond/DeleteSoldDiamonds"
export const DeleteDiamondFromFile = "diamond/DeleteDiamondFromFile"

export const GetServiceLogs = "services/service_logs"
export const StartService = "services/start_service"
export const StopService = "services/stop_service"
export const GetImportCounts = "services/import_counts"


//Blog Category
export const SetBlogCategory = "blog/SetBlogCategory"
export const GetBlogCategory = "blog/getBlogCategory"
export const GetBlogCategoryById = "blog/GetBlogCategoryById"
export const DeleteBlogCategory = "blog/DeleteBlogCategory"

//Blogs
export const SetBlog = "blog/SetBlog"
export const GetBlogs = "blog/getBlogs"
export const GetBlogById = "blog/GetBlogById"
export const DeleteBlog = "blog/DeleteBlog"

//Report
export const GetLinkStatistics = "report/GetLinkStatistics"
export const GetLinkStatisticsPeriod = "report/GetLinkStatisticsPeriod"
export const GetLinkStatisticsByDay = "report/GetLinkStatisticsByDay"
export const GetQuickLinksByDay = "report/GetQuickLinksByDay"
export const GetUserConsentByDay = "report/GetUserConsentByDay"
export const GetSearchTerms = "product/GetSearchKeyword"
export const GetAnalysisData = "report/getdataanalysis"

//User Management
export const GetRoles = "role_permission/getRoleList"
export const DeleteRole = "role_permission/deleteRole"
export const GetRoleByID = "role_permission/getRolebyID"
export const SetRole = "role_permission/setrole"
export const GetUser = "users/getUser"
export const SetUser = "users/SetUser"
export const GetUsers = "users/GetUsers"
export const DeleteUser = "users/DeleteUser"
export const GetAppointmentUsers = "users/GetAppointmentUsers"
export const GetPermissionByRole = "role_permission/getPermissionByRole"

export const GetPermission = "role_permission/getPermissionList"
export const DeletePermission = "role_permission/deletePermission"
export const GetPermissionByID = "role_permission/getPermissionbyID"
export const SetPermission = "role_permission/setPermission"
//Virtual Ring Builder
export const GetVirtualRingRequestList = "virtual-ring-builder/GetVirtualRingRequestList"
