import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, SimpleReactValidator, API, toast, Title, Messages, FileUploadWithPreview, DropdownTreeSelect, getBase64, Common, Permission_keys } from '../../config/react'
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import Spinner from '../Spinner';
import { ReactSVG } from 'react-svg'
const AddBlog = () => {
    const urlParams = useParams()
    const inputFile = useRef()
    const clearFileRef = useRef()
    const history = useHistory()
    const [IsAuth, setIsAuth] = useState(false)
    let submitbtnRef = useRef();
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [IsShowImageRemoveLink, setIsShowImageRemoveLink] = useState(false)
    const [BlogImage, setBlogImage] = useState('')
    const validator = useRef(new SimpleReactValidator())
    const [svgUrl, setsvgUrl] = useState('');
    const [Content, setContent] = useState();
    const [MultipleBlogCatId, setMultipleBlogCatId] = useState();
    const [Categories, setCategories] = useState([])
    const [Inputs, setInputs] = useState({
        id: 0,
        title: "",
        description: "",
        short_description: "",
        slug: "",
        image: "",
        meta_title: "",
        meta_description: "",
        status: 0,
        blog_category_id: ""
    });
    const clearInputs = {
        id: 0,
        title: "",
        description: "",
        short_description: "",
        link: "",
        category_id: 0,
        page_id: 0,
        image: "",
        css_class: "",
        parent_id: 0,
        sort_order: "",
        menu_column: 0,
        parameters: null,
        status: 0
    }
    var blog_images = '';
    var SelectedBlogCatId = '';
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.blogs)) {
            history.push("/");
            return
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        if (urlParams.id != undefined)
            GetBlogById()
        else
            GetBlogCategoryList()

        initImagePlugin();
    }, [urlParams.id])
    const GetBlogById = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetBlogById, param)
            .then(response => {
                if (response.data.result) {
                    setInputs(response.data.result[0][0]);
                    setContent(response.data.result[0][0].content);
                    SelectedBlogCatId = response.data.result[0][0].blog_category_id;
                    setMultipleBlogCatId(SelectedBlogCatId);
                    GetBlogCategoryList();
                    setForceUpdate(!isForceUpdate);
                }
            });
    }
    const initImagePlugin = (image = null) => {
        if (image != '' && image != null) {
            blog_images = new FileUploadWithPreview("blog_images", {
                text: {
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            blog_images = new FileUploadWithPreview("blog_images", {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }
    window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
        getBase64(e.detail.cachedFileArray[0], (result) => {
            setsvgUrl(result)
            setBlogImage(result)
        });
        setIsShowImageRemoveLink(true)
        updateCategoryDropdown()
    });

    window.addEventListener("fileUploadWithPreview:clearButtonClicked", function (e) {
        setBlogImage('')
        setIsShowImageRemoveLink(false)
        updateCategoryDropdown()
    });
    const clearForm = e => {
        setInputs(clearInputs)
        setsvgUrl()
        clearFileRef.current.click();
        setForceUpdate(!isForceUpdate);
    }
    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
                updateCategoryDropdown()
            };
    const updateCategoryDropdown = () => {
        if (urlParams.id != undefined) {
            if (SelectedBlogCatId != '') {
                var data = SelectedBlogCatId.split(",");
                Categories.map((item, index) => {
                    var checked = data.filter(x => x == item.value).length > 0
                    Categories[index].checked = checked;
                });
            }
            else {
                var data = Inputs['blog_category_id'].split(",");
                Categories.map((item, index) => {
                    var checked = data.filter(x => x == item.value).length > 0
                    Categories[index].checked = checked;
                });
            }
        }
        else {
            Categories.map((item, index) => {
                if (item.value == Inputs['blog_category_id']) {
                    Categories[index].checked = true;
                }
                else {
                    Categories[index].checked = false;
                }
            });
        }

    }
    const handleEditorChange = (e) => {
        setContent(e)
        setForceUpdate(!isForceUpdate)
    }
    const onChangeCategory = (currentNode, selectedNodes) => {
        var selectCatId = '';
        if (selectedNodes.length > 0) {
            selectCatId = selectedNodes.map(x => x.value).join(",")
        }
        Inputs['blog_category_id'] = selectCatId
        SelectedBlogCatId = selectCatId;
    };
    const GetBlogCategoryList = (selectedCategory = null) => {
        var blogcategoryData = {
            "_id": null,
            "page": 0,
            "search": null,
            "page_size": 0
        }
        HttpService.postApi(API.GetBlogCategory, blogcategoryData)
            .then(response => {
                if (response.data.result) {
                    var blogCategories = response.data.result[1].filter(x => x.status)
                    var categories = [];
                    if (urlParams.id != undefined) {
                        var data = SelectedBlogCatId.split(",");
                        blogCategories.map((item) => {
                            var checked = data.filter(x => x == item.id).length > 0
                            categories.push({
                                label: item.name,
                                value: item.id,
                                children: [],
                                checked: checked
                            })
                        });
                    }
                    else {
                        blogCategories.map((item) => {
                            categories.push({
                                label: item.name,
                                value: item.id,
                                children: [],
                                checked: false
                            })
                        });
                    }
                    setCategories(categories)
                }
            });
    }
    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");

        var param = {
            "id": Inputs.id,
            "title": Inputs.title,
            "content": Content,
            "description": Inputs.description,
            "short_description": Inputs.short_description,
            "slug": Inputs.slug,
            "image": BlogImage,
            "prev_image": Inputs.image,
            "status": Inputs.status,
            "blog_category_id": Inputs.blog_category_id,
            "meta_title": Inputs.meta_title,
            "meta_description": Inputs.meta_description,
        }
        HttpService.postApi(API.SetBlog, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                toast.success(Messages.Record_Added)
                setForceUpdate(!isForceUpdate);
                history.push('/blog/blogs')
            });
    }
    return (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddBlog : Title.EditBlog} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                            }} className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Page Title</label>
                                            <input type="text" className="form-control" name="title" min="2" maxLength="255"
                                                onChange={handleInputChanges("title")}
                                                onKeyUp={handleInputChanges("title")}
                                                onBlur={() => validator.current.showMessageFor("Page Title")}
                                                defaultValue={Inputs.title} placeholder="Page Title" />
                                            {validator.current.message('Page Title', Inputs.title, 'required|max:60', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status"
                                                        onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control" name="description" onChange={handleInputChanges("description")} onKeyUp={handleInputChanges("description")} onBlur={() => validator.current.showMessageFor("Description")} defaultValue={Inputs.description} placeholder="Description"></textarea>
                                            {validator.current.message('Description', Inputs.description, 'required', { className: 'error' })}
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Slug (URL)</label>
                                            <input type="text" className="form-control" name="slug" min="1" maxLength="50"
                                                onChange={handleInputChanges("slug")}
                                                onKeyUp={handleInputChanges("slug")}
                                                onBlur={() => validator.current.showMessageFor("Slug")}
                                                defaultValue={Inputs.slug} placeholder="Slug" />
                                            {validator.current.message('Slug', Inputs.slug, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Category</label>
                                            {
                                                Categories.length > 0 &&
                                                <DropdownTreeSelect placeholder="Choose" mode='multiSelect' data={Categories} onChange={onChangeCategory} className="bootstrap-demo w-100" />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Content</label>
                                            <ReactSummernote
                                                name="content"
                                                value={Content}
                                                options={{
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ["style", ["style"]],
                                                        ["font", ["bold", "underline", "clear", 'strikethrough', 'superscript', 'subscript']],
                                                        ["fontname", ["fontname"]],
                                                        ["para", ["ul", "ol", "paragraph"]],
                                                        ["table", ["table"]],
                                                        ["insert", ["link", "picture", "video"]],
                                                        ["view", ["fullscreen", "codeview"]],
                                                        ['height', ['height']]
                                                    ]
                                                }}
                                                onChange={e => { handleEditorChange(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Short Description</label>
                                            <textarea className="form-control" name="short_description" onChange={handleInputChanges("short_description")} onKeyUp={handleInputChanges("short_description")} onBlur={() => validator.current.showMessageFor("Short Description")} defaultValue={Inputs.short_description} placeholder="Short Description"></textarea>
                                            {validator.current.message('Short Description', Inputs.short_description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Meta Title</label>
                                            <input type="text" className="form-control" name="meta_title" min="1" maxLength="255"
                                                onChange={handleInputChanges("meta_title")}
                                                onKeyUp={handleInputChanges("meta_title")}
                                                onBlur={() => validator.current.showMessageFor("Meta Title")}
                                                defaultValue={Inputs.meta_title} placeholder="Meta Title" />
                                            {validator.current.message('Meta Title', Inputs.meta_title, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="custom-file-container mb-5" data-upload-id="blog_images">
                                            <label>Image</label>
                                            <label className="custom-file-container__custom-file h-auto">
                                                <input type="file" className="custom-file-container__custom-file__custom-file-input" accept=".png, .jpg, .jpeg" aria-label="Choose Image" />
                                                <img src={Inputs.image} width="300" className='my-5' />
                                                <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                                <span className="custom-file-container__custom-file__custom-file-control"></span>
                                            </label>
                                            <div className="btn-remove-image d-none">
                                                <a href={void (0)} className="custom-file-container__image-clear" title="Remove Image" style={IsShowImageRemoveLink ? {} : { display: 'none' }}>&times;</a>
                                                <div className="custom-file-container__image-preview">

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Meta Description</label>
                                            <textarea className="form-control" rows="5" name="meta_description"
                                                onChange={handleInputChanges("meta_description")}
                                                onKeyUp={handleInputChanges("meta_description")}
                                                onBlur={() => validator.current.showMessageFor("Meta Description")}
                                                defaultValue={Inputs.meta_description} placeholder="Meta Description"></textarea>
                                            {validator.current.message('Meta Description', Inputs.meta_description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/blog/blogs" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddBlog;