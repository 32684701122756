import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { HttpService, API, swal, Title, toast, Messages, DateFormat,Common,Permission_keys } from '../../config/react'
import UserContext from '../../config/UserContext'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Spinner from '../Spinner';
const Blogs = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [BlogList, setBlogList] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }
    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search For Name" className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['page']: 1, ['search']: e.target.value })} />
        );
    });
    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Slug',
            selector: row => row.slug,
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,

        },
        {
            name: 'Status',
            selector: row => row.status == 1 ? <label>Active</label> : <label>Inactive</label>,
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/blog/Addblog/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    <Link onClick={e => DeleteBlog(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.blogs)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        GetBlogsList()
    }, [CustomFilters])

    const GetBlogsList = () => {
        var blogData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetBlogs, blogData)
            .then(response => {
                if (response.data.status == true && response.data.result != '') {
                    var data = response.data.result[0];
                    setBlogList(data)
                    setForceUpdate(!isForceUpdate)
                    settotalRows(response.data.result[0][0].total_category)
                }
                else {
                    setBlogList([])
                    settotalRows(0)
                }
            });
    }
    const DeleteBlog = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id,
                    "IsDeleted": true,
                    "deleted_by": user.id
                }
                HttpService.postApi(API.DeleteBlog, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetBlogsList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Blogs} </h3>
                <div className="btn-group">
                    <Link to="/blog/Addblog" className="btn btn-dark btn-fw rounded mr-2"> {Title.AddBlog} </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={BlogList}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Blogs;