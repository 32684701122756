import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams, useHistory } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, Nestable, FileUploadWithPreview, getBase64, swal, DropdownTreeSelect, xmlbuilder, fs, Common, Permission_keys } from '../../../config/react'
import { ReactSVG } from 'react-svg'
import Spinner from '../../Spinner';
const NavigationMenu = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const urlParams = useParams()
    const inputFile = useRef()
    //const clearFileRef = useRef()
    const [defaultCollapsed, setdefaultCollapsed] = useState(true)
    const [Categories, setCategories] = useState([])
    const [DiamondShapeCategories, setDiamondShapeCategories] = useState([])
    const [Pages, setPages] = useState([])
    const [Menus, setMenus] = useState([])
    const [MenuItems, setMenuItems] = useState([])
    const [IsDisabledSubmitButton, setIsDisabledSubmitButton] = useState(true)
    //const [MenuItemSortOrders, setMenuItemSortOrders] = useState([])
    const validator = useRef(new SimpleReactValidator())
    const CustomLinkValidator = useRef(new SimpleReactValidator())
    const [IsShowImageRemoveLink, setIsShowImageRemoveLink] = useState(false)
    const [MenuItemImage, setMenuItemImage] = useState('')
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [svgUrl, setsvgUrl] = useState('');
    const [BlogList, setBlogList] = useState([]);
    var menu_image = ''; var MenuItemSortOrders = [];
    const clearInputs = {
        id: 0,
        title: "",
        description: "",
        menu_type: "",
        link: "",
        category_id: 0,
        page_id: 0,
        // image: "",
        css_class: "",
        parent_id: 0,
        sort_order: "",
        menu_column: 0,
        parameters: null,
        status: 0
    }
    const [Inputs, setInputs] = useState(clearInputs)

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.NavigationMenu

        if (urlParams.id != null) {
            document.title = Title.EditNavigationMenu
            GetNavigationMenuDetail()
        }
        else {
            clearForm()
        }
        GetCategoryList()
        GetPageList()
        GetMenuItems()
        GetBlogs()
        //initImagePlugin()

    }, [urlParams.id])

    // const initImagePlugin = (image = null) => {
    //     if (image != '' && image != null) {
    //         menu_image = new FileUploadWithPreview("menu_image", {
    //             text: {
    //                 chooseFile: 'Choose Image...'
    //             },
    //             presetFiles: [
    //                 image,
    //             ]
    //         });
    //     }
    //     else {
    //         menu_image = new FileUploadWithPreview("menu_image", {
    //             text: {
    //                 chooseFile: 'Choose Image...'
    //             }
    //         });
    //     }
    // }

    const GetNavigationMenuDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetNavigationMenuDetail, param)
            .then(response => {
                if (response.data.result) {
                    var data = response.data.result
                    Object.keys(data[0]).map(function (key, value) {
                        Inputs[key] = data[0][key];
                    })

                    setInputs(data[0])
                    GetCategoryList()
                    //initImagePlugin(data[0].image)
                    // setsvgUrl(data[0].image);
                    setForceUpdate(!isForceUpdate)
                }
            });
    }

    const GetCategoryList = (selectedCategory = null) => {
        HttpService.postApi(API.GetCategory, null)
            .then(response => {
                if (response.data.result) {
                    //filter parent category
                    var parentCategories = response.data.result[0].filter(x => x.parent_id == 1 && x.status)
                    setDiamondShapeCategories(response.data.result[0].filter(x => x.is_diamond_category == 1));
                    // console.log(parentCategories)
                    var categories = [];

                    parentCategories.map((item) => {
                        //filter sub categories by parent
                        var filteredSubcategories = response.data.result[0].filter(x => x.parent_id == item.id && x.status)
                        var subcategories = [];

                        filteredSubcategories.map((subitem) => {
                            //filter sub child categories by parent
                            var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id && x.status)
                            var subchildcategories = [];

                            filteredSubChildCategories.map((subchilditem) => {
                                var filteredChildCategories = response.data.result[0].filter(x => x.parent_id == subchilditem.id && x.status)
                                var childcategories = [];

                                filteredChildCategories.map((childitem) => {
                                    var filteredChildrenCategories = response.data.result[0].filter(x => x.parent_id == childitem.id && x.status)
                                    var childrencategories = [];
                                    filteredChildrenCategories.map((childrenitem) => {
                                        var checked = false;
                                        if ((urlParams.id != null && (Inputs['category_id'] == childrenitem.id)) || selectedCategory == childrenitem.id)
                                            checked = true;
                                        childrencategories.push({
                                            label: childrenitem.name,
                                            value: childrenitem.id,
                                            checked: checked,
                                            //children: subcategories
                                        })
                                    });

                                    var checked = false;
                                    if ((urlParams.id != null && (Inputs['category_id'] == childitem.id)) || selectedCategory == childitem.id)
                                        checked = true;
                                    childcategories.push({
                                        label: childitem.name,
                                        value: childitem.id,
                                        checked: checked,
                                        disabled: true,
                                        children: childrencategories
                                    })
                                });

                                var checked = false;
                                if ((urlParams.id != null && (Inputs['category_id'] == subchilditem.id)) || selectedCategory == subchilditem.id)
                                    checked = true;
                                subchildcategories.push({
                                    label: subchilditem.name,
                                    value: subchilditem.id,
                                    checked: checked,
                                    children: childcategories
                                })
                            })

                            var checked = false;
                            if ((urlParams.id != null && (Inputs['category_id'] == subitem.id)) || selectedCategory == subitem.id)
                                checked = true;
                            subcategories.push({
                                label: subitem.name,
                                value: subitem.id,
                                checked: checked,
                                children: subchildcategories
                            })
                        });
                        var checked = false;
                        if ((urlParams.id != null && (Inputs['category_id'] == item.id)) || selectedCategory == item.id)
                            checked = true;
                        categories.push({
                            label: item.name,
                            value: item.id,
                            children: subcategories,
                            checked: checked
                        })
                    });
                    setCategories(categories)
                }
            });
    }

    const GetMenuItems = () => {
        HttpService.getApi(API.GetAdminNavigationMenuList)
            .then(response => {
                //console.log(response);
                let data = response.data.result[0]
                setMenus(data)
                //filter 1st level menu items
                let parent_menu = data.filter(x => x.parent_id == 0)
                let menu_items = []

                //sort menu on sort_order field
                parent_menu.sort(({ sort_order: previousID }, { sort_order: currentID }) => previousID - currentID).map((item) => {
                    //filter 2nd level menu items
                    let sub_menu = data.filter(x => x.parent_id == item.id)
                    let children = []
                    //sort menu on sort_order field
                    sub_menu.sort(({ sort_order: previousID }, { sort_order: currentID }) => previousID - currentID).map((sub_item) => {

                        //filter 3rd level menu items
                        let child_menu = data.filter(x => x.parent_id == sub_item.id)
                        let sub_children = []
                        child_menu.sort(({ sort_order: previousID }, { sort_order: currentID }) => previousID - currentID).map((sub_child_item) => {

                            //filter 4th level menu items
                            let sub_child_menu = data.filter(x => x.parent_id == sub_child_item.id)
                            let children = []
                            sub_child_menu.sort(({ sort_order: previousID }, { sort_order: currentID }) => previousID - currentID).map((child_item) => {
                                children.push({ id: child_item.id, text: child_item.title, slug: child_item.slug, css_class: child_item.css_class, updated_at: child_item.updated_at, page_update_priority: child_item.page_update_priority })
                            })

                            sub_children.push({ id: sub_child_item.id, text: sub_child_item.title, children: children, image: sub_child_item.image, slug: sub_child_item.slug, updated_at: sub_child_item.updated_at, css_class: sub_child_item.css_class, page_update_priority: sub_child_item.page_update_priority })
                        })
                        children.push({ id: sub_item.id, text: sub_item.title, children: sub_children, image: sub_item.image, slug: sub_item.slug, updated_at: sub_item.updated_at, css_class: sub_item.css_class, page_update_priority: sub_item.page_update_priority })
                    })
                    menu_items.push({ id: item.id, text: item.title, children: children, image: item.image, slug: item.slug, updated_at: item.updated_at, css_class: item.css_class, page_update_priority: item.page_update_priority })
                })

                setMenuItems(menu_items)
            });
    }

    const GetPageList = () => {
        HttpService.getApi(API.GetContentManagementList)
            .then(response => {
                setPages(response.data.result)
            });
    }

    const styles = {
        position: "relative",
        padding: "10px 15px",
        fontSize: "15px",
        border: "1px solid #f9fafa",
        background: "#f9fafa",
        cursor: "pointer"
    };

    const renderItem = (item) => {
        // console.log(item);
        return (
            <div style={styles}>
                {item.handler}
                {item.collapseIcon}
                {/* <img className={item.css_class} src={item.item.image} width="100" /> */}
                <span className={'menu-icon nav-' + item.item.css_class}></span>
                {item.item.text}
                <Link to="#" onClick={e => deleteMenuItem(e, item.item.id)} className="text-dark float-right" title="Delete"><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                <Link to={"/system/navigation-menu/edit/" + item.item.id} className="text-dark float-right mr-2" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
            </div>
        );
    }

    const changeMenu = (menu) => {
        let menu_items = []
        menu.items.map((item, index) => {
            //1st level menu items
            menu_items.push({ id: item.id, parent_id: 0, sort_order: index })

            //2nd level menu items
            if (item.children) {
                item.children.map((sub_item, sub_index) => {
                    menu_items.push({ id: sub_item.id, parent_id: item.id, sort_order: sub_index })

                    //3rd level menu items
                    if (sub_item.children) {
                        sub_item.children.map((sub_child_item, sub_child_index) => {
                            menu_items.push({ id: sub_child_item.id, parent_id: sub_item.id, sort_order: sub_child_index })

                            //4th level menu items
                            if (sub_child_item.children) {
                                sub_child_item.children.map((child_item, child_index) => {
                                    menu_items.push({ id: child_item.id, parent_id: child_item.id, sort_order: child_index })
                                })
                            }
                        })
                    }
                })
            }
        })

        MenuItemSortOrders = menu_items
        setIsDisabledSubmitButton(false)
        //setMenuItemSortOrders(menu_items)
    }

    const deleteMenuItem = (event, menuId) => {
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": menuId
                }
                HttpService.postApi(API.DeleteNavigationMenuItem, param)
                    .then(response => {
                        GetMenuItems()
                    });
            }

        });
    }

    const clearForm = e => {
        setInputs(clearInputs)
        //inputFile.current.value = ''
        // setsvgUrl()
        // clearFileRef.current.click();
        setForceUpdate(!isForceUpdate);
    }

    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status")
            Inputs[field] = e.target.checked;
        else
            Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
        updateCategoryDropdown()
    }

    const AddMenuItem = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            if (Inputs['menu_type'] == "custom_link") {
                ValidateCustomLink()
            }
            setForceUpdate(!isForceUpdate)
            return
        }
        if (Inputs['menu_type'] == "custom_link") {
            ValidateCustomLink()
        }
        var param = {
            "id": Inputs.id,
            "title": Inputs.title,
            "description": Inputs.description,
            "menu_type": Inputs.menu_type,
            "link": Inputs.link,
            "category_id": Inputs.category_id,
            "page_id": Inputs.page_id,
            "image": MenuItemImage,
            "css_class": Inputs.css_class,
            "status": Inputs.status,
            "parent_id": Inputs.parent_id,
            "menu_column": Number(Inputs.menu_column),
            "parameters": Inputs.parameters ?? '',
            "sort_order": Inputs.sort_order
        }
        // console.log(param);
        if (urlParams.id == null) {
            HttpService.postApi(API.AddNavigationMenuItem, param)
                .then(response => {
                    if (response.data.result != null) {
                        let menu_id = response.data.result[0][0].result
                        let menu_items = MenuItems
                        menu_items.push({ id: menu_id, text: Inputs['title'] })
                        setMenuItems(menu_items)
                        GetMenuItems()
                        clearForm()
                        toast.success(Messages.Menu_Added)
                        setForceUpdate(!isForceUpdate)
                        window.scrollTo(0, 0)
                    }
                });
        }
        else {
            HttpService.postApi(API.UpdateNavigationMenu, param)
                .then(response => {
                    if (response != null) {
                        GetMenuItems()
                        toast.success(Messages.Menu_Updated)
                        window.scrollTo(0, 0)
                    }

                });
        }
    }

    const ValidateCustomLink = (e) => {
        if (!CustomLinkValidator.current.allValid()) {
            CustomLinkValidator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
    }

    const SubmitMenu = (e) => {
        var param = {
            'menu_items': MenuItemSortOrders
        }
        HttpService.postApi(API.UpdateNavigationMenuOrder, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                GetMenuItems()
                toast.success(Messages.Menu_Updated)
                //setForceUpdate(!isForceUpdate);
            });
    }
    const GetBlogs = (e) => {
        let param = {
            slug: ''
        }
        HttpService.postApi(API.GetBlogs, param)
            .then(response => {
                if (response.data.status == true && response.data.result != '') {
                    setBlogList(response.data.result[0])
                    setForceUpdate(!isForceUpdate)
                }
                else {
                    setBlogList([])
                }
            });
    }
    const onChangeCategory = (currentNode, selectedNodes) => {
        if (selectedNodes.length > 0) {
            Inputs['category_id'] = selectedNodes[0].value
        }

        //setForceUpdate(!isForceUpdate)
    };

    const updateCategoryDropdown = (e) => {
        Categories.map((item, index) => {
            if (item.value == Inputs['category_id']) {
                Categories[index].checked = true;
            }
            else {
                Categories[index].checked = false;
            }
            if (item.children) {
                item.children.map((subitem, subindex) => {
                    if (subitem.value == Inputs['category_id']) {
                        Categories[index].children[subindex].checked = true;
                    }
                    else {
                        Categories[index].children[subindex].checked = false;
                    }
                })
            }
        });
    }
    // window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
    //     // setsvgUrl(e.detail.cachedFileArray[0])
    //     // var fileName = e.detail.cachedFileArray[0].name
    //     // var fileExtension = ['png','jpg','jpeg','bmp']
    //     // var regex = /(?:\.([^.]+))?$/;
    //     // var ext = regex.exec(fileName)[1];
    //     // console.log(fileExtension.indexOf(ext) > -1)
    //     // if(fileExtension.indexOf(ext) > -1){
    //     //     getBase64(e.detail.cachedFileArray[0], (result) => {
    //     //         setMenuItemImage(result)
    //     //     });
    //     //     setIsShowImageRemoveLink(true)
    //     //     updateCategoryDropdown()
    //     // }
    //     // else{
    //     // }
    //     getBase64(e.detail.cachedFileArray[0], (result) => {
    //         //setsvgUrl(result)
    //         setMenuItemImage(result)
    //         setForceUpdate(!isForceUpdate)
    //     });
    //     setIsShowImageRemoveLink(true)
    //     updateCategoryDropdown()

    // });
    // window.addEventListener("fileUploadWithPreview:clearButtonClicked", function (e) {
    //     setMenuItemImage('')
    //     setIsShowImageRemoveLink(false)
    //     updateCategoryDropdown()
    // });
    const generateSitemap = () => {
        let urls = [];
        let frontendUrl = process.env.REACT_APP_FRONTEND_URL;
        let staticLinks = [{ url: frontendUrl + '/appointment', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/contact-us', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/blogs', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-guide/cut', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-guide/colour', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-guide/clarity', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-guide/carat-weight', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-guide/shapes', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-guide/fancy-colour', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-guide/grading', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/round', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/princess', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/cushion', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/oval', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/emerald', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/heart', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/pear', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/marquise', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/asscher', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/diamond-size-chart/radiant', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/engagement-rings/ring-style', priority: 1.00, lastmod: 'daily' }, { url: frontendUrl + '/metal-guide', priority: 1.00, lastmod: 'daily' }];


        var xml = xmlbuilder.create('urlset');
        xml.att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9');
        xml.att('xmlns:xhtml', 'http://www.w3.org/1999/xhtml');

        xml.ele('url')
            .ele('loc', frontendUrl).up()
            .ele('lastmod', 'daily').up()
            .ele('priority', 1.00).up()
            .end({ pretty: true });
        urls.push(frontendUrl);
        MenuItems.map((item, index) => {
            let pageUpdatePriority = (item.page_update_priority != null && item.page_update_priority != 'null' && item.page_update_priority != '' && !item.slug.includes('?')) ? item.page_update_priority : 0.80;
            let lastMod = pageUpdatePriority == 1 ? 'daily' : item.updated_at;
            if (item.slug != null && !urls.includes(item.slug) && !item.slug.includes('?') && pageUpdatePriority >= 0) {
                xml.ele('url')
                    .ele('loc', frontendUrl + item.slug).up()
                    .ele('lastmod', lastMod).up()
                    .ele('priority', pageUpdatePriority).up()
                    .end({ pretty: true });
                urls.push(item.slug);
            }
            if (item.children.length > 0) {
                item.children.map((subitem, subindex) => {

                    let pageUpdatePriority = (subitem.page_update_priority != null && subitem.page_update_priority != 'null' && subitem.page_update_priority != '' && !subitem.slug.includes('?')) ? subitem.page_update_priority : 0.80;
                    let lastMod = pageUpdatePriority == 1 ? 'daily' : subitem.updated_at;
                    if (subitem.slug != null && !urls.includes(subitem.slug) && !subitem.slug.includes('?') && pageUpdatePriority >= 0) {
                        xml.ele('url')
                            .ele('loc', frontendUrl + subitem.slug).up()
                            .ele('lastmod', lastMod).up()
                            .ele('priority', pageUpdatePriority).up()
                            .end({ pretty: true });
                        urls.push(subitem.slug);
                    }
                    if (subitem.children.length > 0) {
                        subitem.children.map((childitem, childindex) => {
                            let pageUpdatePriority = (childitem.page_update_priority != null && childitem.page_update_priority != 'null' && childitem.page_update_priority != '' && !childitem.slug.includes('?')) ? childitem.page_update_priority : 0.80;
                            let lastMod = pageUpdatePriority == 1 ? 'daily' : childitem.updated_at;
                            if (childitem.slug != null && !urls.includes(childitem.slug) && !childitem.slug.includes('?') && pageUpdatePriority >= 0) {
                                xml.ele('url')
                                    .ele('loc', frontendUrl + childitem.slug).up()
                                    .ele('lastmod', lastMod).up()
                                    .ele('priority', pageUpdatePriority).up()
                                    .end({ pretty: true });
                                urls.push(childitem.slug);
                            }
                        })
                    }
                })
            }
        })

        Pages.map((item, index) => {
            if (!urls.includes(item.slug) && item.slug != '/notfound') {
                let pageUpdatePriority = (item.page_update_priority != null && item.page_update_priority != 'null' && item.page_update_priority != '') ? item.page_update_priority : 0.80;
                let lastMod = pageUpdatePriority == 1 ? 'daily' : item.updated_at;
                if (pageUpdatePriority >= 0)
                    xml.ele('url')
                        .ele('loc', frontendUrl + item.slug).up()
                        .ele('lastmod', lastMod).up()
                        .ele('priority', pageUpdatePriority).up()
                        .end({ pretty: true });
            }
        })
        /* Add custom link */
        DiamondShapeCategories.map((item, index) => {
            // console.log(item.slug);
            if (!urls.includes(item.slug)) {
                let pageUpdatePriority = (item.page_update_priority != null && item.page_update_priority != 'null' && item.page_update_priority != '') ? item.page_update_priority : 0.80;
                let lastMod = pageUpdatePriority == 1 ? 'daily' : (item.updated_at != null ? item.updated_at : item.created_at);
                if (pageUpdatePriority >= 0)
                    xml.ele('url')
                        .ele('loc', frontendUrl + item.slug).up()
                        .ele('lastmod', lastMod).up()
                        .ele('priority', pageUpdatePriority).up()
                        .end({ pretty: true });
            }
        })
        staticLinks.map((item, index) => {
            xml.ele('url')
                .ele('loc', item.url).up()
                .ele('lastmod', item.lastmod).up()
                .ele('priority', item.priority).up()
                .end({ pretty: true });
        });
        BlogList.map((item, index) => {
            let lastMod = item.updated_at != null ? item.updated_at : item.created_at;
            xml.ele('url')
                .ele('loc', frontendUrl + '/blog/'+ item.slug).up()
                .ele('lastmod', lastMod).up()
                .ele('priority', 0.8).up()
                .end({ pretty: true });
        })

        xml = xml.end({ pretty: true });
        var param = {
            'content': xml
        }
        HttpService.postApi(API.GenerateSitemap, param)
            .then(response => {
                if (response.data.status) {
                    swal({
                        title: Messages.Sitemap_Generated
                    })
                }
            });
    }

    return (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0 d-flex align-items-center"> {Title.NavigationMenu} {Inputs.title ? <small className="ml-2 mb-0">({Inputs.title})</small> : ''} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={generateSitemap}>Generate Sitemap</button>
                    {
                        urlParams.id != null &&
                        <Link to="/system/navigation-menu" className="btn btn-dark btn-fw rounded mr-2"> New Menu </Link>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" name="title" onChange={handleInputChanges} value={Inputs.title || ''} placeholder="Title" />
                                            {validator.current.message('Title', Inputs.title, 'required|max:50', { className: 'error' })}
                                        </div>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input type="text" className="form-control" name="description" onChange={handleInputChanges} value={Inputs.description || ''} placeholder="Description" />
                                            {validator.current.message('Description', Inputs.description, 'required|max:1000', { className: 'error' })}
                                        </div>
                                        <div className="form-group">
                                            <label>Menu Type</label>
                                            <select className="form-control" name="menu_type" onChange={handleInputChanges} value={Inputs.menu_type}>
                                                <option value="">Select</option>
                                                <option value="category">Category</option>
                                                <option value="page">Page</option>
                                                <option value="custom_link">Custom Link</option>
                                                <option value="">Blank</option>
                                            </select>
                                            {/* {validator.current.message('Menu Type', Inputs.menu_type, 'required', { className: 'error' })} */}
                                        </div>
                                        <div className="form-group" style={Inputs.menu_type == "category" ? {} : { display: 'none' }}>
                                            <label>Category</label>
                                            {
                                                Categories.length > 0 &&
                                                <DropdownTreeSelect placeholder="Choose" multi={false} data={Categories} onChange={onChangeCategory} mode="radioSelect" className="bootstrap-demo w-100" />
                                            }
                                        </div>
                                        <div className="form-group" style={Inputs.menu_type == "page" ? {} : { display: 'none' }}>
                                            <label>Page</label>
                                            <select className="form-control" name="page_id" onChange={handleInputChanges} value={Inputs.page_id}>
                                                {
                                                    Pages.map(item => (
                                                        <option key={item.id} value={item.id}>{item.title}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group" style={Inputs.menu_type == "custom_link" ? {} : { display: 'none' }}>
                                            <label>Custom Link</label>
                                            <input type="text" className="form-control" name="link" onChange={handleInputChanges} value={Inputs.link || ''} placeholder="Custom Link" />
                                            {CustomLinkValidator.current.message('Custom Link', Inputs.link, 'required|max:255|url', { className: 'error' })}
                                        </div>
                                        <div className="form-group" >
                                            <label>Parent</label>
                                            <select className="form-control" name="parent_id" onChange={handleInputChanges} value={Inputs.parent_id}>
                                                <option value="0">Choose</option>
                                                {
                                                    Menus.map(item => (
                                                        <option key={item.id} value={item.id}>{item.title}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Sort Order</label>
                                            <input type="number" className="form-control" name="sort_order" onChange={handleInputChanges} value={Inputs.sort_order} placeholder="Sort Order" min="0" />
                                            {validator.current.message('Sort Order', Inputs.sort_order, 'required|numeric|min:0,num', { className: 'error' })}
                                        </div>
                                        <div className="form-group">
                                            <label>Menu Column</label>
                                            <input type="number" className="form-control" name="menu_column" onChange={handleInputChanges} value={Inputs.menu_column || ''} placeholder="Menu Column" min="0" />
                                        </div>
                                        <div className="form-group">
                                            <label>Parameters</label>
                                            <input type="text" className="form-control" name="parameters" onChange={handleInputChanges} value={Inputs.parameters || ''} placeholder="Parameters" />
                                        </div>
                                        <div className="form-group">
                                            <label>Image Name for Menu
                                                <svg data-toggle="tooltip" data-html="true" title="Add class name for individual icon. Ex:1. 'icon-1(row 1)-1(col 1)' look like class name 'icon-1-1', Ex:2. 'icon-1(row 1)-2(col 2)' look like class name 'icon-1-2'  <br> <img src='https://hjl.s3.eu-west-2.amazonaws.com/assets/images/info-sprit-icon.png'>"
                                                    xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000d4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-info ml-1 cursor"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                            </label>
                                            <input type="text" className="form-control" name="css_class" onChange={handleInputChanges} value={Inputs.css_class || ''} placeholder="CSS Class" />
                                            {validator.current.message('CSS Class', Inputs.css_class, 'max:200', { className: 'error' })}

                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="EnableMenu" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableMenu"> </label>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="button" onClick={AddMenuItem} className="btn btn-primary mt-5 mr-2"> {urlParams.id == null ? 'Add to Menu' : 'Update Menu'} </button>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-12">
                                            <Nestable
                                                items={MenuItems}
                                                renderItem={renderItem}
                                                collapsed={defaultCollapsed}
                                                onChange={changeMenu}
                                                maxDepth={4}
                                            //collapsed={true}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <button type="button" onClick={SubmitMenu} className="btn btn-primary mr-2 float-right mt-4" disabled={IsDisabledSubmitButton}> Save Menu </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigationMenu;