
import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, Common, Permission_keys, API, swal, Title, toast, MaterialTable, Messages, DateFormat } from '../../config/react'
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Spinner from '../Spinner';

const Customers = () => {

    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [CustomerList, setCustomerList] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [CustomerGroupList, setCustomerGroupList] = useState([])
    const [ShowFilters, setShowFilters] = useState(false);

    const columns = [
        {
            name: 'First Name',
            selector: row => row.firstname,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Contact Number',
            selector: row => row.contact_number,
        },
        {
            name: 'Is Guest',
            selector: row => row.is_guest == 1 ? <label>Yes</label> : <label>No</label>
        },
        // {
        //     name: 'Email Confirmation',
        //     selector: row => row.is_confirmed == 1 ? <label>Yes</label> : <label>No</label>
        // },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,

        },
        {
            name: 'Status',
            selector: row => row.status == 1 ? <label>Active</label> : <label>Inactive</label>,
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/customers/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    {
                        row.status ?
                            <Link onClick={e => ChangeStatus(e, row.id, 0)} to="" className="text-dark" title="Disable" ><i className="fa fa-ban" aria-hidden="true"></i> </Link>
                            :
                            <Link onClick={e => ChangeStatus(e, row.id, 1)} to="" className="text-dark" title="Enable" ><i className="fa fa-check" aria-hidden="true"></i> </Link>
                    }
                    <Link onClick={e => DeleteCustomer(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        customergroup: '',
        name: "",
        email: "",
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Customers)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.Customers
        GetCustomerList()
        GetCustomerGroupList()
    }, [CustomFilters])

    const GetCustomerList = () => {
        var customerData = {
            "customergroup": CustomFilters['customergroup'],
            "name": CustomFilters['name'],
            "email": CustomFilters['email'],
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetCustomerAdminList, customerData)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result[1];
                    setCustomerList(data)
                    setForceUpdate(!isForceUpdate)
                    settotalRows(response.data.result[0][0].total_row)
                }
            });
    }

    const DeleteCustomer = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id
                }
                HttpService.postApi(API.DeleteCustomer, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetCustomerList()
                    });
            }

        });
    }
    const ChangeStatus = async (e, id, status) => {
        e.preventDefault()
        swal({
            title: status ? Messages.Confirm_Enable : Messages.Confirm_Disable,
            text: status ? Messages.Confirm_Enable : Messages.Confirm_Disable,
            buttons: {
                cancel: true,
                confirm: true,
            }
        }).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id,
                    "status": status
                }
                HttpService.postApi(API.ChangeCustomerStatus, param)
                    .then(response => {
                        toast.success(Messages.Record_Updated)
                        GetCustomerList()
                    });
            }

        });
    }
    const GetCustomerGroupList = () => {
        HttpService.postApi(API.GetCustomerGroups, null)
            .then(response => {
                let obj = [];
                response.data.result[0].map((item, index) => {
                    obj.push({ label: item.name, value: item.id })
                })
                Object.assign(CustomerGroupList, obj)
            });
    }
    const handleInputChanges = (e, fieldName) => {
        if (fieldName == 'customergroup')
            Object.assign(CustomFilters, { [fieldName]: e })
        else
            Object.assign(CustomFilters, { [fieldName]: e.target.value })
    };

    const openFilterBox = () => {
        if (ShowFilters) {
            setShowFilters(false);
        }
        else {
            setShowFilters(true);
        }
    }

    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }
    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search for First Name, Email, Contact Number, Created At." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['page']: 1, ['search']: e.target.value })} />
        );
    });

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Customers} </h3>
                <div className="btn-group ml-auto">
                    <Link to="#" onClick={openFilterBox} className="btn btn-primary btn-fw rounded filter-btn mr-2" > Filters </Link>
                </div>
                <div className="btn-group">
                    <Link to="/customers/add" className="btn btn-dark btn-fw rounded mr-2"> Add Customer </Link>
                </div>
            </div>
            <div className={ShowFilters ? "filter-bar d-block" : "filter-bar"} id="filter-bar">
                <div className="grid-margin w-100 stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="active-filter-box mb-4">
                                    <div className="row justify-content-between">
                                        <div className="col-md-10">
                                            <div className="filter-result-list">
                                                <span className="filter-result-repeat-title">Active Filter : </span>
                                                <span className="filter-result-repeat"><a>Name : {CustomFilters.name} <button className="btn-close"></button></a></span>
                                                <span className="filter-result-repeat"><a>Email : {CustomFilters.email}  <button className="btn-close"></button></a></span>
                                                {/* <span className="filter-result-repeat"><a>Customer Group :  <button className="btn-close"></button></a></span>                                      */}
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-right">
                                            <Link to="#" className="rounded mr-2 font-13"> Clear All </Link>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Name</strong></label>
                                            <input type="text" className="form-control" onChange={(e) => handleInputChanges(e, 'name')} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Email</strong></label>
                                            <input type="text" className="form-control" onChange={(e) => handleInputChanges(e, 'email')} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Customer Group</strong></label>
                                            {
                                                <MultiSelect
                                                    onChange={(e) => handleInputChanges(e, 'customergroup')}
                                                    options={CustomerGroupList}
                                                />
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-12 pr-2 text-right">
                                        <div className="btn-group ml-auto">
                                            {/* <Link to="#" className="btn btn-primary btn-fw rounded mr-2" onClick={e => ClearFilter(e)}> Cancel </Link> */}
                                            <span className="filter-result-repeat"><a><button className="btn btn-primary btn-fw rounded mr-2">Cancel</button></a></span>
                                        </div>
                                        <div className="btn-group">
                                            <Link to="#" className="btn btn-dark btn-fw rounded mr-2" onClick={e => GetCustomerList(e)}> Apply Filter </Link>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={CustomerList}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                />

                                {/* <MaterialTable
                                    title=""
                                    columns={[
                                        { title: 'First Name', field: 'firstname' },
                                        { title: 'Email', field: 'email' },
                                        { title: 'Contact Number', field: 'contact_number' },
                                        {
                                            title: 'Is Guest', field: 'is_guest',
                                            render: rowData => rowData.is_guest == 1 ? <label>Yes</label> : <label>No</label>
                                        },
                                        {
                                            title: 'Email Confirmation', field: 'is_confirmed',
                                            render: rowData => rowData.is_confirmed == 1 ? <label>Yes</label> : <label>No</label>
                                        },
                                        {
                                            title: 'Created Date', field: 'created_at', type: 'datetime',
                                            render: rowData => <DateFormat value={rowData.created_at}></DateFormat>
                                        },
                                        {
                                            title: 'Status', field: 'status',
                                            render: rowData => rowData.status == 1 ? <label>Active</label> : <label>Inactive</label>
                                        },
                                        {
                                            title: 'Action', field: 'id', className: "table_icon", sorting: false,
                                            render: rowData =>
                                                <div className="action-btn">
                                                    <Link to={"/customers/edit/" + rowData.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                                                    {
                                                        rowData.status ?
                                                            <Link onClick={e => ChangeStatus(e, rowData.id, 0)} to="" className="text-dark" title="Disable" ><i className="fa fa-ban" aria-hidden="true"></i> </Link>
                                                            :
                                                            <Link onClick={e => ChangeStatus(e, rowData.id, 1)} to="" className="text-dark" title="Enable" ><i className="fa fa-check" aria-hidden="true"></i> </Link>
                                                    }
                                                    <Link onClick={e => DeleteCustomer(e, rowData.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                                                </div>
                                        }
                                    ]}

                                    data={CustomerList}
                                    options={{
                                        thirdSortClick: false,
                                        //exportButton: true,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        //filtering: true,
                                        exportCsv: (columns, data) => {
                                            alert('You should develop a code to export ' + data.length + ' rows');
                                        },

                                    }}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Customers;