import axios from 'axios'
import { toast } from '../config/react'
var CryptoJS = require("crypto-js");
// require("dotenv").config();
// import * as config from './config'


const baseUrl = process.env.REACT_APP_API_URL;

export const getToken = () => {
    const isAuthed = JSON.parse(localStorage.getItem("appState"));
    if (isAuthed)
        return isAuthed.session_token
    else
        return ''
}
export const encrypt = (req) => {
    return {
        req: CryptoJS.AES.encrypt(JSON.stringify(req), 'Please#dont@share&me').toString()
    }
}
export const myheaders = {
    'Content-Type': 'application/json'
}

export const postApi = (url, data) => {
    myheaders.Authorization = 'Bearer ' + getToken();
    return axios.post(baseUrl + url, encrypt(data), { headers: myheaders, maxBodyLength: Infinity, maxContentLength: Infinity })
        .catch((error) => {
            // debugger;
            console.log(error.response);
            if(error.response.data.message != undefined){
                toast.warning(error.response.data.message)
            }
            else{
                toast.warning("Something went wrong.")
            }
            //localStorage.removeItem('appState');
           //window.location = '/error'
        });
}

export const getApi = (url) => {
    myheaders.Authorization = 'Bearer ' + getToken();
    return axios.get(baseUrl + url, { headers: myheaders })
        .catch((error) => {
            // debugger;
            console.log(error.response);
            toast.warning("Something went wrong.")
            //localStorage.removeItem('appState');
            //window.location = '/error'
        });
}
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;