import React, { useState, useRef, useEffect, useContext, ChangeEvent } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, Common, Permission_keys, API, SimpleReactValidator, Title, toast, Messages, DropdownTreeSelect, FileUploadWithPreview, getBase64, Nestable } from '../../config/react'
import { DeleteDiamondQuality } from '../../constants/API';
import { ReactSVG } from 'react-svg'
import Spinner from '../Spinner';

const AddCategory = () => {
    const urlParams = useParams()
    const history = useHistory()
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext)
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [IsShowImageRemoveLink, setIsShowImageRemoveLink] = useState(false)
    const [IsValidFileType, setValidFileType] = useState(false)
    const [CategoryImage, setCategoryImage] = useState('')
    const [Categories, setCategories] = useState([])
    const [Attributes, setAttributes] = useState([]);
    const [svgUrl, setsvgUrl] = useState('');
    const [Inputs, setInputs] = useState({
        id: 0,
        name: "",
        title: "",
        description: "",
        slug: "",
        image: "",
        parent_id: 1,
        abbreviation: "",
        meta_title: "",
        meta_description: "",
        meta_keyword: "",
        status: 0,
        is_diamond_category: 0,
        order_no: 0,
        options: [],
        size_attribute_id: 0
    });
    var category_images = '';

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Categories)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddCategory
        if (urlParams.id != null) {
            document.title = Title.EditCategory
            //GetCategoryDetail()
        }
        GetProductAttributes();
        GetCategories()

        // initImagePlugin()


        setTimeout(() => {
            initImagePlugin()
            // if (IsAuth)
            //     initImagePlugin()
        }, 1000);
        //
    }, [urlParams.id])

    const initImagePlugin = (image = null) => {
        if (image != '' && image != null) {
            category_images = new FileUploadWithPreview("category_images", {
                text: {
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            category_images = new FileUploadWithPreview("category_images", {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }
    // const GetCategoryDetail = () => {
    //     var param = {
    //         "id": urlParams.id
    //     }
    //     HttpService.postApi(API.GetCategory, param)
    //         .then(response => {
    //             console.log(response);
    //             if (response.data.result) {
    //                 debugger
    //                 var data = response.data.result
    //                 setInputs(data[0])
    //                 if (data[0].image.includes(".")) {
    //                     setsvgUrl(data[0].image);
    //                     initImagePlugin(data[0].image)
    //                 }
    //                 setForceUpdate(!isForceUpdate)
    //             }
    //         });
    // }
    const onChangeCategory = (currentNode, selectedNodes) => {
        if (selectedNodes.length > 0) {
            Inputs['parent_id'] = selectedNodes[0].value
        }
        else {
            Inputs['parent_id'] = ""
        }
        //setForceUpdate(!isForceUpdate)
    };
    const GetCategories = (selectedCategory = null) => {
        HttpService.postApi(API.GetCategory, null)
            .then(response => {
                if (response.data.result) {
                    //set category detail
                    if (urlParams.id != undefined) {
                        var detail = response.data.result[0].filter(x => x.id == urlParams.id)
                        var options = Common.GetJSON(detail[0].filter_options);
                        if (options != null)
                            options.sort(function (a, b) {
                                return a.sort_order - b.sort_order;
                            });
                        detail[0].options = options;
                        setInputs(detail[0])
                        if (detail[0].image.includes(".")) {
                            setsvgUrl(detail[0].image);
                            initImagePlugin(detail[0].image)
                        }
                    }
                    //end


                    //filter parent category
                    var parentCategories = response.data.result[0].filter(x => x.parent_id == 1)
                    var categories = [];

                    parentCategories.map((item) => {
                        //filter sub categories by parent
                        var filteredSubcategories = response.data.result[0].filter(x => x.parent_id == item.id)
                        var subcategories = [];

                        filteredSubcategories.map((subitem) => {
                            //filter sub child categories by parent
                            var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id)
                            var subchildcategories = [];

                            filteredSubChildCategories.map((subchilditem) => {
                                var filteredChildCategories = response.data.result[0].filter(x => x.parent_id == subchilditem.id)
                                var childcategories = [];

                                filteredChildCategories.map((childitem) => {
                                    var filteredChildrenCategories = response.data.result[0].filter(x => x.parent_id == childitem.id)
                                    var childrencategories = [];
                                    filteredChildrenCategories.map((childrenitem) => {
                                        var checked = false;
                                        if ((urlParams.id != null && (Inputs['parent_id'] == childrenitem.id)) || selectedCategory == childrenitem.id)
                                            checked = true;
                                        childrencategories.push({
                                            label: childrenitem.name,
                                            value: childrenitem.id,
                                            checked: checked,
                                            //children: subcategories
                                        })
                                    });

                                    var checked = false;
                                    if ((urlParams.id != null && (Inputs['parent_id'] == childitem.id)) || selectedCategory == childitem.id)
                                        checked = true;
                                    childcategories.push({
                                        label: childitem.name,
                                        value: childitem.id,
                                        checked: checked,
                                        disabled: true,
                                        children: childrencategories
                                    })
                                });

                                var checked = false;
                                if ((urlParams.id != null && (Inputs['parent_id'] == subchilditem.id)) || selectedCategory == subchilditem.id)
                                    checked = true;
                                subchildcategories.push({
                                    label: subchilditem.name,
                                    value: subchilditem.id,
                                    checked: checked,
                                    children: childcategories
                                })
                            })

                            var checked = false;
                            if ((urlParams.id != null && (Inputs['parent_id'] == subitem.id)) || selectedCategory == subitem.id)
                                checked = true;
                            subcategories.push({
                                label: subitem.name,
                                value: subitem.id,
                                checked: checked,
                                children: subchildcategories
                            })
                        });
                        var checked = false;
                        if ((urlParams.id != null && (Inputs['parent_id'] == item.id)) || selectedCategory == item.id)
                            checked = true;
                        categories.push({
                            label: item.name,
                            value: item.id,
                            children: subcategories,
                            checked: checked
                        })
                    });
                    setCategories(categories)
                    setForceUpdate(!isForceUpdate)
                }
            });

    }
    const GetProductAttributes = () => {
        HttpService.postApi(API.GetProductAttributeList, {})
            .then(response => {
                setAttributes(response.data.result[0])
                setForceUpdate(!isForceUpdate);
            });
    }
    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status" || prop == "is_diamond_category")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
                updateCategoryDropdown()
            };

    const updateCategoryDropdown = (e) => {
        Categories.map((item, index) => {
            if (item.value == Inputs['parent_id']) {
                Categories[index].checked = true;
            }
            else {
                Categories[index].checked = false;
            }
            item.children.map((subitem, subindex) => {
                if (subitem.value == Inputs['parent_id']) {
                    Categories[index].children[subindex].checked = true;
                }
                else {
                    Categories[index].children[subindex].checked = false;
                }
            })
        });
    }

    window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
        // getBase64(e.detail.cachedFileArray[0], (result) => {
        //     setsvgUrl(result)
        //     setCategoryImage(result)
        // });
        // setIsShowImageRemoveLink(true)
        if (e.detail.cachedFileArray[0].type == 'image/svg+xml') {
            setValidFileType(false);
            console.log("fileUploadWithPreview:imagesAdded Invoke");
            getBase64(e.detail.cachedFileArray[0], (result) => {
                setsvgUrl(result)
                setCategoryImage(result)
            });
            setIsShowImageRemoveLink(true)
            setValidFileType(false);
        }
        else {
            setsvgUrl('');
            setValidFileType(true);
            setCategoryImage('')
            setIsShowImageRemoveLink(false)
        }
    });

    window.addEventListener("fileUploadWithPreview:clearButtonClicked", function (e) {
        setCategoryImage('')
        setIsShowImageRemoveLink(false)
    });

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        // if (IsValidFileType) {
        //     return;
        // }
        var param = {
            "id": Inputs.id,
            "name": Inputs.name,
            "title": Inputs.title,
            "description": Inputs.description,
            "slug": Inputs.slug,
            "image": CategoryImage,
            "parent_id": Inputs.parent_id,
            "abbreviation": Inputs.abbreviation,
            "meta_title": Inputs.meta_title,
            "meta_description": Inputs.meta_description,
            "meta_keyword": Inputs.meta_keyword,
            "status": Inputs.status ?? 0,
            "is_diamond_category": Inputs.is_diamond_category ?? 0,
            "order_no": Inputs.order_no,
            "filter_option": Inputs.options,
        }
        HttpService.postApi(API.SetCategory, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/categories')
            });
    }
    const addOption = e => {
        e.preventDefault();
        let options = Inputs.options;
        if (options == undefined) options = []
        options.push({ id: Math.floor(1000 + Math.random() * 9000), value: '', sort_order: options.length > 0 ? Math.max(...options.map(o => o.sort_order)) + 1 : 0 });
        setInputs({ ...Inputs, ["options"]: options })
        setForceUpdate(!isForceUpdate);
    }
    const handleDelete = i => e => {
        e.preventDefault()
        let options = [...Inputs.options]
        options = options.filter(x => x.id != i);
        Inputs['options'] = options;
        setForceUpdate(!isForceUpdate);
    }
    const styles = {
        position: "relative",
        padding: "10px 15px",
        fontSize: "15px",
        border: "1px solid #f9fafa",
        background: "#f9fafa",
        cursor: 'move'
    };
    const handleText = i => e => {
        let options = [...Inputs.options]
        let index = options.findIndex(x => x.value === i);
        options[index] = { id: index, value: e.target.value, sort_order: options[index].sort_order != undefined ? options[index].sort_order : 0 };
        setInputs({ ...Inputs, ['options']: options });
        setForceUpdate(!isForceUpdate);
    }
    const renderItem = (item) => {
        return (
            <div style={styles}>
                <div className="row form-group mb-0" >
                    <div className="col-md-10 pr-1">
                        <select className="form-control"
                            name="options[]"
                            data-id={item.item.id}
                            onChange={handleText(item.item.value)}
                            value={item.item.value}>
                            <option value="">Please Select</option>
                            {
                                Attributes.length > 0 &&
                                Attributes.map(item => (
                                    <option value={item.id} disabled={Inputs['options'].filter(option => option.value == item.id).length > 0}>{item.attribute_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-md-2 pl-1">
                        <button className="btn btn-danger input-btn btn-block" onClick={handleDelete(item.item.id)}>X</button>
                    </div>
                </div>
            </div>
        );
    }
    const changeOptionOrder = (item) => {

        let attributeOptions = [];
        item.items.map((item, index) => {
            attributeOptions.push({ option_id: item.id, value: item.value, sort_order: index });
        });
        setInputs({ ...Inputs, ['options']: attributeOptions });
        if (urlParams.id != null) {
            let param = {
                'items': attributeOptions
            }

            HttpService.postApi(API.ChangeCategoryAttributeOrder, param)
                .then(response => {
                    //console.log(response);
                });
        }
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddCategory : Title.EditCategory} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" name="name" onChange={handleInputChanges("name")} onKeyUp={handleInputChanges("name")} onBlur={() => validator.current.showMessageFor("Name")} defaultValue={Inputs.name} placeholder="Name" />
                                            {validator.current.message('Name', Inputs.name, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" name="title" onChange={handleInputChanges("title")} onKeyUp={handleInputChanges("title")} defaultValue={Inputs.title} placeholder="Title" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control" name="description" onChange={handleInputChanges("description")} onKeyUp={handleInputChanges("description")} onBlur={() => validator.current.showMessageFor("Description")} defaultValue={Inputs.description} placeholder="Description"></textarea>
                                            {validator.current.message('Description', Inputs.description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Slug</label>
                                            <input type="text" className="form-control" name="slug" onChange={handleInputChanges("slug")} onKeyUp={handleInputChanges("slug")} onBlur={() => validator.current.showMessageFor("Slug")} defaultValue={Inputs.slug} placeholder="Slug" />
                                            {validator.current.message('Slug', Inputs.name, 'required|max:50', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Parent Category</label>
                                            {
                                                Categories.length > 0 &&
                                                <DropdownTreeSelect data={Categories} onChange={onChangeCategory} mode="radioSelect" className="bootstrap-demo w-100" />
                                            }

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Abbreviation</label>
                                            <input type="text" className="form-control" name="abbreviation" onChange={handleInputChanges("abbreviation")} onKeyUp={handleInputChanges("abbreviation")} onBlur={() => validator.current.showMessageFor("Abbreviation")} defaultValue={Inputs.abbreviation} placeholder="Abbreviation" />
                                            {validator.current.message('Abbreviation', Inputs.abbreviation, 'max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Meta Title</label>
                                            <textarea className="form-control" name="meta_title" onChange={handleInputChanges("meta_title")} onKeyUp={handleInputChanges("meta_title")} defaultValue={Inputs.meta_title} placeholder="Meta Title"></textarea>
                                            {/* {validator.current.message('Meta title', Inputs.meta_title, 'required', { className: 'error' })} */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Meta Description</label>
                                            <textarea className="form-control" name="meta_description" onChange={handleInputChanges("meta_description")} onKeyUp={handleInputChanges("meta_description")} defaultValue={Inputs.meta_description} placeholder="Meta Description"></textarea>
                                            {/* {validator.current.message('Meta Description', Inputs.meta_description, 'required', { className: 'error' })} */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Meta Keyword</label>
                                            <textarea className="form-control" name="meta_keyword" onChange={handleInputChanges("meta_keyword")} onKeyUp={handleInputChanges("meta_keyword")} defaultValue={Inputs.meta_keyword} placeholder="Meta Keyword"></textarea>
                                            {validator.current.message('Meta Keyword', Inputs.meta_keyword, 'max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges("status")} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group mb-0">
                                            <label>Image</label>
                                            <div className="custom-file-container mb-3" data-upload-id="category_images">
                                                <label className="custom-file-container__custom-file h-auto">
                                                    <input type="file" className="custom-file-container__custom-file__custom-file-input" accept=".svg" aria-label="Choose Image" />
                                                    <span class="fs-13 mt-5 pt-1 position-absolute">only .svg format allowed</span>
                                                    <ReactSVG className="menu-svg-icon" src={svgUrl} />
                                                    <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                                    <span className="custom-file-container__custom-file__custom-file-control"></span>
                                                </label>
                                                <div className="btn-remove-image d-none">
                                                    <a href={void (0)} className="custom-file-container__image-clear" title="Remove Image" style={IsShowImageRemoveLink ? {} : { display: 'none' }}>&times;</a>
                                                    <div className="custom-file-container__image-preview">

                                                    </div>
                                                </div>

                                                <div style={IsValidFileType ? {} : { display: 'none' }} class="error">The file formate is not supported.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Is Diamond Category</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="is_diamond_category" onChange={handleInputChanges("is_diamond_category")} id="is_diamond_category" checked={Inputs.is_diamond_category} />
                                                    <label className="custom-control-label" htmlFor='is_diamond_category' > </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select Size Attribute</label>
                                            <select className="form-control" name="size_attribute_id" onChange={handleInputChanges("size_attribute_id")} value={Inputs.size_attribute_id}>
                                                {
                                                    Attributes.length > 0 &&
                                                    Attributes.map(item => (
                                                        <option value={item.id} >{item.attribute_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Order No</label>
                                            <input type="text" className="form-control" name="order_no" onChange={handleInputChanges("order_no")} onKeyUp={handleInputChanges("order_no")} value={Inputs.order_no} placeholder="Order No" />
                                        </div>
                                    </div>

                                </div>
                                <hr />
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Filter Options</label>
                                        <button class="btn btn-primary float-right" onClick={addOption}>Add Option</button>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-2">

                                        <p></p>
                                        {
                                            Inputs['options']?.length > 0 &&
                                            <Nestable
                                                items={Inputs['options']}
                                                renderItem={renderItem}
                                                collapsed={false}
                                                onChange={changeOptionOrder}
                                                maxDepth={1}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/categories" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCategory;