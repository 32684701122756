import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { HttpService, API, Title, toast, Messages, swal,Common,Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';
const Instagram = () => {
    const path = useLocation();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [IsRemove, setIsRemove] = useState(false);
    const [ClientId, setClientId] = useState('');
    const [ClientSecret, setClientSecret] = useState('');
    const [Token, setToken] = useState('');
    const [RedirectUrl, setRedirectUrl] = useState(window.location.origin + '/system/settings/instagram/auth/');
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        let parameters = new URLSearchParams(path.search);
        let code = parameters.get('code');
        GetInstagramOptions();
        if (code != null && Token == '' && ClientId != '' && ClientSecret != '') {
            GetShortLivedToken(code);
        }
    }, []);
    const GetInstagramOptions = () => {
        HttpService.getApi(API.GetInstagramOptions)
            .then(response => {
                if (response.data.result) {
                    response.data.result.map(item => {
                        if (item.hj_key == 'instagram_client_id') {
                            setClientId(item.hj_value);
                        }
                        if (item.hj_key == 'instagram_client_secret') {
                            setClientSecret(item.hj_value);
                        }
                        if (item.hj_key == 'instagram_token') {
                            setToken(item.hj_value);
                        }
                        setForceUpdate(!isForceUpdate);
                    });
                }
            });
    }
    const GetShortLivedToken = async (code) => {
        var formdata = new FormData();
        formdata.append("client_id", ClientId);
        formdata.append("client_secret", ClientSecret);
        formdata.append("grant_type", "authorization_code");
        formdata.append("code", code);
        formdata.append("redirect_uri", RedirectUrl);
        await axios.post(API.GetShortLivedToken, formdata).then(async (res) => {
            if (res.status == 200) {
                GetLongLivedToken(res.data.access_token)
                const queryParams = new URLSearchParams(path.search)
                if (queryParams.has('code')) {
                    queryParams.delete('code')
                }
            }
        });
    }
    const GetLongLivedToken = async (token) => {
        await axios.get(API.GetLongLivedToken + '?grant_type=ig_exchange_token&client_secret=' + ClientSecret + '&access_token=' + token).then(async (res) => {
            if (res.status == 200) {
                StoreInstagramToken(res.data.access_token)
            }
        });
    }
    const StoreInstagramToken = (token) => {
        let param = {
            'token': token
        }
        HttpService.postApi(API.UpdateInstagramToken, param)
            .then(response => {
                if (response.data.result) {
                    setIsRemove(false);
                    toast.success(Messages.Instagram_Token_Generated)
                    history.push('/system/settings/instagram');
                }
            });
    }
    const RemoveToken = () => {
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                setIsRemove(true);
                StoreInstagramToken('');
                setToken('');
                history.push('/system/settings/instagram');
            }
        });
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {Title.InstagramToken} </h3>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {
                                    (Token == '') ?
                                        <>
                                            <div className="col-md-12">
                                                <a href={API.InstagramAuth + '?client_id=' + ClientId + '&scope=user_profile,user_media&response_type=code&redirect_uri=' + RedirectUrl} className="btn btn-primary">Login & Generate</a>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-md-12">
                                                <label>Token:</label>
                                                <input type='text' className='form-control' value={Token} readonly />
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <button type="button" className='btn btn-danger' onClick={RemoveToken}>Remove</button>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instagram;