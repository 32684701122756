import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, Common, Permission_keys, swal, Title, toast, MaterialTable, Messages, DateFormat } from '../../config/react'
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import shape from '@material-ui/core/styles/shape';
import DataTable from 'react-data-table-component';
import DiamondDetail from './DiamondDetail';
import Spinner from '../Spinner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Diamond = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [loading, setLoading] = useState(false);
    const [DiamondList, setDiamondList] = useState([])
    const [diamondData, setdiamondData] = useState()
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [ShowFilters, setShowFilters] = useState(false);
    const price_To = useRef(null)
    const price_From = useRef(null)
    const columns = [
        {
            name: 'Image',
            selector: row => <img className="my-2" width="95px" height="95px" src={row.base_image}></img>,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
        },
        {
            name: 'Vendor',
            selector: row => row.vendor,
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Price',
            selector: row => row.GB_price,
        },
        {
            name: 'Weight',
            selector: row => row.weight,
        },
        {
            name: 'Created Date',
            selector: row => row.created_at,
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="cursor action-btn">
                    <span onClick={(e) => ShowDiamondData(e, row.sku)} className="text-dark" title="View"><i className="fa fa-eye" aria-hidden="true"></i> </span>
                </div>
            ,
        },
    ];
    const DefaultFilters = {
        shape: [],
        cut: [],
        color: [],
        clarity: [],
        sku: "",
        price_To: "",
        price_From: "",
        page: 1,
        search: "",
        page_size: 20,
        diamond_type: 'earth'
    }
    const [Filters, setFilters] = useState(DefaultFilters)
    const [DefaultValuesForFilters, setDefaultValuesForFilters] = useState({ color: [], clarity: [], cut: [], shape: [] })
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()
    const [IsShowModal, setIsShowModal] = useState(false);
    const [importCount, setimportCount] = useState()

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Diamonds)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.Diamond
        GetFilters();
        GetDiamondImportCount();
    }, [])

    const onCloseModal = () => {
        setIsShowModal(false);
        GetDiamondsList();
        setForceUpdate(!isForceUpdate);
    }

    const GetDiamondsList = () => {
        var diamondData = {
            "sku": CustomFilters.sku,
            "price_From": (Number(CustomFilters.price_From) > 0) ? Number(CustomFilters.price_From) : 1,
            "price_To": Number(CustomFilters.price_To),
            "color": CustomFilters.color,
            "category_id": CustomFilters.diamond_type == 'earth' ? process.env.REACT_APP_EARTH_MINED_DIAMOND : process.env.REACT_APP_LAB_DIAMOND,
            "shape": CustomFilters.shape,
            "clarity": CustomFilters.clarity,
            "cut": CustomFilters.cut,
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size,
            "diamond_type": CustomFilters.diamond_type
        }
        setLoading(true);
        HttpService.postApi(API.GetDiamondsList, diamondData)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result;

                    if (data.length > 0) {
                        setDiamondList([])
                        setDiamondList(data)
                        setForceUpdate(!isForceUpdate)
                        if (data[0].total_diamond) {
                            settotalRows(data[0].total_diamond)
                        }
                        else{
                            settotalRows(0)
                        }
                    }
                }
                setLoading(false);
            });
    }

    const ShowDiamondData = (e, sku) => {
        e.preventDefault();
        let data = DiamondList.filter(x => x.sku == sku)[0]
        setdiamondData(data)
        setIsShowModal(true);
        setForceUpdate(!isForceUpdate)
    }

    useEffect(() => {
        GetDiamondsList()
    }, [CustomFilters])

    useEffect(() => {
        if (diamondData == undefined) return
        setForceUpdate(!isForceUpdate)
        // return <DiamondDetail props={{ IsShowModal: IsShowModal, data: diamondData }} onCloseModal={onCloseModal} />
    }, [diamondData, IsShowModal])

    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const handleInputChanges = (e, fieldName) => {
        if (fieldName == 'color' || fieldName == 'cut' || fieldName == 'shape' || fieldName == 'clarity')
            Object.assign(CustomFilters, { [fieldName]: e })
        else
            Object.assign(CustomFilters, { [fieldName]: e.target.value })
        setForceUpdate(!isForceUpdate);
    };

    const GetFilters = () => {
        var param = {
            "diamond_type": 41
        }
        HttpService.postApi(API.GetDiamondDefaultFilters, param)
            .then(response => {
                setFilters(response.data.result[0][0])
                setForceUpdate(!isForceUpdate)
                let obj = [];
                let obj_Color = [];
                let obj_Clarity = [];
                let obj_Cut = [];
                response.data.result[0][0].shape.map((item, index) => {
                    obj.push({ label: item.name, value: item.id })
                })
                Object.entries(response.data.result[0][0].color).map((item, index) => {
                    obj_Color.push({ label: item[1], value: item[0] })
                })
                Object.entries(response.data.result[0][0].clarity).map((item, index) => {
                    obj_Clarity.push({ label: item[1], value: item[0] })
                })
                Object.entries(response.data.result[0][0].cut).map((item, index) => {
                    obj_Cut.push({ label: item[1], value: item[0] })
                })
                Object.assign(DefaultValuesForFilters, { shape: obj, color: obj_Color, clarity: obj_Clarity, cut: obj_Cut })
            });
    }

    const openFilterBox = () => {
        if (ShowFilters) {
            setShowFilters(false);
        }
        else {
            setShowFilters(true);
        }
    }

    const GetDiamondImportCount = () => {
        HttpService.postApi(API.GetVendorDiamondCount, {})
            .then(response => {
                setimportCount(response.data.result[0][0].hj_value);
                setForceUpdate(!isForceUpdate)
            });
    }

    const cleardrp = false;
    const ClearFilter = (e) => {
        setCustomFilters(DefaultFilters);
        // Object.assign(DefaultValuesForFilters, { sku:'',price_From:'',price_To:'', shape: [], color: [], clarity: [], cut: [] })

        setClearMultiSelect([])
        price_To.current.value = ''
        price_From.current.value = ''
        setForceUpdate(!isForceUpdate);
        GetDiamondsList();
    }

    const ApplyFilter = () => {
        setCustomFilters({ ...CustomFilters, ['page']: 0 });
        GetDiamondsList();
    }
    const [value, setClearMultiSelect] = useState('rf,test,new')
    return (!IsAuth) ? <Spinner /> : (
        <>

            {
                IsShowModal == true &&
                <DiamondDetail props={{ IsShowModal: IsShowModal, data: diamondData }} onCloseModal={onCloseModal} />
            }

            <div className="content-wrapper pb-0">
                <div className="page-header">
                    <h3 className="page-title"> {Title.Diamond} </h3>
                    <div className="btn-group ml-auto">
                        <Link to="#" onClick={openFilterBox} className="btn btn-primary btn-fw rounded filter-btn mr-2" > Filters </Link>
                    </div>
                </div>
                <div className={ShowFilters ? "filter-bar d-block" : "filter-bar"} id="filter-bar">
                    <div className="grid-margin w-100 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    {/*<div className="active-filter-box mb-4">
                                         <div className="row justify-content-between">
                                            <div className="col-md-10">
                                                <div className="filter-result-list">
                                                    <span className="filter-result-repeat-title">Active Filter : </span>
                                                    <span className="filter-result-repeat"><a>Name : {CustomFilters.name}  <button className="btn-close"></button></a></span>
                                                    <span className="filter-result-repeat"><a>Price : ${CustomFilters.price_From}-${CustomFilters.price_To} <button className="btn-close"></button></a></span>
                                                    <span className="filter-result-repeat"><a>SKU : {CustomFilters.sku} <button className="btn-close"></button></a></span>

                                                </div>
                                            </div>
                                            <div className="col-md-2 text-right">
                                                <Link to="#" className="rounded mr-2 font-13"> Clear All </Link>
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </div>*/}

                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Type</strong></label>
                                                <select className='form-control' name="diamond_type" onChange={(e) => handleInputChanges(e, 'diamond_type')} value={CustomFilters.diamond_type}>
                                                    <option value='earth'>Earth</option>
                                                    <option value='lab'>Lab</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>SKU</strong></label>
                                                <input type="text" className="form-control" value={CustomFilters.sku} onChange={(e) => handleInputChanges(e, 'sku')} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Price</strong></label>
                                                <div className="form-group mb-2 row align-items-center">
                                                    <div className="col-md-6 pr-md-2">
                                                        <input type="number" className="form-control" min={0} ref={price_From} placeholder="From" onChange={(e) => handleInputChanges(e, 'price_From')} value={CustomFilters.price_From} />
                                                    </div>
                                                    <div className="col-md-6 pl-md-2">
                                                        <input type="number" className="form-control" min={0} ref={price_To} placeholder="To" onChange={(e) => handleInputChanges(e, 'price_To')} value={CustomFilters.price_To} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Color</strong></label>
                                                {
                                                    <MultiSelect
                                                        onChange={(e) => handleInputChanges(e, 'color')}
                                                        options={DefaultValuesForFilters.color}
                                                        defaultValue={CustomFilters.color}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Shape</strong></label>
                                                <MultiSelect
                                                    onChange={(e) => handleInputChanges(e, 'shape')}
                                                    options={DefaultValuesForFilters.shape}
                                                    defaultValue={CustomFilters.shape}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Clarity</strong></label>

                                                <MultiSelect
                                                    onChange={(e) => handleInputChanges(e, 'clarity')}
                                                    options={DefaultValuesForFilters.clarity}
                                                    defaultValue={CustomFilters.clarity}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Cut</strong></label>

                                                <MultiSelect
                                                    onChange={(e) => handleInputChanges(e, 'cut')}
                                                    options={DefaultValuesForFilters.cut}
                                                    defaultValue={CustomFilters.cut}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-12 pr-2 text-right">
                                            <div className="btn-group ml-auto">
                                                <Link to="#" className="btn btn-dark btn-fw rounded mr-2" onClick={e => ApplyFilter(e)}> Apply Filter </Link>
                                            </div>
                                            <div className="btn-group">
                                                <Link to="#" className="btn btn-primary btn-fw rounded mr-2" onClick={e => ClearFilter(e)}> Clear All </Link>
                                                {/* <span className="filter-result-repeat"><button onClick={e=>ClearFilter()} className="btn btn-primary btn-fw rounded mr-2">Clear All</button></span> */}
                                            </div>
                                            {/* <div className="btn-group ml-auto">
                                                <span className="filter-result-repeat"><a><button className="btn btn-primary btn-fw rounded mr-2">Cancel</button></a></span>
                                            </div> */}


                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">

                                <div className="table-responsive">

                                    <DataTable
                                        // title="Users"
                                        columns={columns}
                                        data={DiamondList}
                                        // progressPending={loading}
                                        pagination
                                        paginationServer
                                        paginationPerPage={20}
                                        paginationTotalRows={totalRows}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                    />

                                    {/* <MaterialTable
                                    title=""
                                    columns={[
                                        {
                                            title: 'Image', field: 'base_image',
                                            render: rowData => <img src={rowData.base_image}></img>
                                        },
                                        { title: 'SKU', field: 'sku' },
                                        { title: 'name', field: 'name' },
                                        { title: 'Price', field: 'GB_price' },
                                        { title: 'Metal Weight', field: 'weight' },
                                        // { title: 'Price', field: 'price' },
                                        {
                                            title: 'Created Date', field: 'created_at', type: 'datetime',
                                            render: rowData => <DateFormat value={rowData.created_at}></DateFormat>
                                        },
                                        // {
                                        //     title: 'Action', field: 'id', className: "table_icon", sorting: false,
                                        //     render: rowData =>
                                        //         <div className="action-btn">
                                        //             <Link to={"/products/edit/" + rowData.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                                        //             <Link onClick={e => DeleteProducts(e, rowData.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                                        //         </div>
                                        // }
                                    ]}

                                    data={DiamondList}
                                    // onChangePage={onChangePage}
                                    // onSearchChange={onSearchChange}
                                    options={{
                                        thirdSortClick: false,
                                        //exportButton: true,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        //filtering: true,
                                        exportCsv: (columns, data) => {
                                            alert('You should develop a code to export ' + data.length + ' rows');
                                        },

                                    }}
                                /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Diamond;